import React from "react";
import DefaultSelector from "../utils/DefaultSelector";
import { GET_SPECIALISTS } from '../favors/graphqlRequests';
import {useQuery} from "@apollo/react-hooks";
import { Spin, Row, Col, Input, Form, InputNumber } from "antd";

const ServiceForm = ({ service, onChange }) => {
    const {loading, data = {specialists: []}} = useQuery(GET_SPECIALISTS);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Col span={12}>
                    <Form.Item label="Название" required>
                        <Input title={"Наименование услуги"} value={service.name} id="name" onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Вид занятия" required>
                        <DefaultSelector placeholder={"Тип занятия"} value={service.type}
                            onChange={value => onChange({ target: { id: "type", value } })}
                            options={["Первичное", "Индивидуальное", "Групповое"].map(type => ({ id: type, title: type }))}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Продолжительность (мин)">
                        <InputNumber style={{ width: '100%' }} placeholder="Продолжительность" value={service.duration} id="duration"
                            onChange={value => onChange({ target: { id: "duration", value } })}
                            max={60 * 10} min={30} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Цена для клиента">
                        <InputNumber style={{ width: '100%' }} placeholder="Цена для клиента" value={service.costForClient} id="costForClient"
                            onChange={value => onChange({ target: { id: "costForClient", value } })}
                            max={100000} min={0} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Стоимость специалиста">
                        <InputNumber style={{ width: '100%' }} placeholder="Стоимость специалиста" value={service.specialistCost} 
                            id="specialistCost" max={100000} min={0}
                            onChange={value => onChange({ target: { id: "specialistCost", value } })}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="ID в 1С">
                        <Input title={"ID в 1С"} value={service.businessId} id="businessId" onChange={onChange} />
                    </Form.Item>
                </Col>
                <Spin spinning={loading}>
                    <Col span={24}>
                        <DefaultSelector placeholder={"Специалисты"} mode={"multiple"} value={service.specialists.map(_ => Number.parseInt(_))}
                                onChange={value => onChange({ target: { id: "specialists", value } })}
                                options={
                                    (data.specialists || []).map(spec => ({id: Number.parseInt(spec.id), title: `${spec.lastName} ${spec.name}`}))
                                }
                            />
                    </Col>
                </Spin>
            </Col>
        </Row>
    )
}

ServiceForm.whyDidYouRender = true;

export {ServiceForm}