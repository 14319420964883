import gql from "graphql-tag";

export const GET_NOTIFICATIONS = gql`query notifications($filters: [Filter]){
    filteredNotifications(filters: $filters) {
        id
        message
        method
        status
        created
        props {key value}
        recipient {
            id
            name
            lastName
            surName
            phoneNumber
        }
    }
}`;

export const GET_USERS = gql`query users{
    users {
        id
        name
        lastName
        surName
        phoneNumber
    }
}`;

export const NOTIFICATION_MUTATION = gql`mutation sendNotification($props:NotificationInput){
    sendNotification(props: $props) {
        id
    }
}`