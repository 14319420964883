import DefaultSelector from "../utils/DefaultSelector";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_LOCATIONS} from "../lessons/graphqlRequests";
import { Spin } from "antd";

export function LocationSelector(props) {
    const {value, onChange} = props;
    const {loading, data = {locations: []}} = useQuery(GET_ALL_LOCATIONS);
    const defaultOnChange = selectedId => onChange(data.locations.find(location => location.id === selectedId))

    const getResult = (value) => value instanceof Array ? props.onChange(value) : defaultOnChange(value)

    return (
        <Spin spinning={loading}>
            <DefaultSelector {...props}
                         placeholder="Выберите кабинет"
                         onChange={getResult}
                         // onChange={(value,option) => console.info(value, option)}
                         dataTitle="name" dataIndex="id" id={"cabinet_selector"}
                         options={data.locations.map(item => ({
                             ...item,
                             name: `${item.address} каб. ${item.cabinet}`
                         }))}
            />
        </Spin>
    )
}
