import React, {useState, useEffect} from "react";
import moment from 'moment'
import 'moment/locale/ru'
import "react-big-calendar/lib/css/react-big-calendar.css";
import DefaultSelector from "../utils/DefaultSelector";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import ScheduleCalendar, {RESOURCE_TYPE} from "./schedule_calendar";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Form from "antd/es/form";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import {useStateWithLocalStorage} from "../../utils/localStorageState";
import DefaultDatePicker from "../utils/DefaultDatePicker";
import ScheduleSettings from "./scheduleSettings"
import {sendScheduleTiming} from "../../analytics";

moment.locale("ru");

const GET_ALL_PERSONS_FOR_SCHEDULE = gql`query GET_ALL_PERSONS_FOR_SCHEDULE {
    specialists {
        id
        lastName
        name
        surName
    }
    locations {
        id
        address
        cabinet
    }
}`;

export default function Schedule({type: scheduleFor = RESOURCE_TYPE.SPECS}) {
    const startMoment = Date.now();
    const [specsInLocalStore, setSpecsInLocalStore] = useStateWithLocalStorage({
        key: 'selectedSpecialists',
        initialValue: []
    });
    const [locationsInLocalStore, setLocationsInLocalStore] = useStateWithLocalStorage({
        key: 'selectedLocations',
        initialValue: []
    });
    const [dateInLocalStore, setDateInLocalStore] = useStateWithLocalStorage({key: 'selectedDate', initialValue: ""});
    const [cellSizeInMinutes, setCellSizeInMinutes] = useStateWithLocalStorage({
        key: 'cellSizeInMinutes',
        initialValue: 30
    });
    const [showStatuses, setShowStatuses] = useStateWithLocalStorage({
        key: 'showStatuses',
        initialValue: ["Предстоит", "Ожидает_решения", "Не_дозвонились", "Отменен"]
    });
    const [settingsDrawer, setSettingsDrawer] = useState({
        visible: false,
        onClose: () => setSettingsDrawer({...settingsDrawer, visible: false})
    });
    const [schedule, setSchedule] = useState({
        date: dateInLocalStore || new Date(),
        momentD: moment(dateInLocalStore || new Date())
    });
    const {data = {specialists: [], locations: []}, loading} = useQuery(GET_ALL_PERSONS_FOR_SCHEDULE);

    const changeDate = (moment, str) => {
        const date = moment ? moment.toDate() : new Date();
        setSchedule({...schedule, date, momentD: moment});
        setDateInLocalStore(date)
    };

    useEffect(() => {
        sendScheduleTiming('Загрузка страницы', startMoment)
    })

    const RESOURCE_INFO = {
        SPECS: {
            title: "Специалисты",
            selector: {
                value: specsInLocalStore,
                onChange: setSpecsInLocalStore,
                dataTitle: "fio",
                options: data.specialists.map(spec => ({...spec, fio: `${spec.lastName} ${spec.name} ${spec.surName}`}))
            }
        },
        LOCATIONS: {
            title: "Кабинеты",
            selector: {
                value: locationsInLocalStore,
                onChange: setLocationsInLocalStore,
                dataTitle: "customName",
                options: data.locations.map(location => ({
                    ...location,
                    customName: `${location.address} ${location.cabinet}`
                }))
            }
        }
    };

    const currentResource = RESOURCE_INFO[scheduleFor];
    return (
        <Row>
            <ScheduleSettings scheduleProps={{
                setCellSizeInMinutes,
                cellSizeInMinutes,
                showStatuses,
                setShowStatuses
            }} {...settingsDrawer} />
            <Row gutter={[8, 0]}>
                <Col span={3}>
                    <Form.Item label="Дата">
                        <DefaultDatePicker onChange={changeDate} value={moment(schedule.date)}/>
                    </Form.Item>
                </Col>
                <Col span={20}>
                    <Form.Item label={currentResource.title}>
                        <DefaultSelector placeholder={currentResource.title} mode="multiple"
                                         dataIndex="id" {...currentResource.selector} id={"spec_selector"} />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Form.Item label={"  "}>
                        <Button onClick={() => setSettingsDrawer({...settingsDrawer, visible: true})}>
                            <Icon type="setting"/>
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col span={24} style={{height: '80vh'}}>
                    <ScheduleCalendar
                        schedule={{
                            ...schedule,
                            resources: {type: scheduleFor, ids: currentResource.selector.value},
                            statuses: showStatuses
                        }}
                        setSchedule={setSchedule}
                        customProps={{timeslots: cellSizeInMinutes / 10}}
                    />
                </Col>
            </Row>
        </Row>
    )
}