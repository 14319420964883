import DefaultSelector from "../utils/DefaultSelector";
import React from "react";

const priorities = [
    {name: "Высокий"},
    {name: "Средний"},
    {name: "Низкий"},
];

export function LessonPrioritySelector(props) {
    return (
        <DefaultSelector {...props}
                         options={priorities}
                         dataTitle="name"
                         dataIndex="name"
                         onChange={selectedPriorityName => props.onChange(priorities.find(priority => priority.name === selectedPriorityName))}
                         placeholder="Приоритет занятия" id={"lesson_priority_selector"}
        />
    )
}
