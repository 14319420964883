import React from "react";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";
import {useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Form, Button} from 'antd'
import FormBuilder from 'antd-form-builder'
import {SAVE_ORGANIZATION, SELECT_ORGANIZATION} from "../graphQL";
import DefaultSelector from "../../utils/DefaultSelector";
import gql from "graphql-tag";

const OrganizationModal = Form.create()(({form}) => {
    const {id} = useParams();
    const history = useHistory();
    const title = (id ? "Редактирование" : "Добавление новой") + " организации";

    const {loading, data = {organization: {employees: []}}} = useQuery(SELECT_ORGANIZATION, {variables: {id}, skip: !id});
    const [updateOrg] = useMutation(SAVE_ORGANIZATION);

    const onDelete = () => updateOrg({variables: {...data.organization, isDeleted: true}})
        .then(() => history.goBack());

    const onOk = () => {
        const {org} = form.getFieldsValue();
        updateOrg({variables: {...data.organization, ...org}})
            .then(() => history.goBack());
    };

    const footer = [
        <Button type="primary" onClick={onOk}>Сохранить</Button>,
        <Button type="danger" hidden={!id} onClick={onDelete}>Удалить</Button>,
    ];

    return (
        <Spin spinning={loading}>
            <Modal {...{visible: true, width: 600, title, footer}} onCancel={history.goBack}>
                <EditForm {...{form, initial: data.organization || {}}} />
            </Modal>
        </Spin>
    )
});

const GET_ALL_PERSONS_FOR_SCHEDULE = gql`query GET_ALL_PERSONS_FOR_SCHEDULE {
    specialists {
        id
        lastName
        name
        surName
    }
}`;

const SelectSpecialists = ({value, onChange}) => {
    const {data = {specialists: []}, loading} = useQuery(GET_ALL_PERSONS_FOR_SCHEDULE);
    return (
        <Spin spinning={loading}>
            <DefaultSelector placeholder="Специалисты" mode="multiple" dataIndex="id" dataTitle="fio"
                             options={data.specialists.map(spec => ({...spec, fio: `${spec.lastName} ${spec.name} ${spec.surName}`}))}
                             value={value} id={"specialists_selector"}
                             onChange={onChange}
            />
        </Spin>
    )
};

FormBuilder.defineWidget('SelectSpecialists', SelectSpecialists);

const EditForm = ({form, initial}) => {
    const meta = {
        initialValues: {org: {...initial, specs: initial.employees.map(item => item.id)}},
        fields: [
            {key: 'org.name', label: 'Наименование', required: true},
            {key: 'org.businessId', label: 'Идентификатор в 1С'},
            {key: 'org.specs', label: 'Специалисты', widget: 'SelectSpecialists'}
        ],
    }

    return (
        <Form>
            <FormBuilder meta={meta} form={form}/>
        </Form>
    )
};

export default OrganizationModal;