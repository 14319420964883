import React from "react"
import { SpecialistDescriptionProps } from "../models"
import { Descriptions, Button, Divider } from "antd";
import { MarkedText } from "../markedText";
import { useHistory, useLocation } from "react-router-dom";

export const SpecialistDescription: React.FC<SpecialistDescriptionProps> = ({ spec, mappedBy = "" }: SpecialistDescriptionProps) => {
    const history = useHistory();
    const location = useLocation();
    const markText = (text: string) => <MarkedText text={text} mappedBy={mappedBy} />
    console.info("spec", spec)
    const title = `Специалист. ${spec.lastName} ${spec.name} ${spec.surName}`
    const goToSpec = () => history.push(`/specialist/${spec.id}`);
    const servicesDesc = (
        <Descriptions.Item label="Услуги" span={2}>
            {
                spec.services
                    .map(service => (
                        <>{markText(`${service.name} | ${service.duration} мин. | ${service.cost} руб.`)}<br/></>
                    ))
            }
        </Descriptions.Item>
    )
    
    const fullTitle = (
        <>
            {markText(title)}
            <Divider type={"vertical"} />
            <Button type={"link"} onClick={goToSpec} >Карточка</Button>
            <Divider type={"vertical"} />
            <Button type="link" key={"action_show_timetable"}
                         onClick={() => history.push({
                             pathname: `/schedule/specialist/${spec.id}`,
                             state: {
                                 background: location,
                             }
                         })}>
                    Расписание
                </Button>
        </>
    );

    return (
        <Descriptions title={fullTitle}>
            <Descriptions.Item label="Дата рождения">{markText(spec.birthday || "Нет даных")}</Descriptions.Item>
            <Descriptions.Item span={2} label="Описание">{markText(spec.description || "Нет даных")}</Descriptions.Item>
            {servicesDesc}
        </Descriptions>
    )
}