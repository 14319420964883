import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Card from "antd/es/card";
import Result from "antd/es/result";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import DefaultTable from "../utils/DefaultTable";
import message from "antd/es/message";
import {GET_PARENTS_FOR_CHILD, SAVE_PARENTS_FOR_CHILD} from "./graphqlRequests";

export default function Parents({childId, history}) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectParentsMode, setSelectParentsMode] = useState(false);
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const query = useQuery(GET_PARENTS_FOR_CHILD, {variables: {childId: Number.parseInt(childId)}, skip: !childId});
    if (!childId) {
        return (
            <Card size="small" title="Представители">
                <Result
                    status="warning"
                    title="Для добавления представителей сохраните клиента"
                />
            </Card>
        )
    }
    const {loading, data = {parents: []}} = query;
    const parentCols = [
        {
            title: "ФИО",
            dataIndex: "fio"
        },
        {
            title: "Телефон",
            dataIndex: "phoneNumber"
        },
        {
            title: "",
            render: (row) => (<Button type="link" onClick={() => history.push(`/parent/${row.id}`)}>Ред.</Button>)
        },
    ];

    const changeParents = () => setSelectParentsMode(true);

    if (selectParentsMode)
        return (<SelectParents navigation={{history}} refetchParents={query.refetch} childId={childId} existParents={data.parents} setSelectParentMode={setSelectParentsMode}/>);

    const dataSource = data.parents.map(parent => ({...parent, fio: `${parent.lastName || "" } ${parent.name || ""} ${parent.surName || "" }`}));

    return (
        <Spin spinning={loading}>
            <DefaultTable selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys}
                          setSearchFilters={setSearchFilters} searchFilters={searchFilters}
                          columns={parentCols} dataSources={dataSource} tableKey="parent" tableTitle="Представители"
                          selectable={selectParentsMode}
                          footer={() => {
                              if (selectedRowKeys) return (<Button type="link" onClick={changeParents}>Изменить список представителей</Button>)
                          }}
            />
        </Spin>
    )
}


function SelectParents({childId, existParents, setSelectParentMode, refetchParents, navigation}) {
    const query = useQuery(GET_PARENTS_FOR_CHILD);
    const [saveParentsForChild] = useMutation(SAVE_PARENTS_FOR_CHILD);
    const {loading, data = {parents: []}} = query;

    const [selectedRowKeys, setSelectedRowKeys] = useState(existParents.map(({id}) => (`parent_${id}`)));
    const [saving, setSaving] = useState(false);
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const parentCols = [
        {
            title: "ФИО",
            dataIndex: "fio"
        },
        {
            title: "Телефон",
            dataIndex: "phoneNumber"
        }
    ];

    const dataSource = data.parents.map(parent => ({...parent, fio: `${parent.lastName || "" } ${parent.name || ""} ${parent.surName || "" }`}));

    const saveParents = () => {
        setSaving(true);
        const parentsIds = selectedRowKeys.map(key => Number.parseInt(key.substring("parent_".length, key.length)));
        saveParentsForChild({variables: {childId, parentsIds}})
            .then(res => {
                refetchParents();
                message.success("Представители сохранены", 3);
                setSelectParentMode(false);
            })
    };

    return (
        <Spin spinning={loading || saving}>
            <DefaultTable selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys}
                          setSearchFilters={setSearchFilters} searchFilters={searchFilters} selectable={true}
                          columns={parentCols} dataSources={dataSource} tableKey="parent" tableTitle="Представители"
                          footer={() => {
                              const btns = [];
                              if (selectedRowKeys) btns.push(<Button type="link" onClick={saveParents}>Сохранить</Button>);

                              btns.push(<Button type="link" onClick={() => navigation.history.push('/parent')}>Добавить</Button>)

                              return btns;
                          }}
            />
        </Spin>
    )

}