import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {DELETE_SERVICE_FROM_SPEC, GET_ALL_SERVICES_FOR_SPEC} from "../lessons/graphqlRequests";
import {Popconfirm, Icon, message, Tooltip} from "antd";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import {ServicesList} from "../services/services";
import {AddServiceModal} from "./addService";

export const Services = ({specId}) => {
    const [addServiceState, changeAddServiceState] = useState({
        visible: false
    })
    const {data = {servicesForSpec: []}, loading, refetch} = useQuery(GET_ALL_SERVICES_FOR_SPEC, {
        variables: {
            id: specId
        }
    });

    const [deleteServiceQuery, {loading: deleteLoading}] = useMutation(DELETE_SERVICE_FROM_SPEC)
    const customColumns = [
        {
            title: "Наименование",
            dataIndex: "name"
        },

        {
            title: "Продолжительность (мин)",
            dataIndex: "duration"
        },
        {
            title: "Стоимость",
            dataIndex: "costForClient"
        },
        {
            title: "Тип",
            dataIndex: "type"
        },
        {
            title: "",
            render: record => (
                <Popconfirm
                    title="Вы действительно хотите удалить услугу у специалиста?"
                    onConfirm={() => deleteService(record.id)}
                    okText="Да"
                    cancelText="Нет"
                >
                    <Tooltip title="Удалить услугу у специалиста" placement={"bottom"}>
                        <Icon type="delete" />
                    </Tooltip>
                </Popconfirm>)
        }
    ]

    const footer = () => (
        <Button type={"link"} id={"add_service_btn"} onClick={addService}>Добавить</Button>
    )

    const deleteService = (id) => {
        const deleteMessage = message.loading("Удаление...")

        deleteServiceQuery({variables: {serviceId: id, specId: specId}})
            .then(() => setTimeout(deleteMessage, 100))
            .then(refetch)
            .then(() => message.success("Сохранено"))
    }

    const addService = (specId) => changeAddServiceState({specId, visible: true, refetch})

    return (
        <Spin spinning={loading || deleteLoading}>
            <ServicesList {...{
                refetch,
                footer,
                customColumns,
                dataSource: data.servicesForSpec,
                title: () => "Услуги"
            }} />
            {
                !specId || !addServiceState.visible ? null :
                    <AddServiceModal specId={specId} props={addServiceState} changeProps={changeAddServiceState}/>
            }
        </Spin>
    )
}