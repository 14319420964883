import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spin from "antd/es/spin";
import Card from "antd/es/card";
import Col from "antd/es/grid/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Row from "antd/es/grid/row";
import {message} from "antd";
import Popconfirm from "antd/es/popconfirm";

const GET_LOCATION = gql`query GET_LOCATION ($id: ID) {
    location(id: $id) {
        id
        address
        cabinet
    }
}`;

const LOCATION_MUTATION = gql`mutation update_location($id: ID, $address: String!, $cabinet: String!, $description: String) {
    updateLocation(id: $id, address: $address, cabinet: $cabinet, description: $description) {
        id
        address
        cabinet
    }
}`;

const DELETE_LOCATION_MUTATION = gql`mutation delete_location($id: ID) {
    deleteLocation(id: $id) {
        id
    }
}`;

const initialLocation = {
    address: "", id: null, cabinet: ""
};
export default function Location() {
    const {id} = useParams();
    const history = useHistory();
    const query = useQuery(GET_LOCATION, {variables: {id: Number.parseInt(id)}, skip: !id});
    const [updateLocation] = useMutation(LOCATION_MUTATION);
    const [deleteLocationMutation] = useMutation(DELETE_LOCATION_MUTATION);
    const {data = {location: initialLocation}, loading} = query;

    const [location, setLocation] = useState(initialLocation);
    useEffect(() => {
        setLocation(data.location);
    }, [data.location, query]);

    const changeField = ({target: {id, value}}) => {
        if (value) {
            value = value[0].toUpperCase() + value.slice(1);
        }

        setLocation({...location, [id]: value})
    };

    const deleteLocation = () => {
        const savingMessage = message.loading("Сохранение...", 0);
        deleteLocationMutation({variables: location})
            .then(() => {
                setTimeout(savingMessage, 1000);
                message.success("Сохранено!", 4);
            }).then(() => history.push("/locations"))
    };

    const saveLocation = () => {
        const savingMessage = message.loading("Сохранение...", 0);
        updateLocation({variables: location})
            .then(() => {
                setTimeout(savingMessage, 1000);
                message.success("Сохранено!", 4);
            }).then(() => history.push("/locations"))
    };

    return (
        <Spin spinning={loading}>
            <Card size="small" title="Карточка кабинета">
                <Row gutter={[8, 8]}>
                    <Col>
                        <Col span={12}>
                            <Form.Item label="Адрес" required>
                                <Input placeholder="Адрес" required value={location.address} id="address" onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Кабинет" required>
                                <Input placeholder="Кабинет" required value={location.cabinet} id="cabinet" onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Описание">
                                <Input.TextArea id="description" value={location.description} onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button type="primary" style={{width: '100%'}} onClick={saveLocation}>Сохранить</Button>
                        </Col>
                        <Col span={24}>
                            <Popconfirm
                                title="Вы дейтвительно хотите это сделать?"
                                onConfirm={deleteLocation}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <Button hidden={!location.id} disabled={!location.id}
                                        type="danger" style={{width: '100%'}}>Удалить</Button>
                            </Popconfirm>
                        </Col>
                    </Col>
                </Row>
            </Card>
        </Spin>
    )
}