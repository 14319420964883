import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import Spin from "antd/es/spin";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import React, { useEffect, useState } from "react";
import { Icon } from "antd";
import Tooltip from "antd/es/tooltip";
import { GET_LESSONS_FOR_SPECS, GET_LESSONS_FOR_CHILDREN, GET_LESSONS_FOR_LOCATIONS } from "./graphqlRequests";
import LessonModal from "../lessons/modal";
import { useStateWithLocalStorage } from "../../utils/localStorageState";
import {sendScheduleTiming} from "../../analytics";

moment.locale("ru");
const localizer = momentLocalizer(moment);
const POOL_INTERVAL = 1000 * 3; //3 seconds

const DragAndDropCalendar = withDragAndDrop(Calendar);

export const RESOURCE_TYPE = { SPECS: "SPECS", CHILDREN: "CHILDREN", LOCATIONS: "LOCATIONS" };
const GET_RESOURCE_HANDLER = { SPECS: useSpecialistsSchedule, CHILDREN: useChildrenSchedule, LOCATIONS: useLocationsSchedule };

export default function ScheduleCalendar({ schedule = { type: RESOURCE_TYPE.SPECS, ids: [], statuses: [] }, setSchedule, customProps = {} }) {
    const history = useHistory();
    const location = useLocation();
    const [moved, setMoved] = useState(null);
    const [viewInLocalStore, setViewInLocalStore] = useStateWithLocalStorage({ key: 'selectedView', initialValue: "day" });
    const [calendar, setCalendar] = useState({
        dates: {
            start: moment().subtract(1, 'w'),
            end: moment().add(1, "w")
        }
    });

    useEffect(() => {
        setCalendar({
            ...calendar, dates: {
                start: moment(schedule.date).subtract(1, 'w'),
                end: moment(schedule.date).add(1, 'w')
            }
        })
    }, [schedule]);

    const { type, ids } = schedule.resources || { type: RESOURCE_TYPE.SPECS, ids: [] };
    const targetSchedule = GET_RESOURCE_HANDLER[type](ids, calendar.dates, schedule.statuses);

    const [lessonModal, setLessonModal] = useState({ visible: false, lesson: { id: null } });
    const resourceMap = [...targetSchedule.resources,];
    const events = [...targetSchedule.events,];
    const loading = targetSchedule.loading;

    const moveEvent = ({ start, resourceId, event: { lessonId } }) => {
        setMoved(null);
        setLessonModal({ ...lessonModal, lesson: { id: lessonId, newDateTime: start }, visible: true })
    };

    const onRangeChange = (periods, view) => {
        if (!view || view === "week") {
            setCalendar({
                ...calendar, dates: {
                    start: moment(periods[0]),
                    end: moment(periods[periods.length - 1]),
                }
            })
        } else {
            if (view === 'day') {
                setCalendar({
                    ...calendar, dates: {
                        start: moment(periods[0]),
                        end: moment(periods[0]),
                    }
                })
            } else {

            }
        }
    };
    const onNavigate = (date, view, action) => {
        setSchedule({ ...schedule, date: moment(date).set({ 'hour': 0, 'minute': 0, 'second': 0 }).toDate() })
    };
    
    return (
        <Spin spinning={loading}>
            <DragAndDropCalendar
                style={{ height: '80vh' }}
                localizer={localizer}
                events={events}
                resources={resourceMap}
                selectable
                onNavigate={onNavigate}
                step={10}
                timeslots={3}
                onRangeChange={onRangeChange}
                showMultiDayTimes={false}
                startAccessor={(event) => event.start}
                endAccessor={(event) => event.end}
                length={7}
                tooltipAccessor={getTooltipAccessor}
                date={moment(schedule.date).set({ 'hour': 0, 'minute': 0, 'second': 0 }).toDate()}
                drilldownView="day"
                defaultView={"week"}
                view={viewInLocalStore}
                onView={setViewInLocalStore}
                views={['month', 'week', 'day']}
                min={getTime(8, 30)}
                max={getTime(21, 0)}
                resizable={false}
                onDragStart={({ event: { lessonId } }) => setMoved(lessonId)}
                onEventDrop={moveEvent}
                onSelectEvent={(event) => onSelectEvent(event, history, location)}
                eventPropGetter={event => getEventProps(event, moved)}
                titleAccessor={(event) => getEventTitle(event, history)}
                resourceTitleAccessor="resourceTitle"
                slotPropGetter={(dateTime, resourceId) => getSlotProperties(dateTime, resourceMap.find(res => res.id === resourceId))}
                onSelectSlot={(slotInfo) => onSelectSlot(slotInfo, resourceMap, history)}
                {...customProps}
            />
            <LessonModal {...{ lessonModal }} lessonCallback={() => setLessonModal({ ...lessonModal, visible: false })} />
            {/* <ScheduleLessonDrawer {...{scheduleLessonDrawer, setScheduleLessonDrawer}} /> */}
        </Spin>
    )
}

const onSelectEvent = (event, history, location) => {
    history.push({
        pathname: "/schedule/info/" + event.lessonId,
        state: {
            background: location
        }
    })
}

const eventsStatusesProps = [
    {
        status: "Предстоит", color: "#096dd9",
        icon: (<Tooltip title="Занятие предстоит"><Icon type="info" /></Tooltip>)
    },
    {
        status: "Отменен", color: "#8c8c8c",
        icon: (<Tooltip title="Занятие отменено"><Icon type="close" /></Tooltip>)
    },
    {
        status: "Ожидает_решения", color: "#cf1322",
        icon: (<Tooltip title="Занятие ожидает решения"><Icon type="pause" /></Tooltip>)
    },
    {
        status: "Завершен", color: "#096dd9",
        icon: (<Tooltip title="Занятие завершено"><Icon type="check" /></Tooltip>)
    },
    {
        status: "Проводится", color: "#096dd9",
        icon: (<Tooltip title="Занятие проводится"><Icon type="clock" /></Tooltip>)
    },
    {
        status: "Резерв", color: "#7cb305",
        icon: (<Tooltip title="Резерв"><Icon type="clock" /></Tooltip>)
    },
    {
        status: "Не_дозвонились", color: "#d4b106",
        icon: (<Tooltip title="Не зодвонился"><Icon type="clock" /></Tooltip>)
    },

];

const getEventProps = (event, moved) => {
    let style = {
        background: eventsStatusesProps.find(item => item.status === event.status).color,
        color: '#fff'
    };

    if (event.serviceType === "Первичное" && event.status !== "Отменен") {
        style.background = "#fa8c16"
    }

    if (event.lessonId === moved) {
        style = {
            ...style,
            background: "#bfbfbf",
            borderColor: "#f5222d"
        };
    }

    return { style }
};

const isCallAboutLessonIcons = [
    {
        status: false,
        icon: (<Tooltip title="Звонок не прозведен"><Icon type="phone" /></Tooltip>)
    },
    {
        status: true,
        icon: (<Tooltip title="Звонок был прозведен"><Icon type="check" /></Tooltip>)
    },
];

const getEventTitle = (event, history) => {
    const statusIcon = eventsStatusesProps.find(item => item.status === event.status).icon
    const notifyIcon = isCallAboutLessonIcons.find(item => item.status === event.isConfirmed).icon

    return (
        <span>
            {statusIcon}{notifyIcon} {event.title}
        </span>
    );
};

const getSlotProperties = (dateTime, resource) => {
    const isSlotActive = isTimeSlotActive(dateTime, resource);
    if (!isSlotActive) {
        return { style: { background: '#d9d9d9' } }
    }
    return { style: { background: '#fafafa' } }
};

const isTimeSlotActive = (dateTime, resource = { workPeriods: [], type: "specialist" }) => {
    const { workPeriods, type } = resource;
    if (type === "child") return true;
    if (workPeriods === undefined) return true;
    if (!workPeriods) return false;
    const mDateTime = moment(dateTime);

    if (!workPeriods.flatMap(period => period.dayOfWeek).includes(mDateTime.weekday() + 1)) {
        return false
    } else {
        const tmp = workPeriods
            .map(period => ({
                ...period, tmpFrom: moment(`${mDateTime.format("DD-MM-YYYY")} ${period.from_time}`, "DD-MM-YYYY HH:mm"),
                tmpTo: moment(`${mDateTime.format("DD-MM-YYYY")} ${period.to_time}`, "DD-MM-YYYY HH:mm")
            }));

        if (!tmp.filter(period => period.dayOfWeek === mDateTime.weekday() + 1)
            .some(period => mDateTime.isBetween(period.tmpFrom, period.tmpTo, true) || mDateTime.isSame(period.tmpFrom))) {
            return false
        }
    }

    return true;
};

const onSelectSlot = (slotInfo, resourceMap, history) => {
    const resource = resourceMap.find(item => item.id = slotInfo.resourceId);
    if (slotInfo.action !== "doubleClick") return;

    history.push({
        pathname: '/lesson/',
        state: {
            date: slotInfo.start,
            resource: {
                id: slotInfo.resourceId,
                type: resource.type
            }
        }
    })
};

function getResources(items, resourceType, getResourceTitle) {
    return items.map(item => {
        const res = {
            id: Number.parseInt(item.id),
            type: resourceType,
            resourceTitle: getResourceTitle(item)
        };
        if (resourceType === "specialist") {
            res.workPeriods = item.workPeriods.filter(wp => !wp.isDeleted)
        }

        return res;
    })
}

function getEvents(lessons, lessonTitle, owner) {
    return lessons.map(lesson => {
        const lessonStart = moment(`${lesson.date} ${lesson.time}`, "YYYY-MM-DD HH:mm:00");
        const lessonEnd = moment(`${lesson.date} ${lesson.time}`, "YYYY-MM-DD HH:mm:00").add(lesson.duration, 'm');
        return {
            lessonId: lesson.id,
            title: lessonTitle(lesson),
            start: lessonStart.toDate(),
            end: lessonEnd.toDate(),
            resourceId: Number.parseInt(lesson[owner].id),
            allDay: false,
            status: lesson.status.name,
            isCalled: lesson.isCalled,
            isConfirmed: lesson.isCalled || lesson.isConfirmed,
            parentInfo: getParentInfo(lesson.child),
            child: lesson.child,
            service: lesson.service ? lesson.service.name : "NONE",
            serviceType: lesson.service.type,
            description: lesson.description || "",
        }
    }).sort((l1, l2)=> l1.lessonId - l2.lessonId);
}

const getTooltipAccessor = (event) => {
    const { child, service, status, description } = event;
    return `
Реб: ${child.lastName} ${child.name} (${child.birthday ? moment(child.birthday).format("DD.MM.YYYY") : "Нет даты рожд."})
О реб.: ${child.description || "Нет записей"}
Род: ${event.parentInfo}
Занятие: ${service}
Статус: ${status}
Описание: ${description || "Нет записей"}`
};

function useChildrenSchedule(ids = [], dates, statuses) {
    const startMoment = Date.now()
    const { data = { childrenByIds: [], lessonsForChildren: [] }, loading } = useQuery(GET_LESSONS_FOR_CHILDREN, {
        variables: {
            ids: ids.map(id => Number.parseInt(id)),
            startDate: dates.start.format("YYYY-MM-DD"),
            endDate: dates.end.format("YYYY-MM-DD"),
            statuses
        },
        onCompleted: _ => {
            sendScheduleTiming('Загрузка расписания для ребенка', startMoment)
        },
        skip: !ids || ids.length === 0,
        pollInterval: POOL_INTERVAL
    });

    useEffect(() => {
        sendScheduleTiming('Загрузка расписания для ребенка', startMoment)
    }, [data])

    const resources = getResources(data.childrenByIds, "child",
        (item) => (`Реб. ${item.lastName} ${item.name} (${item.birthday || "Дата рождения не задана"})`));

    const events = getEvents(data.lessonsForChildren,
        (lesson) => (`Спец. ${lesson.specialist.lastName} ${lesson.specialist.name} | ${lesson.service.name}`),
        'child');

    return { resources, loading, events }
}

const getParentInfo = ({ parents = [] }) => {
    if (!parents.length) return "Нет данных";

    return `${parents[0].name || ""} ${parents[0].surName || ""} ${parents[0].phoneNumber || "Номер тел. не задан"}`
        .replace("  ", " ")
};

function useLocationsSchedule(ids = [], dates, statuses) {
    const startMoment = Date.now()
    const { data = { locations: [], lessonsForLocations: [] }, loading } = useQuery(GET_LESSONS_FOR_LOCATIONS, {
        variables: {
            ids: ids.map(id => Number.parseInt(id)),
            startDate: dates.start.format("YYYY-MM-DD"),
            endDate: dates.end.format("YYYY-MM-DD"),
            statuses
        },
        onCompleted: _ => {
            sendScheduleTiming('Загрузка расписания для кабинета', startMoment)
        },
        skip: ids.length === 0,
        pollInterval: ids.length === 0 ? 0 : POOL_INTERVAL
    });
    const events = getEvents(data.lessonsForLocations,
        (lesson) => (`Реб. ${lesson.child.lastName} ${lesson.child.name}`),
        'location');
    const resources = getResources(data.locations, RESOURCE_TYPE.LOCATIONS,
        (item) => (`${item.address} каб. ${item.cabinet}`));
    return { events, resources, loading }
}

function useSpecialistsSchedule(ids = [], dates, statuses) {
    const startMoment = Date.now()
    const { data = { specialists: [], lessonsForSpecs: [] }, loading } = useQuery(GET_LESSONS_FOR_SPECS, {
        variables: {
            ids: ids.map(id => Number.parseInt(id)),
            startDate: dates.start.format("YYYY-MM-DD"),
            endDate: dates.end.format("YYYY-MM-DD"),
            statuses: statuses
        },
        skip: ids.length === 0,
        onCompleted: _ => {sendScheduleTiming('Загрузка расписания для специалиста', startMoment)},
        pollInterval: ids.length === 0 ? 0 : POOL_INTERVAL
    });

    const resources = getResources(data.specialists, "specialist",
        (item) => (`Спец. ${item.lastName} ${item.name} ${item.surName}`));

    const events = getEvents(data.lessonsForSpecs,
        (lesson) => (<span>{lesson.child.lastName} {lesson.child.name}</span>),
        'specialist');

    return { events, resources, loading }
}

function getTime(hours, minutes) {
    const date = new Date();
    date.setHours(hours, minutes, 0);
    return date;
}