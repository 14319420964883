import React from "react";
import moment from "moment";
import HistoryPanel, {HISTORY_ENTITY} from "../history";

const SpecialistHistory = ({props, setProps}) => <HistoryPanel props={{...props, entity: HISTORY_ENTITY.SPEC, historyFields}} setProps={setProps}/>

const historyFields = [
    {key: "lastName", label: "Фамилия"},
    {key: "name", label: "Имя"},
    {key: "surName", label: "Отчество"},
    {key: "", label: "Дата рождения", render: ({birthday}) => !birthday ? "" : moment(birthday).format("DD.MM.YYYY")},
    {key: "isDeleted", label: "Удален", render: ({isDeleted}) => isDeleted ? "Да" : "Нет"},
    {key: "description", label: "Описание"},
]

export default SpecialistHistory;