import gql from "graphql-tag";

const lesson = `id
        date
        time
        isCalled
        duration
        description
        isCalled
        isConfirmed
        status {
            name
        }
        service {
            id
            name
            type
            duration
        }
`

const parents = `parents {
                id
                surName
                name
                phoneNumber
            }`
const GET_LESSONS_FOR_SPECS = gql`query getInfo($ids: [ID], $startDate: String, $endDate: String, $statuses: [String]) {
    lessonsForSpecs(ids: $ids, startDate: $startDate, endDate: $endDate, statuses: $statuses) {
        ${lesson}
        child {
            id
            lastName
            name
            description
            birthday
            ${parents}
        }
        specialist {
            id
        }
    }
    specialists(ids: $ids) {
        id
        name
        lastName
        surName
        workPeriods {
            id
            dayOfWeek
            from_time
            to_time
            isDeleted
        }
    }
}`;
const GET_LESSONS_FOR_LOCATIONS = gql`query getInfo($ids: [ID], $startDate: String, $endDate: String, $statuses: [String]) {
    lessonsForLocations(ids: $ids, startDate: $startDate, endDate: $endDate, statuses: $statuses) {
        ${lesson}
        child {
            id
            description
            lastName
            birthday
            name
            ${parents}
        }
        specialist {
            id
            name
            lastName
        }
        location {
            id
        }
    }
    locations(ids: $ids) {
        id
        address
        cabinet
    }
}`;
const GET_LESSONS_FOR_CHILDREN = gql`query getInfo($ids: [ID], $startDate: String, $endDate: String, $statuses: [String]) {
    lessonsForChildren(ids: $ids, startDate: $startDate, endDate: $endDate, statuses: $statuses) {
        ${lesson}
        child {
            id
            description
            lastName
            name
            birthday
        }
        specialist {
            id
            name
            lastName
        }
    }
    childrenByIds(ids: $ids) {
        id
        name
        lastName
        birthday
        description
        ${parents}
    }
}`;

export {
    GET_LESSONS_FOR_SPECS,
    GET_LESSONS_FOR_CHILDREN,
    GET_LESSONS_FOR_LOCATIONS
}