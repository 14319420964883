import Modal from "antd/es/modal";
import Lesson from "./lesson";
import React from "react";

export default function LessonModal({lessonModal, lessonCallback}) {
    return (
        <Modal visible={lessonModal.visible} onCancel={lessonCallback} width={1200} footer={null}
               style={{ top: 20 }}
               title={lessonModal.setIdNull ? "Копирование занятия" : lessonModal.lesson.id ? "Редактирование занятия" : "Создание занятия"}
        >
            <Lesson id={lessonModal.lesson.id}
                    setIdNull={lessonModal.setIdNull}
                    newDateTime={lessonModal.lesson.newDateTime}
                    lessonCallback={lessonCallback}
            />
        </Modal>
    )
};
