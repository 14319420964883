import moment from "moment";
import DefaultSelector from "../utils/DefaultSelector";
import React, {useEffect} from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_CHILDREN} from "../lessons/graphqlRequests";
import {Spin} from "antd";

export const ClientSelector = (props) => {
    const {data: childrenData = {children: []}, refetch: refetchChildren} = useQuery(GET_ALL_CHILDREN, {
        variables: {
            page: 0,
            count: 0
        }
    });

    useEffect(() => {
        refetchChildren();
    }, [props, refetchChildren])

    return (
        <DefaultSelector {...props}
                         placeholder="Выберите клиента"
                         onChange={id => props.onChange(childrenData.children.find(client => client.id === id ))}
                         id={"client_selector"}
                         dataTitle="fio" dataIndex="id"
                         options={childrenData.children.map(child =>
                             ({
                                 ...child,
                                 fio: `${child.birthday ? moment(child.birthday).format("DD.MM.YYYY") : ""} ${child.lastName || "Фамилия не задана"} ${child.name || "Имя не задано"} ${child.surName || ""}`
                             })
                         )}
        />
    )
}

export const ClientsSelector = (props) => {
    const {loading, data: childrenData = {children: []}, refetch: refetchChildren} = useQuery(GET_ALL_CHILDREN, {
        variables: {
            page: 0,
            count: 0
        }
    });

    useEffect(() => {
        refetchChildren();
    }, [props, refetchChildren])

    return (
        <Spin spinning={loading}>
            <DefaultSelector {...props} mode={"multiple"}
                         placeholder="Выберите клиентов"
                         onChange={props.onChange}
                         id={"client_selector"}
                         dataTitle="fio" dataIndex="id"
                         options={childrenData.children.map(child =>
                             ({
                                 ...child,
                                 fio: `${child.birthday ? moment(child.birthday).format("DD.MM.YYYY") : ""} ${child.lastName || "Фамилия не задана"} ${child.name || "Имя не задано"} ${child.surName || ""}`
                             })
                         )}
        />
        </Spin>
    )
}