import axios, { AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getAccessToken, setAccessToken } from '../auth/tokenUtils';
import { getNewToken } from './connections';

/**
 * Create global HTTP client instance
 * and set app defaults
 */
export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const tokenInterceptor = async (config: AxiosRequestConfig) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  };

const refreshTokenInterceptor = (failedRequest:any) => getNewToken().then(tokenRefreshResponse => {
    console.log('refreshTokenInterceptor', failedRequest);
    console.log('tokenRefreshResponse', tokenRefreshResponse);
    
    setAccessToken(tokenRefreshResponse.data.token);
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
    return Promise.resolve();
});
/**
 * Init request and response interceptors
 */
axiosInstance.interceptors.request.use(tokenInterceptor);
createAuthRefreshInterceptor(axiosInstance, refreshTokenInterceptor);

