import moment from "moment";
import DefaultTable from "../utils/DefaultTable";
import React, {useEffect, useState} from "react";
import {Divider} from "antd";
import Button from "antd/es/button";
import { useHistory, useLocation } from "react-router-dom";

export const LessonsTable = ({
                                 dataSources = [],
                                 columns,
                                 setSearchFilters,
                                 searchFilters,
                                 selectedRowKeys,
                                 setSelectedRowKeys,
                                 tableProps = {},
                                 scheduleProps = undefined
                             }
) => {
    const history = useHistory()
    const location = useLocation();
    const [pagination, setPagination] = useState({
        pageSize: 10,
        onChange: (page) => setPagination({...pagination, current: page})
    });
    const sortedDataSource = dataSources.sort((l1, l2) => Date.parse(`${l1.date} ${l1.time}`) - Date.parse(`${l2.date} ${l2.time}`))
    useEffect(() => {
        if (!pagination.current) {
            const nextLessonIndex = dataSources.findIndex(({date, time}) => moment(`${date} ${time}`).isAfter(moment()))
            setPagination({...pagination, current: Math.floor(nextLessonIndex/10) + 1})
        }
    }, [sortedDataSource])

    const scheduleButton = !scheduleProps ? null : (
        <Button type="link" key={"action_show_timetable"}
                id={`row_btn_schedule`}
                onClick={() => history.push({
                    pathname: `/schedule/${scheduleProps.entity}/${scheduleProps.id}`,
                    state: {
                        background: location,
                    }
                })}>
            Показать календарь
        </Button>
    )
    return (
        <DefaultTable selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys}
                      setSearchFilters={setSearchFilters} searchFilters={searchFilters}
                      columns={columns} dataSources={sortedDataSource} tableKey="lessons"
                      tableTitle={<><span>Занятия</span><Divider type={"vertical"}/>{scheduleButton}</>}
                      selectable={true}
                      customProps={{
                          onRow: tableRowProps,
                          ...tableProps,
                          pagination: pagination
                      }}
        />
    )
}

const tableRowProps = (record) => {
    const today = moment();
    const someday = moment(`${record.date} ${record.time}`);

    if (someday.isBefore(today)) return {style: {backgroundColor: "#eaeaea"}}

    return {}
}