import React from "react";
import DatePicker from "antd/es/date-picker";

const DEFAULT_DATE_FORMATS = ["DD.MM.YYYY", "DDMMYYYY", "DDMMYY", "DDMM"];

export default function DefaultDatePicker(props) {

    return (
        <DatePicker format={DEFAULT_DATE_FORMATS} id={"date_picker"}
                    style={{width: "100%"}} placeholder={"Выберите дату"}
                    {...props}
        />
    )
}

export {DEFAULT_DATE_FORMATS}