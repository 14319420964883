import gql from "graphql-tag";

const GET_LESSONS_FOR = gql`query getLessonsForChild($id: ID!, $type: String!){
    lessonsFor(id: $id, type: $type) {
        id
        date
        time
        status {
            name
        }
        specialist {
            id
            lastName
            name
            surName
        }
        child {
            id
            name
            lastName
            birthday
        }
        service {
            id
            name
        }
        location {
            id
            address
            cabinet
        }
    }
}`;
const GET_LESSONS_BY_IDS = gql`query lessonsByIds($ids: [ID!]) {
    lessonsByIds(ids: $ids){
        id
        date
        time
        cost
        duration
        isCalled
        priority {
            name
        }
        status {
            name
        }
        specialist {
            id
            lastName
            name
            surName
        }
        child {
            id
            name
            lastName
            surName
            birthday
        }
        service {
            id
            name
        }
        location {
            id
            address
            cabinet
        }
}}`
const UPDATE_LESSON_STATUS = gql`mutation updateLessonStatus($id: ID!, $status: String!) {
    updateLessonStatus(lessonId: $id, newStatus: $status) {
        id
        status {
            name
        }
    }
}`;
const DELETE_LESSONS = gql`mutation deleteLessons($ids: [ID]!) {
    deleteLessons(ids: $ids) {
        id
    }
}`;

const UPDATE_LESSONS_DATE = gql`mutation updateLessonsDate($ids: [ID]!, $date: String, $time: String) {
    updateLessonsDate(ids: $ids, date: $date, time: $time) {
        id
    }
}`;

const FIND_LESSON = gql`query FIND_LESSON($id: ID!) {
    lesson(id: $id) {
        id
        date
        time
        duration
        isDeleted
        created
        isCalled
        isConfirmed
        cost
        description
        status {
            name
        }
        priority {
            name
        }
        specialist {
            id
        }
        location {
            id
        }
        child {
            id
        }
        service {
            id
        }

    }
}`;


const GET_ALL_CHILDREN = gql`query getChildren($page: Int, $count: Int, $searchText: String, $searchedColumn: String) {
    children(page: $page, count: $count, searchText: $searchText, searchedColumn: $searchedColumn) {
        id
        name
        lastName
        surName
        birthday
    }
}`;

const GET_ALL_CHILDREN_WITH_PARENTS = gql`
    query getChildren($page: Int, $count: Int, $searchText: String, $searchedColumn: String) {
        children(page: $page, count: $count, searchText: $searchText, searchedColumn: $searchedColumn) {
            id
            name
            lastName
            surName
            birthday
            parents {
                id
                name
                lastName
                surName
                birthday
                phoneNumber
            }
        }
    }`;

const GET_ALL_LOCATIONS = gql`query GET_ALL_LOCATIONS {
    locations {
        id
        address
        cabinet
    }
}`;

const GET_ALL_SPECS = gql`query GET_ALL_SPECS {
    specialists {
        id
        lastName
        name
        surName
        cabinets {
            id
        }
    }
}`;

const GET_ALL_FAVORS_FOR_SPEC = gql`query GET_ALL_FAVORS_FOR_SPEC($specId: ID) {
    favors(specId: $specId) {
        id
        name
        duration
        costForClient
    }
}`;

const GET_ALL_SERVICES_FOR_SPEC = gql`query GET_ALL_SERVICES_FOR_SPEC($id: ID!) {
    servicesForSpec(id: $id) {
        id
        name
        duration
        costForClient
        type
    }
}`;

const LESSON_MUTATION = gql`mutation LESSON_MUTATION(
    $id: ID,
    $specId: ID!,
    $priority: String,
    $status: String,
    $time: String!,
    $date: String!,
    $cost: Int!,
    $duration: Int!
    $childId: ID!,
    $serviceId: ID!,
    $locationId: ID!,
    $isCalled: Boolean,
    $isDeleted : Boolean,
    $description: String
){
    updateLesson(
        id: $id,
        specId: $specId,
        priority: $priority,
        status: $status,
        time: $time,
        date: $date,
        childId: $childId,
        cost: $cost,
        duration: $duration,
        serviceId: $serviceId,
        locationId: $locationId
        isDeleted: $isDeleted,
        isCalled: $isCalled
        description: $description
    ) {
        id
    }
}`;

const DELETE_SERVICE_FROM_SPEC = gql`mutation DELETE_SERVICE_FROM_SPEC($serviceId: ID!, $specId: ID!) {
    deleteServiceFromSpec(serviceId: $serviceId, specId: $specId) {
        id
    }
}`

const ADD_SERVICES_FOR_SPEC = gql`mutation ADD_SERVICE_FOR_SPEC($servicesId: [ID]!, $specId: ID!) {
    addServicesForSpec(servicesId: $servicesId, specId: $specId) {
        id
    }
}`

export {
    UPDATE_LESSON_STATUS,
    GET_LESSONS_BY_IDS,
    GET_LESSONS_FOR,
    DELETE_LESSONS,
    UPDATE_LESSONS_DATE,
    GET_ALL_SPECS,
    GET_ALL_CHILDREN,
    FIND_LESSON,
    GET_ALL_FAVORS_FOR_SPEC,
    GET_ALL_LOCATIONS,
    LESSON_MUTATION,
    GET_ALL_SERVICES_FOR_SPEC,
    DELETE_SERVICE_FROM_SPEC,
    ADD_SERVICES_FOR_SPEC,
    GET_ALL_CHILDREN_WITH_PARENTS
};