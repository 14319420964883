import React, {useState} from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import DefaultTable from "../utils/DefaultTable";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import {useHistory, useLocation} from "react-router-dom";
import Divider from "antd/es/divider";
import {Col} from "antd";
import Row from "antd/es/grid/row";

const GET_SPECIALISTS = gql`{
    specialists {
        id
        name
        lastName
        surName
    }
}`;


export default function Specialists() {
    const history = useHistory();
    const location = useLocation();
    const getSpecialistsQuery = useQuery(GET_SPECIALISTS);
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const {loading, data = {specialists: []}} = getSpecialistsQuery;
    const cols = [
        {
            title: "ФИО",
            dataIndex: "fio"
        },
        {
            title: "Действия",
            dataIndex: "",
            render: (row) => ([
                (<Button key="btn_1" type="link" onClick={() => history.push(`/specialist/${row.id}`)}>Карточка</Button>),
                (<Divider key="btn_2" type="vertical"/>),
                (<Button type="link" key="btn_3"
                         onClick={() => history.push({
                             pathname: `/schedule/specialist/${row.id}`,
                             state: {
                                 background: location
                             }
                         })}>
                    Расписание
                </Button>),
                (<Divider type="vertical" key="divider_1"/>),
                (<Button key="btn_4" type="link" onClick={() => history.push({
                    pathname: `/lesson/`,
                    state: {
                        resource: {type: "specialist", id: row.id}
                    }
                })}>Добавить занятие</Button>)
            ])
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Spin spinning={loading}>
                <Col span={24}>
                    <DefaultTable columns={cols}
                                  dataSources={data.specialists
                                      .map(record => ({...record, fio: `${record.lastName} ${record.name} ${record.surName}`}))}
                                  setSearchFilters={setSearchFilters}
                                  searchFilters={searchFilters}
                                  tableKey="spec_"
                                  footer={() => (
                                      <Button id={"add_btn"} type="link" onClick={() => history.push("/specialist/")}>
                                          Добавить
                                      </Button>
                                  )}
                    />
                </Col>
            </Spin>
        </Row>
    )
}