import {useMutation, useQuery} from "@apollo/react-hooks";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Button from "antd/es/button";
import {Divider, message, Modal} from "antd";
import Row from "antd/es/grid/row";
import Popconfirm from "antd/es/popconfirm";
import Col from "antd/es/grid/col";
import Card from "antd/es/card";
import Result from "antd/es/result";
import Spin from "antd/es/spin";
import {useHistory} from 'react-router-dom';
import Input from "antd/es/input";
import {Form} from "antd/es";
import {DELETE_LESSONS, GET_LESSONS_FOR, UPDATE_LESSON_STATUS, UPDATE_LESSONS_DATE} from "./graphqlRequests";
import LessonModal from "./modal";
import Calendar from "antd/es/calendar";
import {LessonsTable} from "./table";
import {sendLessonTiming} from "../../analytics";
import { axiosInstance } from "../../utils/axios";
import DefaultTable from "../utils/DefaultTable";

export default function Lessons({childId = null, specId = null}) {
    const type = childId ? "child" : specId ? "specialist" : null;
    const startMoment = Date.now();
    const history = useHistory();
    const [lessonModal, setLessonModal] = useState({visible: false, lesson: {id: null}});
    const {refetch: refetchLessons, loading, error, data = {lessonsFor: []}} = useQuery(GET_LESSONS_FOR, {
        skip: !(childId || specId),
        variables: {
            id: childId ? childId : specId,
            type
        }
    });

    useEffect(()=> {
        sendLessonTiming('Отображение таблицы занятий', startMoment)
    })
    const [updateLessonStatus] = useMutation(UPDATE_LESSON_STATUS);
    const [moveLessonsMutation] = useMutation(UPDATE_LESSONS_DATE);
    const [deleteLessonsMutation] = useMutation(DELETE_LESSONS);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [movingModalProps, setMovingModal] = useState({visible: false, ok: {isActive: true}, date: null});
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const setLessonStatus = (id, status) => {
        updateLessonStatus({variables: {id, status}});
        setTimeout(() => refetchLessons(), 800);
    };

    const notifyAboutLesson = (id) => {
        axiosInstance.get(`/rest/lesson/${id}/notify`);
    }

    const lessonCols = [
        {
            title: "День",
            dataIndex: "day",
            customFilter: true,
            filters: ["Пн", "Вт", "Ср", "Чт", "Пт", "Суб", "Вск"].map(_ => ({text: _, value: _})),
            onFilter: (value, record) => getDayOfWeekName(record.date).indexOf(value) === 0
        },
        {
            title: "Дата",
            dataIndex: "date",
            render: date => {
                return moment(date).format("DD.MM.YY")
            },
            onFilter: (value, record) => moment(record.date).format("DD.MM.YY")
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            sorter: (a, b) => moment(a.date).isAfter(moment(b.date)),
        },
        {
            title: "Время",
            dataIndex: "time",
        },
        {},
        {
            title: "Услуга",
            dataIndex: "serviceName",
            customFilter: true,
            filters: Array.from(new Set(data.lessonsFor.map(lesson => lesson.service.name))).map(_ => ({text: _, value: _})),
            onFilter: (value, record) => record.service.name.indexOf(value) === 0
        },
        {
            title: "Точка\\Кабинет",
            dataIndex: "locationName",
            customFilter: true,
            filters: Array.from(new Set(data.lessonsFor.map(lesson => `${lesson.location.address} каб. ${lesson.location.cabinet}`))).map(_ => ({text: _, value: _})),
            onFilter: (value, record) => record.locationName.indexOf(value) === 0
        },
        {
            title: "Статус",
            dataIndex: "lessonStatus",
            customFilter: true,
            filters: Array.from(new Set(data.lessonsFor.map(lesson => lesson.status.name))).map(_ => ({text: _, value: _})),
            onFilter: (value, record) => record.lessonStatus.indexOf(value) === 0
        },
        {
            title: "Действия",
            render: row => (
                <span>
                    <Button type="link" key="btn_action_1"
                            onClick={() => setLessonModal({...lessonModal, lesson: {id: row.id}, visible: true})}>Ред.</Button>
                    <Divider key="divider_1" type="vertical"/>
                    <Button key="btn_action_2" type="link" onClick={() => setLessonStatus(row.id, "Отменен")}>Отменить</Button>
                    <Button key="btn_action_3" type="link" onClick={() => notifyAboutLesson(row.id)}>Уведомить</Button>
                </span>
            )
        }
    ];

    lessonCols[3] = type === "child" ? {
        title: "Специалист",
        dataIndex: "specialistFio",
        render: (value, row) => (
            <Button type="link" onClick={() => history.push(`/specialist/${row.specialist.id}`)}>{value}</Button>
        )
    } : {
        title: "Клиент",
        dataIndex: "childFio",
        render: (value, row) => (
            <Button type="link" onClick={() => history.push(`/child/${row.child.id}`)}>{value}</Button>
        )
    };

    const dataSources = data.lessonsFor
        .map(lesson => ({
            ...lesson,
            lessonStatus: lesson.status.name,
            locationName: `${lesson.location.address} каб. ${lesson.location.cabinet}`,
            serviceName: lesson.service.name,
            specialistFio: `${lesson.specialist.lastName} ${lesson.specialist.name}`,
            childFio: `${lesson.child.lastName} ${lesson.child.name}`,
            day: getDayOfWeekName(lesson.date)
        }));

    const cancelSelectedLessons = () => {
        selectedRowKeys
            .map(key => key.substring("lesson_".length + 1, key.length))
            .forEach(id => setLessonStatus(id, "Отменен"));

        setTimeout(() => {
            refetchLessons();
            setSelectedRowKeys([]);
        }, 1000);
    };

    const deleteAllLessons = () => {
        const currentMoment = moment();
        const ids = dataSources
            .filter(({date, time}) => moment(`${date} ${time}`).isAfter(currentMoment))
            .map(lesson => lesson.id)

        deleteLessonsByIds(ids)
    };

    const deleteSelectedLessons = () => {
        const ids = selectedRowKeys
            .map(key => Number.parseInt(key.slice("lessons_".length)))

        deleteLessonsByIds(ids)
    };

    const deleteLessonsByIds = (ids) => {
        const msg = message.loading("Удаление", 0);
        const promice = deleteLessonsMutation({variables: {ids}})

        promice.then(resp => {
            setSelectedRowKeys([]);
            message.success("Сохранено", 4)
        });
        promice.catch(reason => {
            console.error(reason);
            message.error("Возникла ошибка. (См. F12)", 30);
        });
        promice.finally(() => {
            setTimeout(msg, 400);
            refetchLessons();
        });
    };

    const getMovingButton = () => {
        return [
            <Button key="btn_move" onClick={() => setMovingModal({...movingModalProps, visible: true})}
                    disabled={!selectedRowKeys.length} type="link">
                Перенести
            </Button>,
            <Button key="btn_edit_lessons" id={"btn_edit_lessons"}
                    onClick={() => {
                        history.push(`/editLessons`, {
                            lessons: selectedRowKeys.map(key => Number.parseInt(key.substring("lessons_".length, key.length))),
                        })
                    }}
                    disabled={!selectedRowKeys.length} type="link"
            >
                Редактировать
            </Button>,
        ]
    };

    const getDeleteAllButton = () => {
        return (
            <Popconfirm disabled={true}
                key="popconfirm_delete_all" title="Вы точно уверены?"
                okText="Да" cancelText="Нет"
                onConfirm={deleteAllLessons}
            >
                <Button key="btn_delete" disabled={true} type="link">Удалить все будущие занятия</Button>
            </Popconfirm>
        )
    };

    const getDeleteButton = () => {
        return (
            <Popconfirm
                key="popconfirm_delete" title="Вы уверены?"
                okText="Да" cancelText="Нет"
                disabled={!selectedRowKeys.length}
                onConfirm={deleteSelectedLessons}
            >
                <Button key="btn_delete" disabled={!selectedRowKeys.length} type="link">Удалить</Button>
            </Popconfirm>
        )
    };

    if (error) {
        console.error(error);

        return (
            <Row>
                <Col span={24}>
                    <Card size="small" title="Занятия">
                        <Result
                            status="500"
                            title="Ошибка сервера"
                            subTitle="Простите, случилась ошибка. Свяжитесь с администратором!"
                        />
                    </Card>
                </Col>
            </Row>
        )
    } else {
        const tableFooter = () => {
            const res = [];
            if (selectedRowKeys) res.push((
                <Button disabled={!selectedRowKeys.length} key="btn_cancel" type="link" onClick={cancelSelectedLessons}>Отменить</Button>));
            res.push((<Button type="link" key="btn_add"
                              onClick={() => history.push({
                                  pathname: `/lesson/`,
                                  state: {
                                      resource: {type: specId ? "spec" : "child", id: childId || specId}
                                  }
                              })} disabled={!(childId || specId)} >Добавить</Button>));
            res.push(getDeleteButton(), getMovingButton(), getDeleteAllButton());
            return res
        }

        return (
            <Spin spinning={loading}>
                <MovingModal {...{setMovingModal, movingModalProps, selectedRowKeys, setSelectedRowKeys, moveLessonsMutation, refetchLessons}} />
                <LessonModal {...{lessonModal}} lessonCallback={() => {
                    setLessonModal({...lessonModal, visible: false});
                    refetchLessons()
                }}/>
                <LessonsTable selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys}
                              setSearchFilters={setSearchFilters} searchFilters={searchFilters}
                              columns={lessonCols} dataSources={dataSources}
                              tableProps={{
                                  footer: tableFooter
                              }}
                              scheduleProps={{
                                  entity: type,
                                  id: childId ? childId : specId,
                              }}
                />
            </Spin>
        )
    }
}

const MovingModal = ({movingModalProps, setMovingModal, selectedRowKeys, setSelectedRowKeys, refetchLessons, moveLessonsMutation}) => {
    const [mode, setMode] = useState('selecting');

    const setOperation = ({target: {id: mode}}) => setMode(mode);

    useEffect(() => {
        switch (mode) {
            case "moveByDate":
                setMovingModal({...movingModalProps, date: moment().format("YYYY-MM-DD")});
                break;
            case "moveByTime":
                setMovingModal({...movingModalProps, time: moment().format("HH:mm")});
                break;
            default:
                setMovingModal({...movingModalProps, date: null, time: null});
        }
    }, [mode]);

    function displayOperation() {
        switch (mode) {
            case "moveByDate":
                return (
                    <Calendar fullscreen={false} onSelect={(date) => {
                        setMovingModal({...movingModalProps, date: date.format("YYYY-MM-DD")})
                    }}/>
                );
            case "moveByTime":
                return (
                    <Form.Item label="Перенести на другое время">
                        <Input type="time" placeholder="Время" value={movingModalProps.time}
                               onChange={({target: {value}}) => setMovingModal({...movingModalProps, time: value})}/>
                    </Form.Item>
                );
            default:
                return "Обратитесь к администратору";
        }
    }

    const saveMovedLessons = () => {
        setMovingModal({...movingModalProps, ok: {isActive: false}});
        const ids = selectedRowKeys.map(key => key.substring("lessons_".length, key.length));
        const msg = message.loading("Перенос занятий...", 0);

        moveLessonsMutation({variables: {ids, date: movingModalProps.date, time: movingModalProps.time}})
            .then(() => {
                setMovingModal({visible: false, ok: {isActive: true}})
            })
            .then(() => {
                setTimeout(msg, 400)
            })
            .then(() => setTimeout(() => {
                refetchLessons();
                setSelectedRowKeys([]);
            }, 1000))
            .then(() => {
                message.success("Перенос осуществлен", 5)
            })
            .catch(reason => {
                console.error(reason);
                message.error("Произошла ошибка. Подробнее в консоли (F12)", 30)
            })
            .finally(() => {
                setMode("selecting")
            })
    };


    return (
        <Modal visible={movingModalProps.visible} title={"Перенос занятия"}
               onCancel={() => {
                   setMovingModal({...movingModalProps, visible: false});
                   setMode("selecting")
               }}
               okText="Перенести" cancelText="Отмена" okButtonProps={{disabled: !movingModalProps.ok.isActive}}
               onOk={saveMovedLessons}
        >
            <Row gutter={[16, 16]}>
                {mode !== "selecting" ? displayOperation() :
                    [
                        {mode: "moveByDate", text: "Перенести на другую дату"},
                        {mode: "moveByTime", text: "Перенести на другое время"},
                        {mode: "moveBySpecialist", text: "Перенести к другому специалисту", props: {disabled: true}},
                    ].map(it => (
                        <Col span={24}>
                            <Button {...it.props} id={it.mode} style={{width: '100%'}} onClick={setOperation}>{it.text}</Button>
                        </Col>
                    ))
                }
            </Row>
        </Modal>
    )
};

export const getDayOfWeekName = (date) => {
    const number = moment(date).day();
    return ["Вск", "Пн", "Вт", "Ср", "Чт", "Пт", "Суб"][number];
};
