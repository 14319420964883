let userInfo:any = null;

export const setRefreshToken = (token:string) => localStorage.setItem("refreshToken", token);;

export const setAccessToken = (token:string):void => localStorage.setItem("accessToken", token);

export const setUserInfo = (info:any):void => { userInfo = info; };

export const getUserInfo = ():any => userInfo;

export const setExpiresIn = (seconds:number):void => localStorage.setItem("tokenExpiresIn", seconds.toString());

export const getExpiresIn = ():number => Number.parseInt(localStorage.getItem("tokenExpiresIn") || "0");

export const deleteExpiresIn = (seconds:number):void => localStorage.removeItem("tokenExpiresIn");

export const getAccessToken = ():string | null => localStorage.getItem("accessToken");

export const getRefreshToken = ():string | null => localStorage.getItem("refreshToken");

export const deleteAccessToken = ():void => localStorage.removeItem("accessToken");

export const deleteRefreshToken = ():void => localStorage.removeItem("refreshToken");