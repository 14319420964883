import Input from "antd/es/input";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Highlighter from "react-highlight-words";
import Table from "antd/es/table";
import React from "react";

export default function DefaultTable({
                                         id = undefined,
                                         tableKey = "table",
                                         tableTitle = null,
                                         footer = null,
                                         columns = [],
                                         dataSources = [],
                                         selectedRowKeys = [],
                                         setSelectedRowKeys = () => {},
                                         searchFilters = [],
                                         setSearchFilters = () => {},
                                         selectable = false,
                                         onChange = () => {},
                                         pagination = undefined,
                                         customProps = [],
                                         loading = false
                                     }) {
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys([...selectedRowKeys])
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchFilters({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    const handleReset = clearFilters => {
        clearFilters();
        setSearchFilters({searchText: ''});
    };
    const getColumnSearchProps = dataIndex => {
        if (!dataIndex) return {}; else return ({
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Input
                        placeholder={`Найти ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{width: 90, marginRight: 8}}
                    >
                        Найти
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Сбросить
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
            ),
            onFilter: (value, record) => record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            render: text =>
                searchFilters.searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchFilters.searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        })
    };

    const cols = columns.map(col => (!col.customFilter ?{
        ...getColumnSearchProps(col.dataIndex),
        ...col
    } : {...col}));

    return (
        <Table id={id || "data_table"} loading={loading}
               title={tableTitle ? () => (<span key={`${tableKey}_table_title`}>{tableTitle}</span>) : null}
               columns={cols}
               locale={locale}
               dataSource={dataSources}
               rowSelection={selectable ? rowSelection : null}
               rowKey={(record, index) => `${tableKey}_${record.id}`}
               footer={footer} style={{height: '100%'}}
               size="small"
               onChange={onChange}
               pagination={pagination}
               {...customProps}
        />
    )
}

const locale = {
    filterConfirm: 'Применить',
    filterReset: 'Сбросить',
    emptyText: 'Нет записей'
   }