import React from "react";
import {
    Redirect,
    Route,
    useLocation,
} from "react-router-dom";
import 'antd/dist/antd.css'
import ChildPage from "./../../children/child_page";
import ChildrenPage from "./../../children/children_page";
import Parent from "./../../parents/parent";
import ParentsList from "./../../parents/parents_list";
import Specialists from "./../../specialists/specialists";
import Specialist from "./../../specialists/specialist";
import Locations from "./../../locations/locations";
import Location from "./../../locations/location";
import Schedule from "./../../schedule";
import Lesson from "./../../lessons/lesson";
import { RESOURCE_TYPE } from "../../schedule/schedule_calendar";
import Organizations from "./../../organizations/list";
import OrganizationModal from "./../../organizations/modal";
import SpecModalSchedule from "./../../schedule/specModalSchedule";
import ChildModalSchedule from "./../../schedule/childModalSchedule";
import LocationModalSchedule from "./../../schedule/LocationModalSchedule";
import { SearchPanel } from '../../searchPanel';
import ScheduleSettings from "./../../schedule/scheduleSettings";
import ScheduleLessonDrawer from "./../../schedule/drawer"
import { Services } from "../../services/services";
import {NewEditLessonsPage} from "../../lessons/edit";
import { getAccessToken, getRefreshToken } from "../../../auth/tokenUtils";
import Notifications from "../../notifications";
import ReportPage from "../../../pages/report";
import SmsNotificationsPage from "../../notifications/sms";
import AccountsPage from "../../../pages/accounts";
import {EditAccountPage} from "../../../pages/accounts/edit";

const privateRoutes = [
    { exact: true, path: "/lesson/:id?", component: (Lesson) },
    { exact: true, path: "/child/:id?", component: (ChildPage) },
    { exact: true, path: "/children", component: (ChildrenPage) },
    { exact: true, path: "/parents", component: (ParentsList) },
    { exact: true, path: "/parent/:id?", component: (Parent) },
    { exact: true, path: "/specialists", component: (Specialists) },
    { exact: true, path: "/specialist/:id?", component: (Specialist) },
    { exact: true, path: "/locations", component: (Locations) },
    { exact: true, path: "/location/:id?", component: (Location) },
    { exact: true, path: "/", component: (() => Schedule({ type: RESOURCE_TYPE.SPECS })) },
    { exact: true, path: "/schedule/specialists", component: (() => Schedule({ type: RESOURCE_TYPE.SPECS })) },
    { exact: true, path: "/schedule/locations", component: (() => Schedule({ type: RESOURCE_TYPE.LOCATIONS })) },
    { exact: true, path: "/organizations", component: (Organizations) },
    { exact: true, path: "/services", component: (Services)},
    { exact: true, path: "/editLessons", component: (NewEditLessonsPage)},
    { exact: true, path: "/notifications", component: (Notifications)},
    { exact: true, path: "/reports", component: (ReportPage)},
    { exact: true, path: "/notifications/sms", component: (SmsNotificationsPage)},
    { exact: true, path: "/accounts", component: (AccountsPage)},
    { exact: true, path: "/account/:id?", component: (EditAccountPage)},
];

export default function PrivateRoutes(): React.ReactElement {
    const {state = {background: {pathname: "/"}}} = useLocation();
    let {background} = state || {background: {pathname: "/"}};

    return (
        <>
            {
                privateRoutes.map((route, index) => (
                    <PrivateRoute {...route} key={`router_${index}`} />
                ))
            }

            {background && <Route path="/organizations/:id?" children={<OrganizationModal />} />}
            {background && <Route path="/schedule/specialist/:id" children={<SpecModalSchedule />} />}
            {background && <Route path="/schedule/child/:id" children={<ChildModalSchedule />} />}
            {background && <Route path="/schedule/location/:id" children={<LocationModalSchedule />} />}
            {background && <Route path="/globalSearch" children={<SearchPanel />} />}
            {background && <Route path="/schedule/settings" component={ScheduleSettings} />}
            {background && <Route path="/schedule/info/:id" component={ScheduleLessonDrawer} />}
        </>
    )
}

function PrivateRoute(props:any) {
    const location = useLocation();

    if (getAccessToken() != null || getRefreshToken() != null)
        return (<Route {...props} />)
    else
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location }
                }}
            />
        )
}
