import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import { message} from "antd";
import {useHistory} from "react-router-dom";
import DefaultTable from "../utils/DefaultTable";
import Modal from "antd/es/modal";
import DefaultSelector from "../utils/DefaultSelector";
import Lessons from "../lessons/lessons";
import SpecialistHistory from "./historyPanel";
import {GET_WORK_PERIODS, getCabinets, getSpecialist, SAVE_SPECIALIST, SAVE_WORK_PERIOD} from "./graphqlRequests";
import {Services} from "./services";
import moment from "moment";
import DefaultDatePicker from "../utils/DefaultDatePicker";
import Popconfirm from "antd/es/popconfirm";
import {red} from "react-color/lib/helpers/color";

const specInitial = {specialist: {id: null, name: "", lastName: "", surName: "", cabinets: []}};

export default function Specialist() {
    const {id} = useParams();

    return (
        <Row gutter={[16, 16]}>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <SpecialistCard id={Number.parseInt(id)}/>
                </Col>
                <Col span={12}>
                    <Services specId={Number.parseInt(id)}/>
                </Col>
                <Col span={12}>
                    <WorkPeriods specialistId={Number.parseInt(id)}/>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Lessons specId={id}/>
                </Col>
            </Row>
        </Row>
    )
}

function WorkPeriods({specialistId}) {
    const {data = {workPeriodsBySpecialist: []}, loading, refetch} = useQuery(GET_WORK_PERIODS, {
        variables: {specId: specialistId},
        skip: !specialistId
    });
    const [modal, setModal] = useState({visible: false});
    const [saveWorkPeriod] = useMutation(SAVE_WORK_PERIOD);

    const deleteWorkPeriod = (workPeriod) => {
        const savingMsg = message.loading("Сохранение", 0);
        saveWorkPeriod({
            variables: {
                ...workPeriod,
                specId: specialistId,
                isDeleted: true,
                from: workPeriod.from_time,
                to: workPeriod.to_time
            }
        })
            .then(() => {
                message.success("Сохранено!", 4)
            })
            .catch(error => {
                console.error(error);
                message.error("Произошла ошибка! (См F12)", 10);
            }).finally(() => {
            setTimeout(savingMsg, 100);
            refetch()
        })
    };

    const fields = [
        {
            title: "День недели",
            dataIndex: "dayOfWeek_"
        },
        {
            title: "С (время)",
            dataIndex: "from_time"
        },
        {
            title: "До (время)",
            dataIndex: "to_time"
        },
        {
            title: "",
            render: (row) => (
                <Button type="link" onClick={() => deleteWorkPeriod(row)}>
                    Удалить
                </Button>
            )
        }

    ];

    const getDayOfWeekName = (number) => {
        const days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Суб", "Вск"];
        return days[number - 1]
    };

    return (
        <Spin spinning={loading}>
            <DefaultTable columns={fields} tableTitle="Рабочие периоды"
                          dataSources={data.workPeriodsBySpecialist
                              .map(value => ({...value, dayOfWeek_: getDayOfWeekName(value.dayOfWeek)}))
                              .sort((a, b) => a.dayOfWeek - b.dayOfWeek)
                          }
                          footer={() => (<Button type="link" disabled={!specialistId}
                                                 onClick={() => setModal({...modal, visible: true})}>Добавить</Button>)}
            />
            <AddWorkPeriodModal modal={modal} setModal={setModal} specialistId={specialistId} refetch={refetch}/>
        </Spin>
    )
}

function AddWorkPeriodModal({modal, setModal, specialistId, refetch}) {
    const initialState = {specId: specialistId, from: "08:00", to: "22:00", isDeleted: false};
    const [workPeriod, setWorkPeriod] = useState(initialState);
    const [saveWorkPeriod] = useMutation(SAVE_WORK_PERIOD);
    return (
        <Modal visible={modal.visible} title="Добавить рабочее время"
               onCancel={() => {
                   setModal({...modal, visible: false});
                   setWorkPeriod(initialState);
               }}
               okText="Сохранить"
               onOk={() => {
                   const loadingMsg = message.loading("Сохранение", 0);
                   saveWorkPeriod({variables: workPeriod})
                       .then(() => {
                           message.success("Сохранено", 4);
                           refetch();
                           setModal({...modal, visible: false})
                       })
                       .catch(error => {
                           console.error(error);
                           message.error("Возникла ошибка! Подробнее в консоли (F12)")
                       })
                       .finally(() => setTimeout(loadingMsg, 100));
               }}
        >
            <Form.Item>
                <DefaultSelector value={workPeriod.dayOfWeek}
                                 options={["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]
                                     .map((title, index) => ({title, value: index + 1,}))}
                                 dataTitle="title" dataIndex="value"
                                 onChange={(v) => setWorkPeriod({...workPeriod, dayOfWeek: v})}
                                 placeholder="День недели"/>

                <Input type="time" placeholder="Время начала рабочего периода" value={workPeriod.from}
                       onChange={({target: {value}}) => setWorkPeriod({...workPeriod, from: value})}/>

                <Input type="time" placeholder="Время окончания рабочего периода" value={workPeriod.to}
                       onChange={({target: {value}}) => setWorkPeriod({...workPeriod, to: value})}/>
            </Form.Item>
        </Modal>
    )
}

function SpecialistCard({id}) {
    const {data = specInitial, loading} = useQuery(getSpecialist, {
            variables: {id: Number.parseInt(id)},
            skip: !id
        }
    );

    const [saveSpecialistMutation] = useMutation(SAVE_SPECIALIST);
    const [specialist, setSpecialist] = useState({...data.specialist});
    const [historyPanel, setHistoryPanel] = useState({visible: false, entityId: null});
    const history = useHistory();
    useEffect(() => {
        setSpecialist(data.specialist)
    }, [data]);
    const changeField = ({target: {id, value}}) => {
        if (value) {
            value = value[0].toUpperCase() + value.slice(1);
        }

        setSpecialist({...specialist, [id]: value})
    };

    const saveSpecialist = (customProps) => {
        const savingMessage = message.loading("Сохранение", 0);
        saveSpecialistMutation({variables: {...specialist, ...customProps, cabinets: specialist.cabinets.map(cab => cab.id)}})
            .then(({data: {updateSpecialist}}) => {
                setTimeout(savingMessage, 100);
                message.success("Сохранено", 4);
                history.push(`/specialist/${updateSpecialist.id}`)
            })
    };
    const isFormValid = specialist.lastName && specialist.name;
    return (
        <Spin spinning={loading}>
            <Card size="small" title={(<span style={specialist.isDeleted ? {color: "grey"} : null}>Карточка специалиста {specialist.isDeleted ? (<span style={{color: "red"}}>Удален</span>) : null}</span>)}>
                <Col>
                    <Col span={12}>
                        <Form.Item label="Фамилия" required
                                   validateStatus={!specialist.lastName ? "error" : "success"}
                                   help={!specialist.lastName ? "Необходимо заполнить" : null}
                        >
                            <Input placeholder="Фамилия" required value={specialist.lastName} id="lastName"
                                   onChange={changeField}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Имя" required
                                   validateStatus={!specialist.name ? "error" : "success"}
                                   help={!specialist.name ? "Необходимо заполнить" : null}
                        >
                            <Input placeholder="Имя" required value={specialist.name} id="name" onChange={changeField}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Col span={12}>
                            <Form.Item label="Отчество" validateStatus={!specialist.surName ? "warning" : "success"}
                                       help={!specialist.surName ? "Рекомендуется заполнить" : null}
                            >
                                <Input placeholder="Отчество" value={specialist.surName} id="surName"
                                       onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Дата рождения"
                                       validateStatus={!specialist.birthday ? "warning" : "success"}
                                       help={!specialist.birthday ? "Рекомендуется заполнить" : null}
                            >
                                <DefaultDatePicker onChange={(mom) => changeField({target: {id: "birthday", value: mom.format("YYYY-MM-DD")}})} 
                                    value={!specialist.birthday ? null : moment(specialist.birthday)}
                                />
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Предпочитаемые кабинеты">
                            <CabinetSelector {...{specialist, setSpecialist}} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Описание">
                            <Input.TextArea id="description" value={specialist.description} onChange={changeField}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Button type="primary" id={"save_btn"} style={{width: '100%'}} onClick={() => saveSpecialist()} disabled={!isFormValid}>
                            Сохранить
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Popconfirm
                            title="Вы уверены, что хотите удаить эту запись?"
                            onConfirm={() => saveSpecialist({isDeleted: true})}
                            okText="Да"
                            cancelText="Отмена"
                        >
                            <Button type="danger" id={"delete_btn"} style={{width: '100%'}} disabled={!isFormValid || (specialist.isDeleted || false)}>
                                Удалить и отменить все занятия
                            </Button>
                        </Popconfirm>
                    </Col>
                    {
                        !specialist.id ? null : (
                            <Col span={24}>
                                <Button type="info"
                                        style={{width: '100%'}}
                                        onClick={() => setHistoryPanel({
                                            ...historyPanel,
                                            visible: true,
                                            entityId: specialist.id
                                        })}>
                                    Показать историю
                                </Button>
                            </Col>
                        )
                    }
                </Col>
                <SpecialistHistory props={historyPanel} setProps={setHistoryPanel}/>
            </Card>
        </Spin>
    )
}

function CabinetSelector({specialist = {}, setSpecialist}) {
    const {data = {locations: []}, loading} = useQuery(getCabinets);
    const options = data.locations
        .map(location => ({...location, title: `${location.address} ${location.cabinet}`}));

    return (
        <Spin spinning={loading}>
            <DefaultSelector {...{options, value: specialist.cabinets.map(item => item.id)}} mode="multiple"
                             onChange={(locationIds) => {
                                 const cabinets = locationIds.map(id => ({id}));
                                 setSpecialist({...specialist, cabinets})
                             }}
            />
        </Spin>
    )
}