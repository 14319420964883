import {useQuery} from "@apollo/react-hooks";
import {GET_LESSONS_BY_IDS} from "../../graphqlRequests";
import {getDayOfWeekName} from "../../lessons";
import moment from "moment";
import {Col, Row, Spin, Table} from "antd";
import React, { useEffect } from "react";

export default function Step1(props: any) {
    const {lessons = [], storage, targetData = [], updateTargetData} = props
    const {loading, error, data = {lessonsByIds: []}} = useQuery<{ lessonsByIds: Array<any> }>(GET_LESSONS_BY_IDS, {
        skip: !(lessons),
        variables: {
            ids: lessons
        }
    });

    useEffect(() => {
        let res: Array<any> = data.lessonsByIds.map((lesson: any) => ({
            ...lesson,
            client: lesson.child,
        }))

        Object.keys(storage)
            .forEach(key => {
                const value = storage[key].value;
                res.forEach(item => {
                    item[key] = value
                })
            })
        updateTargetData(res)
    }, [data.lessonsByIds])

    console.log('STEP_1', targetData)
    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Table dataSource={targetData} columns={columns} rowKey={({id}:any) => `lesson_${id}`}/>
                </Col>
            </Row>
        </Spin>
    )
}

export const getFioOf = ({lastName, name, surName}: any): string => {
    return `${lastName || ""} ${name || ""} ${surName || ""}`
}

export const columns: any = [
    {
        title: "День",
        dataIndex: "",
        render: (lesson: any) => getDayOfWeekName(lesson.date)
    },
    {
        title: "Дата",
        dataIndex: "date",
        render: (date: any) => {
            return moment(date).format("DD.MM.YY")
        },
    },
    {
        title: "Время",
        dataIndex: "time",
    },
    {
        title: "Клиент",
        dataIndex: "client",
        render: getFioOf
    },
    {
        title: "Специалист",
        dataIndex: "specialist",
        render: getFioOf
    },
    {
        title: "Услуга",
        dataIndex: "service.name",
    },
    {
        title: "Точка\\Кабинет",
        dataIndex: "location",
        render: (location: any) => `${location.address} ${location.cabinet}`
    },
    {
        title: "Статус",
        dataIndex: "status.name",
    },
    {
        title: "Длительность занятия (мин)",
        dataIndex: "duration",
    },
    {
        dataIndex: "cost",
        title: "Стоимость занятия для клиента"
    },
    {
        title: "Приоритет",
        dataIndex: "priority.name",
    },
    {
        title: "Комментарий",
        dataIndex: "description",
    },
    {
        title: "Был ли обзвон",
        dataIndex: "isCalled",
        render: (isCalled: any) => isCalled ? "Да" : "Нет"
    },
];
