import Steps, {StepProps} from "antd/es/steps";
import React from "react";

const {Step} = Steps;

const steps: Array<StepProps> = [
    {title: "Редактирование полей", description: ""},
    {title: "Валидация данных", description: ""},
    {title: "Сохранение", description: ""},
]

export interface EditorStepsProps {
    current: number
}

export default function EditorStepsHeader({current}: EditorStepsProps): React.ReactElement {
    return (
        <Steps current={current}>
            {
                steps.map((step, index) =>
                    (<Step className={`step_${index}`} key={`step_${index}`} {...step}/>))
            }
        </Steps>
    )
}
