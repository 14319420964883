import React, {useState} from "react";
import {Layout, Menu, Icon, Divider} from "antd";
import { useStateWithLocalStorage } from "../../../utils/localStorageState";
import SubMenu from "antd/es/menu/SubMenu";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import { useAuth } from "../../../auth/useAuth";
const { Sider,} = Layout;

function LeftSlider() :React.ReactElement {
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} style={{ background: '#fff' }}>
            <AppMenu />
        </Sider>
    )
}

function AppMenu() {
    const history = useHistory();
    const {logout, userInfo} = useAuth();
    const location = useLocation();
    
    const [selectedMenuItem, setSelectedMenuItem] = useStateWithLocalStorage({key:'selectedMenuItem', initialValue: ["rasp_spec"] });
    const menuItems = [
        { key: "new_lesson", icon: "plus", title: "Новое занятие", url: { pathname: '/lesson/', state: { date: new Date() } } },
        { key: "children", icon: "user", title: "Клиенты", url: "/children" },
        { key: "parents", icon: "user", title: "Предствители", url: "/parents" },
        { key: "specs", icon: "user", title: "Специалисты", url: "/specialists" },
        { key: "cabinets", icon: "bank", title: "Кабинеты", url: "/locations" },
        { key: "services", icon: "bank", title: "Услуги", url: "/services"},
        { key: "notifications", icon: "notification", title: "Уведомления", url: "/notifications"},
    ];

    if ((userInfo?.roles || "").includes("ROLE_SUPER_ADMIN")) {
        menuItems.push({ key: "accounts", icon: "usergroup-add", title: "Аккаунты", url: "/accounts"})
    }

    console.info('userInfo', userInfo)
    return (
        <Menu theme="light" defaultSelectedKeys={['rasp_spec']} mode="inline" selectedKeys={selectedMenuItem}
            onSelect={({ key }) => setSelectedMenuItem([key])} defaultOpenKeys={["rasp"]}
        >
            <div style={{minHeight: 70}}>
                <img src={"/krugozor_log.png"}
                     style={{
                         width: "100%",
                         paddingLeft: "5%",
                         paddingRight: "5%",
                     }}
                />
            </div>
            <div style={{
                width: "100%",
                textAlign: "center"
            }}>
                <span>{userInfo?.lastName} {userInfo?.name}</span>
            </div>
            <Menu.Item key={"globalSearch"} onClick={() => history.push({
                pathname: "/globalSearch",
                state: {
                    background: location
                }
            })}

            >
                <Icon type="search" />
                <span>Глобальный поиск</span>
            </Menu.Item>

            <SubMenu key="rasp" title={<span><Icon type="calendar" /><span>Расписание</span></span>}>
                {
                    [
                        { key: "rasp_spec", icon: "user", title: "По специалистам", url: "/" },
                        { key: "rasp_cab", icon: "home", title: "По кабинетам", url: "/schedule/locations" },
                    ].map(item => getMenuItem(item, history))
                }
            </SubMenu>
            {menuItems.map(item => getMenuItem(item, history))}
            <Divider />
            <Menu.Item key={"logout"} onClick={logout}>
                <Icon type="logout" /><span>Выход</span>
            </Menu.Item>
        </Menu>
    )
}

const getMenuItem = ({ key, icon, title, disabled, url }:any, history: any) => {
    return (
        <Menu.Item key={key} disabled={disabled}
        id={`${key}_menu_btn`}
                   onClick={() => history.push(url || "/404")}
        >
            <Icon type={icon} />
            <span>{title}</span>
        </Menu.Item>
    )
};

export default LeftSlider;