import React from 'react';
import { Upload, Icon, message } from 'antd';
import {
    getAccessToken
  } from "../../../auth/tokenUtils";
const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: false,
    action: '/rest/notifications/upload',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onSuccess(response, file, xhr) {
        message.success(`${file.name} file uploaded successfully.`);
        console.info('response', response);
        console.info('file',file);
        console.info('xhr',xhr);

        const url = window.URL.createObjectURL(new Blob([response.blob()]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
    }
};

function getAuthHeaders() {
    const token = getAccessToken();
  
    return {
      authorization: "Bearer " + token,
    };
  }

export default function SmsNotificationsPage() {
    const headers = getAuthHeaders();
    return (
        <Dragger {...props } headers={headers}>
            <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
            </p>
            <p>Загрузите Excel файл (Только xlsx)</p>
            <p>{getAccessToken() == null ? "Требуется перезайти в АС" : null}</p>
        </Dragger>
    );
}