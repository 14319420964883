import gql from "graphql-tag";

const GET_PARENT = gql`query getparent($id: ID) {
    parent(id: $id) {
        id
        name
        lastName
        surName
        phoneNumber
        description
        birthday
        isDeleted
    }
}`;

const UPDATE_PARENT = gql`mutation($id: ID, $name: String!, $lastName: String!, $surName: String,
    $description: String, $phoneNumber: String,$birthday: String, $isDeleted: Boolean) {
    updateParent (id: $id, name: $name,lastName: $lastName, surName: $surName, description: $description,
        phoneNumber:$phoneNumber, birthday: $birthday, isDeleted: $isDeleted) {
        id
        name
        lastName
        surName
        birthday
        description
        phoneNumber
        isDeleted
        children {
            id
        }
    }
}`;

const GET_PARENTS_FOR_CHILD = gql`query getParentsForChild($childId: ID) {
    parents(childId: $childId) {
        id
        lastName
        name
        surName
        phoneNumber
    }
}`;


const SAVE_PARENTS_FOR_CHILD = gql`mutation setParentsForChild($childId: ID!, $parentsIds: [ID!]) {
    setParentsForChild(childId: $childId, parentsIds: $parentsIds) {
        id
        parents {
            id
        }
    }
}`;

export const GET_USER_BY_PHONE_NUMBER = gql`query findByPhoneNumber($phoneNumber: String) {
    findByPhoneNumber(phoneNumber: $phoneNumber) {
        id
        deviceInfo { id notificationsStatus }
    }
}`;

export const RESET_PASSWORD_MUTATION = gql`mutation resetPassword($phoneNumber: String) {
    resetPassword(phoneNumber: $phoneNumber)
}`;

export {
    GET_PARENT,
    UPDATE_PARENT,
    GET_PARENTS_FOR_CHILD,
    SAVE_PARENTS_FOR_CHILD
}