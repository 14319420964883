import React, { useEffect } from "react";
import Drawer from 'antd/es/drawer';
import Timeline from "antd/es/timeline";
import {useAxios} from '../../hooks/useAxios';
import { Spin, Result, Icon } from "antd/es";
import moment from "moment";
import Descriptions from "antd/es/descriptions";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Button from "antd/es/button";
import { message } from "antd";

export const HISTORY_ENTITY = {
    CHILD: "child", LESSON: "lesson", SPEC: "specialist", SERVICE: "service"
}

const NO_DATA = "Нет данных";

export default function HistoryPanel({ props: { visible, entityId, entity, historyFields }, setProps }) {
    const [{ data = [], loading, error = null }, refetch] = useAxios({
        url: entityId ? `/rest/audit/${entity}/${entityId}` : null
    })

    const updateHistory = () => {
        if (!entityId) return;
        const msg = message.loading("Обновление", 0);
        refetch().finally(() => setTimeout(msg, 10));
    }

    useEffect(updateHistory, [entityId]);

    if (!entityId) return null;

    if (error) {
        console.error(error);
    }
    return (
        <Spin spinning={loading}>
            <Drawer
                title={
                    <Row>
                        <Col span={20}>История изменений</Col>
                        <Col span={2}>
                            <Button size="small" type="link" onClick={updateHistory}>Обновить</Button>
                        </Col>
                    </Row>}
                placement="right"
                closable={true}
                onClose={() => setProps({ visible: false })}
                visible={visible}
                width={1024}
            >
                {
                    error ? (
                        <Result
                            status="500"
                            title="При загрузке истории возникла ошибка. Сообщите Кириллу!"
                        />
                    ) : (
                            <Timeline>
                                {
                                    data.length === 0 ? (
                                        <Result
                                            icon={<Icon type="meh" theme="twoTone" />}
                                            title="Истории нет" />
                                    ) : (
                                            data.map(item => (
                                                <Timeline.Item key={item.version.id}
                                                    color={item.version.action === "ADD" ? "green" : item.isDeleted ? "red" : "blue"}
                                                >
                                                    {getLessonDescriptionFields(historyFields, item)}
                                                </Timeline.Item>
                                            )
                                            )
                                        )
                                }
                            </Timeline>
                        )
                }
            </Drawer>
        </Spin>
    )
}

const getPropValue = (object, path = '') =>
    path.split('.')
        .reduce((o, x) => o === undefined ? o : o[x]
            , object);


const MODIFIED_FIELD_COLOR = "#f5222d"
const getPropColor = (fieldKey, { version: { action = "ADD", modifiedFields = [] } }) => {
    if (action === "ADD") return MODIFIED_FIELD_COLOR;
    return modifiedFields.find(field => fieldKey.includes(field)) ? MODIFIED_FIELD_COLOR : null
}

const getLessonDescriptionFields = (historyFields, item) => {
    let title = item.version.action === "ADD" ? "Создан" : item.isDeleted ? "Удален" : "Изменен";
    title += ` ${moment(item.version.timestamp).format("DD.MM.YYYY HH:mm")}`;
    if (item.version.modifiedBy !== null && item.version.modifiedBy !== undefined) {
        title += ` (Автор: ${item.version.modifiedBy})`
    }
    return (
        <Descriptions title={title}>
            {
                historyFields.map(({ key, label, render }) => (
                    <Descriptions.Item label={label}>
                        <span style={{ color: getPropColor(key, item) }}>
                            {render ? render(item) || NO_DATA : getPropValue(item, key) || NO_DATA}
                        </span>
                    </Descriptions.Item>
                ))
            }
        </Descriptions>
    )
}