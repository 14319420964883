import React from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Button from "antd/es/button";
import {notification, Popconfirm, Popover} from "antd";
import {GET_USER_BY_PHONE_NUMBER, RESET_PASSWORD_MUTATION} from "./graphqlRequests";

export const ResetPasswordButton = ({phoneNumber}) => {
    const [reset] = useMutation(RESET_PASSWORD_MUTATION)
    const {data:existUserData = {findByPhoneNumber: null}} = useQuery(GET_USER_BY_PHONE_NUMBER, {
        variables: { phoneNumber }, skip: phoneNumber == null || phoneNumber === ""
    })

    const onReset = () => {
        reset({ variables: { phoneNumber }})
            .then(({data: {resetPassword}}) => notify(resetPassword))
            .catch(notifyAboutError)
    }

    const isUserExist = existUserData.findByPhoneNumber !== null
    const resetButton = <Button type={"link"} disabled={!isUserExist}>Сбросить пароль</Button>

    return isUserExist
        ? (<UserExistButton {...{onConfirm: onReset, disabled: !isUserExist, resetButton}} />)
        : (<UserNotExistButton {...{resetButton}} />)

}

const UserExistButton = (props) => (
    <Popconfirm title={"Вы уверены?"} okText={"Да"} cancelText={"Отмена"} {...props}>
        {props.resetButton}
    </Popconfirm>
)

const UserNotExistButton = (props) => (
    <Popover title={"Пользователь не найден"}
             content={(<span>Клиент должен сперва зарегистрироваться в мобильном приложении</span>)}>
        {props.resetButton}
    </Popover>
)

const notify = (newPassword) => notification.success({
    message: "Пароль сброшен",
    description: `Новый пароль: ${newPassword}`,
    duration: 60
})

const notifyAboutError = () => notification.error({
    message: "Возникла ошибка",
    description: "Во время сброса пароля возникла ошибка. Свяжитесь с администратором",
    duration: 60
})