import gql from "graphql-tag";

const SAVE_ORGANIZATION = gql`mutation save_org($id: ID, $name: String!, $businessId: String = null, $isDeleted: Boolean = false, $specs: [ID] = []) {
    saveOrganization(id: $id, name: $name, businessId: $businessId, isDeleted: $isDeleted, specs: $specs) {
        id
        name
        businessId
        employees {
            id
        }
        isDeleted
    }
}`;

const SELECT_ORGANIZATION = gql`query dads($id: ID!) {
    organization(id: $id) {
        id
        name
        businessId
        isDeleted
        employees {
            id
        }
    }
}`;

export {SAVE_ORGANIZATION, SELECT_ORGANIZATION};