import React, {useEffect, useState} from "react";
import Modal from "antd/es/modal";
import ScheduleCalendar, {RESOURCE_TYPE} from "./schedule_calendar";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Button from "antd/es/button";
import {axiosInstance as axios} from "../../utils/axios";
import {message} from "antd";
import {useStateWithLocalStorage} from "../../utils/localStorageState";
import DefaultDatePicker, {DEFAULT_DATE_FORMATS} from "../utils/DefaultDatePicker";
import {useLocation} from "react-router-dom";
import {sendScheduleTiming} from "../../analytics";

export default function ModalSchedule({scheduleModal = {visible: false, resources: {type: RESOURCE_TYPE.SPECS, ids: []}}}) {
    const location = useLocation();
    const [dateInLocalStore, setDateInLocalStore] = useStateWithLocalStorage({key: 'selectedDateInModalSchedule', initialValue: ""});
    const [schedule, setSchedule] = useState({
        ...scheduleModal,
        date: dateInLocalStore || new Date()
    });

    const [range, setRange] = useState([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
    useEffect(() => {
        setSchedule({
            date: schedule.date,
            ...scheduleModal
        });
    }, [scheduleModal]);

    if (!scheduleModal.visible) return null;

    const changeDate = (moment) => {
        const date = moment ? moment.toDate() : new Date();
        setSchedule({...schedule, date});
        setDateInLocalStore(date)
    };

    const downloadSchedule = () => {
        const startMoment = Date.now()
        const data = {
            type: schedule.resources.type === RESOURCE_TYPE.CHILDREN ? "child" : "specialist"
        };
        const loadingMsg = message.loading("Загрузка", 0);
        axios({
            url: `/rest/print/${data.type}/${schedule.resources.ids[0]}`,
            method: 'POST',
            data: {
                "startDate": range[0],
                "endDate": range[1]
            },
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            sendScheduleTiming('Выгрузка расписания в excel', startMoment)
            setTimeout(loadingMsg, 500)
        });
    };

    return (
        <Modal visible={true}
               style={{top: 20}}
               width={1200}
               onCancel={() => {
                   setDateInLocalStore(null);
                   scheduleModal.onCancel();
               }}
               footer={null}
        >
            <Row gutter={[8, 8]}>
                <Row gutter={[8, 8]}>
                    <Col span={10}>
                        <DefaultDatePicker onChange={changeDate} value={moment(schedule.date)}/>
                    </Col>
                    <Col span={5}>
                        <DatePicker.RangePicker defaultValue={[moment(), moment()]}
                                                onChange={dates => setRange(dates.map(date => date.format("YYYY-MM-DD")))}
                                                format={DEFAULT_DATE_FORMATS}/>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" onClick={downloadSchedule}>Скачать для печати</Button>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col>
                        <ScheduleCalendar schedule={schedule} setSchedule={setSchedule}
                                          customProps={{
                                              selectable: location.state.schedule ? location.state.schedule.selectable : true,
                                          }}
                        />
                    </Col>
                </Row>
            </Row>
        </Modal>
    )
}