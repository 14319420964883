import React from "react";
import DefaultTable from "../../utils/DefaultTable";
import Button from "antd/es/button";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Spin from "antd/es/spin";
import {useHistory, useLocation} from "react-router-dom";

const selectAll = gql`query {
    organizations {
        id
        name
        businessId
    }
}`

export default function Organizations() {
    const location = useLocation();
    const history = useHistory();
    const {data = {}, loading} = useQuery(selectAll, {pollInterval: 4000});

    const cols = [
        {title: "Название организации", dataIndex: "name"},
        {title: "Идентификатор в 1С", dataIndex: "businessId"},
        {
            title: "", dataIndex: "", render: ({id}) => (<>
                <Button type="link" onClick={() => history.push({
                    pathname: '/organizations/'+id,
                    state: {
                        background: location
                    }
                })}>Редактировать</Button>
            </>)
        },
    ];

    return (
        <Spin spinning={loading}>
            <DefaultTable columns={cols} dataSources={data.organizations || []}
                          footer={() => (<Button type="link" onClick={() => history.push({
                              pathname: '/organizations/',
                              state: {
                                  background: location
                              }
                          })}>Добавить</Button>)}
            />
        </Spin>
    )
}