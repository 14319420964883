import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ModalSchedule from "./modal_schedule";
import {RESOURCE_TYPE} from "./schedule_calendar";

export default function LocationModalSchedule() {
    const {id: locationId} = useParams();
    const history = useHistory();

    const [scheduleModal, setScheduleModal] = useState({
        visible: true,
        onCancel: () => history.goBack(),
        resources: {type: RESOURCE_TYPE.LOCATIONS, ids: [locationId]}
    });

    return (
        <ModalSchedule {...{scheduleModal, setScheduleModal}}/>
    )
}