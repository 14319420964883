import React from "react";
import { Typography } from "antd"

const {Text} = Typography;

interface MarkedTestProps {
    text: string
    mappedBy?: string
}

export const MarkedText = ({text = "", mappedBy = ""}: MarkedTestProps) => {
    const index = text.toUpperCase().search(mappedBy.toUpperCase());
    let result = <span>{text}</span>
    if (index !== -1) {
        const pre = text.substring(0, index);
        const main = text.substr(index, mappedBy.length);
        const post = text.substring(index + mappedBy.length, text.length);
        
        result =  <Text>{pre}<Text mark>{main}</Text>{post}</Text>
    }
    return result
}
