import React from "react"
import { Descriptions, Button } from "antd";
import { MarkedText } from "../markedText";
import { ParentdDescriptionProps } from "../models";
import { useHistory } from "react-router-dom";

export const ParentdDescription: React.FC<ParentdDescriptionProps> = ({ parent, mappedBy = "" }: ParentdDescriptionProps) => {
    const history = useHistory();
    const markText = (text: string) => <MarkedText text={text} mappedBy={mappedBy}/>
    
    const children = parent.children.map(child => (
        <Descriptions.Item span={2} label="Контактное лицо для">{child.lastName} {child.name} {child.birthday}</Descriptions.Item>
    ))
    const title = `Представитель ${parent.lastName} ${parent.name} ${parent.surName || ""}`

    const goToParent = () => history.push(`/parent/${parent.id}`);

    return (
        <Descriptions title={<>{markText(title)} <Button type={"link"} onClick={goToParent} >Перейти</Button></>}>
            <Descriptions.Item span={2} label="Телефон">{markText(parent.phoneNumber || "Нет даных")}</Descriptions.Item>
            <Descriptions.Item label="Дата рождения">{markText(parent.birthday || "Нет даных")}</Descriptions.Item>
            {children}
        </Descriptions>
    )
}