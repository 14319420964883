import DefaultTable from "../utils/DefaultTable";
import React, { useState } from "react";
import { useQuery} from "@apollo/react-hooks";
import { Button, Spin, Tag } from "antd";
import { Service } from "./service"
import { GET_SERVICES } from './graphql';

export const Services = () => {
    const {data = {services: []}, loading, refetch} = useQuery(GET_SERVICES, {
        pollInterval: 10000
    });

    return (
        <Spin spinning={loading}>
            <ServicesList {...{refetch, dataSource: data.services}} />
        </Spin>
    )
};

export const ServicesList = ({ dataSource, footer = undefined, customColumns = undefined, refetch}) => {
    const SERVICE_INITIAL_STATE = {
        visible: false,
        id: undefined,
        onClose: () => {setSelectedService({ visible: false }); refetch()}
    }
    const [selectedService, setSelectedService] = useState(SERVICE_INITIAL_STATE)

    const openService = (service) => {
        setSelectedService({
            ...SERVICE_INITIAL_STATE,
            id: service.id,
            visible: true,
        })
    }

    const defaultColumns = [
        {
            title: "Наименование",
            dataIndex: "name"
        },
        {
            title: "Продолжительность (мин)",
            dataIndex: "duration"
        },
        {
            title: "Стоимость",
            dataIndex: "costForClient"
        },
        {
            title: "Тип",
            dataIndex: "type"
        },
        {
            title: "Специалисты",
            render: (record) => (<SpecialistsList specialists={record.specialists} />)
        },
        {
            title: "",
            dataIndex: "",
            render: (record) => (<Button type={"link"} onClick={() => openService(record)}>Редактировать</Button>)
        }
    ]

    const columns = customColumns || defaultColumns
    const defaultFooter = () => (
        <Button type="link"
                onClick={() => setSelectedService({ ...SERVICE_INITIAL_STATE, visible: true })}
        >Добавить</Button>
    )

    return (
        <>
            <DefaultTable dataSources={dataSource} columns={columns}
                footer={footer || defaultFooter} tableTitle={"Услуги"}
            />
            {!selectedService.visible ? null : <Service {...selectedService} />}
        </>
    )
}

const SpecialistsList = ({specialists}) => {
    const result = specialists.map(spec => <Tag>{spec.lastName} {spec.name}</Tag>).slice(0,2)
    if (specialists.length > result.length) {
        result.push(<Tag>... + {specialists.length - result.length}</Tag>)
    }
    return result
}