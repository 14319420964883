import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_SERVICES} from "../services/graphql";
import {getSpecialist} from "./graphqlRequests";
import {ADD_SERVICES_FOR_SPEC} from "../lessons/graphqlRequests";
import React, {useState} from "react";
import {message} from "antd";
import Spin from "antd/es/spin";
import Modal from "antd/es/modal";
import DefaultSelector from "../utils/DefaultSelector";

export const AddServiceModal = ({specId, props = {specId: undefined, visible: false}, changeProps}) => {
    const {data = {services: []}, loading} = useQuery(GET_SERVICES, {skip: !specId})
    const {data: specData = {specialist: {services: []}}, loading: loadingSpec} = useQuery(getSpecialist, {
            variables: {id: Number.parseInt(specId)},
            skip: !specId
        }
    );
    const [saveNewServicesMutation, {loading: saveLoading}] = useMutation(ADD_SERVICES_FOR_SPEC)
    const [newServices, setNewServices] = useState([]);
    const options = data.services
        .filter(service => !specData.specialist.services.flatMap(_ => _.id).includes(service.id))
        .map(service => ({
            id: Number.parseInt(service.id),
            title: `${service.name} | ${service.duration} мин. | ${service.costForClient} руб.`
        }))

    const saveNewServices = () => {
        const messageSaving = message.loading("Сохранение")
        saveNewServicesMutation({variables: {
                servicesId: newServices, specId: specId
            }})
            .then(() => setTimeout(messageSaving, 100))
            .then(props.refetch)
            .then(() => changeProps({visible: false}))
            .then(() => message.success("Сохранено"))
    }

    return (
        <Spin spinning={loading || loadingSpec || saveLoading}>
            <Modal visible={props.visible} onCancel={() => changeProps({visible: false})}
                   onOk={saveNewServices} okText={"Сохранить"} width={800} okButtonProps={{id: "saveServicesBtn"}}
                   title={`${specData.specialist.lastName} ${specData.specialist.name} ${specData.specialist.surName} | добавление услуг `}
            >
                <DefaultSelector mode="multiple" options={options} placeholder={"Выберите услуги"} id={"service"}
                                 value={newServices} onChange={(_) => setNewServices(_)}
                />
            </Modal>
        </Spin>
    )
}