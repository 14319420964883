import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import DefaultTable from "../utils/DefaultTable";
import gql from "graphql-tag";


const GET_PARENTS_FOR_CHILD = gql`query getParentsForChild($childId: ID) {
    parents(childId: $childId) {
        id
        lastName
        name
        surName
        phoneNumber
    }
}`;

export default function ParentsList({history, location, match}) {
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const query = useQuery(GET_PARENTS_FOR_CHILD);

    const {loading, data = {parents: []}} = query;
    const parentCols = [
        {
            title: "ФИО",
            dataIndex: "fio"
        },
        {
            title: "Телефон",
            dataIndex: "phoneNumber"
        },
        {
            title: "",
            render: (row) => [
                <Button type="link" onClick={() => history.push(`/parent/${row.id}`)}>Ред.</Button>,
            ]
        },
    ];

    const dataSource = data.parents.map(parent => ({...parent, fio: `${parent.lastName || "" } ${parent.name || ""} ${parent.surName || "" }`}));

    return (
        <Spin spinning={loading}>
            <DefaultTable setSearchFilters={setSearchFilters} searchFilters={searchFilters}
                          columns={parentCols} dataSources={dataSource} tableKey="parent" tableTitle="Представители"
                          selectable={false}
                          footer={() => {
                             return (<Button type="link" onClick={() => history.push("/parent/")}>Добавить</Button>)
                          }}
            />
        </Spin>
    )
}


