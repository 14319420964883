import {Modal} from "antd/es";
import {Table} from "antd";
import {columns as lessonInfoColumns} from "./steps/step1";
import React from "react";

export const ModalWithErrors = (props: any):JSX.Element => {
    const columns = [
        ...lessonInfoColumns,
        {
            title: "Ошибка",
            render: ({errors}:any) => !errors ? null: `${errors.reduce((prev:string, curr:string) => prev + "\n" + curr, "")}`,
            width: 300,
            fixed: "right"
        }
    ]
    return (
        <Modal width={1400} title={"При сохранении возникли ошибки"}
               okText={"Сохранить в любом случае"} cancelText={"Отмена"}
               {...props}
               onOk={() => props.onOk(props.allLessons)}
        >
            <Table dataSource={props.dataSource} columns={columns} scroll={{x: 1800}} rowKey={({id}: any) => `lesson_${id}`}/>
        </Modal>
    )
}
