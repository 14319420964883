import gql from "graphql-tag";

const SAVE_SERVICE_MUTATION = gql`mutation saveService($id: ID,
    $name: String!,
    $costForClient: Int!,
    $specialistCost: Int,
    $specialists: [ID]!,
    $duration: Int!,
    $type: String,
    $isDeleted: Boolean,
    $businessId: String
) {
    saveService(id: $id, name:$name, costForClient: $costForClient,
        specialistCost: $specialistCost,
        specialists: $specialists, duration: $duration, type: $type, isDeleted: $isDeleted, businessId: $businessId)
        {
            id
        }
    }`;


const GET_SERVICE = gql`query findService($id: ID!){
    findService (id: $id) {
        id
        name
        isDeleted
        costForClient
        specialistCost
        duration
        businessId
        type
        specialists {
            id
            lastName
            name
            surName
        }
    }
}`;

const GET_SERVICES = gql`query services {
    services {
        id
        name
        costForClient
        specialistCost
        duration
        type
        specialists {
            id
            lastName
            name
            surName
        }
    }
}`;

export { SAVE_SERVICE_MUTATION, GET_SERVICE, GET_SERVICES };