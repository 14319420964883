import React, { useState, useEffect } from 'react';
import { Form, Button, Steps, Input, message, Row, Col, Descriptions } from 'antd';
import FormBuilder from 'antd-form-builder';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {useAxios} from '../../hooks/useAxios';
import moment from 'moment';

const { Step } = Steps;
const DateView = ({ value }) => (value ? " " + value.format('Do MMM YYYY') : ' Нет данных');

const PhoneInput = ({ value, onChange }) => {
    return (
        <Input
            style={{ width: '100%' }}
            value={value}
            placeholder={"Контактный телефон"}
            onChange={e => {
                const { value } = e.target;
                const parsePhoneNumber = (value = "+ 7") => value ? parsePhoneNumberFromString(value, 'RU') : value;
                const phoneNumber = parsePhoneNumber(value);
                onChange({ ...e, target: { ...e.target, value: phoneNumber ? phoneNumber.formatInternational() : value } })
            }}
        />
    )
};

const FioInput = ({ value, onChange }) => {
    return (
        <Input
            style={{ width: '100%' }}
            value={value}
            onChange={e => {
                const value = toUpper(e.target.value);

                onChange({ ...e, target: { ...e.target, value } })
            }}
        />
    )
};

FormBuilder.defineWidget('date-view', DateView);
FormBuilder.defineWidget('phoneInput', PhoneInput);
FormBuilder.defineWidget('fioInput', FioInput);

const wizardMeta = {
    steps: [
        {
            title: 'Информация о клиенте',
            formMeta: {
                columns: 2,
                fields: [
                    { key: 'child.lastName', label: 'Фамилия', widget: 'fioInput', rules: [{ required: true, message: 'Заполните поле' }] },
                    { key: 'child.name', label: 'Имя', widget: 'fioInput', rules: [{ required: true, message: 'Заполните поле' }] },
                    { key: 'child.surName', label: 'Отчество', widget: 'fioInput', required: false },
                    {
                        key: 'child.birthday', label: 'Дата рождения',
                        widget: 'date-picker', viewWidget: 'date-view',
                        widgetProps: {
                            style: { width: "100%" }, format: ["DD.MM.YYYY", "DDMMYYYY", "DDMMYY"],

                        }
                    }
                ],
            },
        },
        {
            title: 'Представитель',
            formMeta: {
                columns: 2,
                fields: [
                    { key: 'parent.lastName', label: 'Фамилия', widget: 'fioInput', rules: [{ required: true, message: 'Заполните поле' }] },
                    { key: 'parent.name', label: 'Имя', widget: 'fioInput', rules: [{ required: true, message: 'Заполните поле' }] },
                    { key: 'parent.surName', label: 'Отчество', widget: 'fioInput', required: false },
                    {
                        key: 'parent.phoneNumber', label: 'Конт. телефон', forwardRef: true, widget: 'phoneInput',
                        rules: [{ required: true, message: 'Заполните поле' }]
                    },
                    {
                        key: 'parent.birthday', label: 'Дата рождения',
                        widget: 'date-picker', viewWidget: 'date-view',
                        widgetProps: { style: { width: "100%" }, format: ["DD.MM.YYYY", "DDMMYYYY", "DDMMYY"] }
                    }
                ],
            },
        },
    ],
};

const toUpper = (value) => value ? value[0].toUpperCase() + value.slice(1) : value;

const ChildForm = Form.create()(({ form, props, setProps, setSaving, onCancel, setChild }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedChild, updateSelectedChild] = useState({ ...form.getFieldsValue().child })
    const [selectedParent, updateSelectedParent] = useState({ ...form.getFieldsValue().parent })
    const [formStatus, setFormStatus] = useState({locked: false})

    const [ , saveChildAndParent] = useAxios(
        {
            url: '/rest/individual/save/client',
            method: 'POST',
        },
        { manual: true }
    );

    const handleSubmit = () => {
        saveChildAndParent({
            data: {
                client: {
                    ...selectedChild,
                    birthday: selectedChild.birthday ? selectedChild.birthday.format("YYYY-MM-DD") : null
                },
                representative: selectedParent
            }
        }).then(({data}) => {
            message.success("Сохранено");
            setChild(`${data.id}`);
            setProps({visible:false})
            form.resetFields();
            setCurrentStep(0);
        })
    };

    const newWizardMeta = JSON.parse(JSON.stringify(wizardMeta));
    newWizardMeta.steps.forEach(s => s.formMeta.fields.forEach(f => (f.preserve = true)));

    const reviewFields = [];

    newWizardMeta.steps.push({
        key: 'review',
        title: 'Подтверждение',
        formMeta: {
            columns: 2,
            fields: reviewFields,
        },
    });

    const stepsLength = newWizardMeta.steps.length;

    const handleNext = () => {
        form.validateFields(err => {
            if (err) return;
            updateSelectedChild(form.getFieldsValue().child)
            updateSelectedParent(form.getFieldsValue().parent)
            setCurrentStep(currentStep + 1)
        })
    };

    const handleBack = () => setCurrentStep(currentStep - 1);

    const isReview = currentStep === stepsLength - 1;

    return (
        <Form layout="horizontal" style={{ width: '100%' }}>
            <Steps current={currentStep}>
                {newWizardMeta.steps.map(s => (
                    <Step key={s.title} title={s.title} />
                ))}
            </Steps>
            <>
                {
                    isReview ? (<ReviewForm {...{ child: selectedChild, parent: selectedParent, updateSelectedChild, updateSelectedParent, setFormStatus }} />) : (
                        <div style={{ background: '#f7f7f7', padding: '20px', margin: '30px 0' }}>
                            <FormBuilder
                                viewMode={isReview}
                                form={form}
                                meta={newWizardMeta.steps[currentStep].formMeta}
                            />
                        </div>
                    )
                }
            </>
            <Form.Item className="form-footer" style={{ textAlign: 'right' }}>
                {currentStep > 0 && (
                    <Button onClick={handleBack} style={{ float: 'left', marginTop: '5px' }}>
                        Назад
                    </Button>
                )}
                <Button onClick={() => {
                    form.resetFields();
                    updateSelectedParent({});
                    updateSelectedChild({})
                    onCancel();
                }}>Отмена</Button>&nbsp; &nbsp;
                <Button type="primary" disabled={formStatus.locked} onClick={isReview ? handleSubmit : handleNext}>
                    {isReview ? 'Сохранить' : 'Далее'}
                </Button>
            </Form.Item>
        </Form>
    )
});

const useClient = (child) => {
    const [{ data = [], loading = false, error }] = useAxios(
        {
            url: '/rest/individual/find/client',
            method: 'POST',
            data: {
                ...child
            }
        },
        { manual: !!child.id }
    );

    return {data, loading, error};
}


const useParent = (parent) => {
    const [{ data = [], loading = false, error }] = useAxios(
        {
            url: '/rest/individual/find/representative',
            method: 'POST',
            data: {
                ...parent
            }
        },
        { manual: !!parent.id }
    );

    return {data, loading, error};
}

const ReviewForm = ({ child, parent, updateSelectedChild, updateSelectedParent, setFormStatus }) => {
    console.debug('ReviewForm', child, parent);

    const childData = useClient(child);
    const parentData = useParent(parent)
    
    useEffect(()=> {
        if (!((child.id || !childData.data.length) || (parent.id || !parentData.data.length))) {
            setFormStatus({locked: true})
        }
    }, [childData.data, parentData.data])

    const selectExistClient = (client, target = "child") => {
        if (target === "child") {
            updateSelectedChild(client);
        } else {
            updateSelectedParent(client);
        }

        if ((child.id || !childData.data.length) && (parent.id || !parentData.data.length)) {
            setFormStatus({locked: false});
        }
    }

    const childDescs = []
    const parentdDescs = []
    
    if (child.id || !childData.data.length) {
        childDescs.push(<ClientDescription {...{ client: child, meta: { type: "child", label: "Клиент" } }} />)
    } else {
        childData.data
            .map(client => (<ClientDescription {...{ client, meta: { type: "child", select: () => selectExistClient(client), label: "Клиент" } }} />))
            .forEach(item => childDescs.push(item))
    }

    if (parent.id || !parentData.data.length) {
        parentdDescs.push(<ClientDescription {...{ client: parent, meta: { type: "parent", label: "Представитель" } }} />)
    } else {
        parentData.data
            .map(client => ({...client, birthday: client.birthday ? moment(client.birthday, "YYYY-MM-DD") : null}))
            .map(client => (<ClientDescription {...{ client, meta: { type: "parent", select: () => selectExistClient(client, "parent"), label: "Представитель" } }} />))
            .forEach(item => parentdDescs.push(item))
    }



    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                {
                    childDescs.map(item => (<Col span={12}>{item}</Col>))
                }
                {
                    parentdDescs.map(item => (<Col span={12}>{item}</Col>))
                }
            </Col>
        </Row>
    )
}

const ClientDescription = ({ client, meta }) => {
    console.debug('ClientDescription', client, meta);

    const title = (<>
        <span>{meta.label}</span>
        {
            meta.select ? (
                <Button type="link" onClick={meta.select} style={{ float: 'right', zIndex: 1 }}>
                    Выбрать
                </Button>
            ) : null
        }
    </>)

    return (
        <div style={{ background: '#f7f7f7', padding: '20px', margin: '30px 0' }}>
            <Descriptions title={title} column={2} size={"small"}>
                <Descriptions.Item label="ФИО">{`${client.lastName} ${client.name} ${client.surName || ""}`}</Descriptions.Item>
                <Descriptions.Item label="Дата рождения">{client.birthday ? moment(client.birthday).format("DD.MM.YYYY") : "Нет информации"}</Descriptions.Item>
                <Descriptions.Item label="Номер телефона">{client.phoneNumber || "Нет информации"}</Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default ChildForm;