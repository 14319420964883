import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_USERS, NOTIFICATION_MUTATION} from "./queries.graphql";
import React, {useState} from "react";
import {Col, Input, message, Modal, Radio, Row} from "antd";
import Spin from "antd/es/spin";
import DefaultSelector from "../utils/DefaultSelector";

const NOTIFICATION_INITIAL_STATE = {
    method: "SMS", recipientId: null
}

export default function NotificationFactoryModal ({props, setProps, refetch}) {
    console.info("notificationFactory", props)
    const {loading, data = {users: []}} = useQuery(GET_USERS);
    const [sendingNotification, setSendingStatus] = useState(false);
    const [notificationProps, setNotificationProps] = useState(NOTIFICATION_INITIAL_STATE)
    const [sendNotification] = useMutation(NOTIFICATION_MUTATION);

    const onOk = () => {
        setSendingStatus(true);
        sendNotification({
            variables: {
                'props': {
                    ...notificationProps
                }
            }
        }).then((result) => {
            setProps({visible: false});
            setNotificationProps(NOTIFICATION_INITIAL_STATE);
            setSendingStatus(false);
            message.success("Уведомление отправлено", 5);
            refetch();
        })
    }

    return <Modal visible={props.visible}
                  onCancel={() => { setProps({visible: false})}}
                  title={"Отправить уведомление"}
                  okButtonProps={{
                      disabled: sendingNotification || loading,
                      onClick: onOk,
                      title:"Отправить"
                  }}
    >
        <Spin spinning={loading || sendingNotification}>
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <Radio.Group value={notificationProps.method}
                                 onChange={({target: {value}}) => setNotificationProps({...notificationProps,method: value })} defaultValue="a"
                    >
                        <Radio.Button value="SMS">СМС</Radio.Button>
                        <Radio.Button value="MOBILE_APP_PUSH">Push уведомление</Radio.Button>
                        <Radio.Button value="EMAIL" disabled>Email</Radio.Button>
                        <Radio.Button value="WA" disabled>Написать WA</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col span={24}>
                    <DefaultSelector placeholder={"Клиент"} value={notificationProps.recipientId}
                                     onChange={value => setNotificationProps({...notificationProps, recipientId: value})}
                                     dataTitle="fio" dataIndex="id"
                                     options={data.users.map(user => ({
                                         ...user,
                                         fio: `${user.lastName} ${user.name} ${user.surName || ""} ${user.phoneNumber}`
                                     }))}
                    />
                </Col>
                <Col span={24}>
                    <Input.TextArea
                        rows={4} value={notificationProps.message}
                        onChange={({target: {value}}) => setNotificationProps({...notificationProps, message: value})}
                        maxLength={255}
                    />
                </Col>
            </Row>
        </Spin>
    </Modal>
}