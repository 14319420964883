import React from "react";
import HistoryPanel, {HISTORY_ENTITY} from "../history";
import moment from "moment";

const LessonHistory = ({props, setProps}) => <HistoryPanel props={{...props, entity: HISTORY_ENTITY.LESSON, historyFields}} setProps={setProps}/>

const historyFields = [
    {key: "datetime", label: "Дата занятия", render: ({date, time}) => moment(`${date} ${time}`).format("DD.MM.YYYY HH:mm")},
    {key: "isCalled", label: "Звонили", render: ({isCalled}) => isCalled ? "Да" : "Нет"},
    {key: "status", label: "Статус"},
    {key: "priority", label: "Приоритет"},
    {key: "child.name", label: "Клиент"},
    {key: "specialist.name", label: "Специалист"},
    {key: "service.name", label: "Услуга"},
    {key: "cost", label: "Стоимость"},
    {key: "duration", label: "Продолжительность (мин)"},
    {key: "location.name", label: "Кабинет"},
    {key: "isDeleted", label: "Удален", render: ({isDeleted}) => isDeleted ? "Да" : "Нет"},
    {key: "description", label: "Описание"},
]

export default LessonHistory;