import React, {useContext, useEffect, useState } from 'react';
import { axiosInstance as axios } from '../utils/axios';
import { getNewToken } from '../utils/connections';
import {
  deleteAccessToken,
  deleteRefreshToken,
  getAccessToken,
  setAccessToken,
  setExpiresIn,
  setRefreshToken,
  getRefreshToken,
  setUserInfo, getUserInfo
} from './tokenUtils';
var jwtDecode = require('jwt-decode');

type AuthContextData = ReturnType<typeof useProvideAuth>;

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }:any) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useProvideAuth = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(true);
  const isAuthenticated = token !== null || getRefreshToken() !== null;
  const [userInfo, saveUserInfo] = useState({name: "", lastName:"", roles: ""});
  const login = async (username: string, password: string) => {
    const response = await axios.post<SignInResponse>('/rest/auth', {
      login: username,
      password,
    });
    const { accessToken, refreshToken } = response.data;
    const decodedAccessToken = jwtDecode(accessToken);

    saveUserInfo(decodedAccessToken.userInfo);
    await Promise.all([
      setAccessToken(accessToken), 
      setRefreshToken(refreshToken), 
      setExpiresIn(decodedAccessToken.exp),
      setUserInfo(decodedAccessToken.userInfo)
    ]);
  };

  const logout = async () => {
    const refreshToken = await getRefreshToken();
    const accessToken = await getAccessToken();
    await axios.post('/rest/auth/logout/', {
      refreshToken, accessToken
    });
    localStorage.removeItem("showNotifications")
    await Promise.all([deleteAccessToken(), deleteRefreshToken(), document.location.reload()]);
  };
  
  const refreshToken = async () => {
    await getNewToken().then((response:any) => {
      
      setAccessToken(response.data.accessToken);
      setToken(response.data.accessToken)
    });
  };

  const getUser = () => {
    if (userInfo?.name !== "") return userInfo;
    else if (getAccessToken()) return jwtDecode(getAccessToken()).userInfo;
    else return null;
  }

  useEffect(() => {
    const initAuth = async () => {

      const storedToken = await getAccessToken();
      const storedRefreshToken = await getRefreshToken();
      setToken(storedToken);

      if (storedToken == null && storedRefreshToken !== null) {
        await refreshToken();
      }

      setLoading(false);
    };
    initAuth();
  }, []);

  return {
    isLoading,
    isAuthenticated,
    login,
    logout,
    token,
    userInfo: getUser()
  };
};

interface SignInResponse {
  accessToken:string
  refreshToken: string
}