import DefaultSelector from "../utils/DefaultSelector";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_SERVICES_FOR_SPEC} from "../lessons/graphqlRequests";
import {GET_SERVICES} from "./graphql";
import {Spin} from "antd";

export function ServiceSelector(props) {
    const {onChange, value, forSpec} = props;
    const {loading, data = {servicesForSpec: [], services: []}} =
        useQuery(forSpec ? GET_ALL_SERVICES_FOR_SPEC : GET_SERVICES, {
            skip: props.skip,
            variables: {
                id: Number.parseInt(forSpec)
            }
        });
    const options = data.servicesForSpec || data.services || []
    return (
        <Spin spinning={loading}>
            <DefaultSelector {...props}
                         placeholder="Выберите услугу" value={value}
                         id={"service_selector"}
                         onChange={(selectedId) => onChange(options.find(item => item.id === selectedId))}
                         dataTitle="info" dataIndex="id"
                         options={options.map(service =>
                             ({
                                 ...service,
                                 info: `${service.name} | ${service.duration} мин. | ${service.costForClient} руб.`
                             }))
                         }
        />
        </Spin>
    )
}

export function ServicesSelector(props) {
    const {onChange, value, forSpec} = props;
    const {loading, data = {servicesForSpec: [], services: []}} =
        useQuery(forSpec ? GET_ALL_SERVICES_FOR_SPEC : GET_SERVICES, {
            skip: props.skip,
            variables: {
                id: Number.parseInt(forSpec)
            }
        });
    const options = data.servicesForSpec || data.services || []
    return (
        <Spin spinning={loading}>
            <DefaultSelector {...props} mode={"multiple"}
                         placeholder="Выберите услугу" value={value}
                         id={"service_selector"}
                         onChange={props.onChange}
                         dataTitle="info" dataIndex="id"
                         options={options.map(service =>
                             ({
                                 ...service,
                                 info: `${service.name} | ${service.duration} мин. | ${service.costForClient} руб.`
                             }))
                         }
        />
        </Spin>
    )
}
