import DefaultSelector from "../utils/DefaultSelector";
import React from "react";

const statuses = [
    {name: "Предстоит"},
    {name: "Завершен"},
    {name: "Отменен"},
    {name: "Ожидает_решения"},
    {name: "Резерв"},
    {name: "Не_дозвонились"},
];

export function LessonStatusSelector(props) {
    return (
        <DefaultSelector {...props}
                         options={statuses} dataTitle="name"
                         dataIndex="name"
                         onChange={selectedStatusName => props.onChange(statuses.find(status => status.name === selectedStatusName))}
                         placeholder="Статус занятия" id={"lesson_status_selector"}/>
    )
}

export function LessonStatusesSelector(props) {
    return (
        <DefaultSelector {...props} mode={"multiple"}
                         options={statuses}
                         dataTitle="name" dataIndex="name"
                         onChange={props.onChange}
                         placeholder="Статусы занятия" id={"lesson_status_selector"}/>
    )
}
