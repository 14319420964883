import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { RetryLink } from "apollo-link-retry";
import { ApolloLink } from "apollo-boost";
import {
  getAccessToken,
  getExpiresIn,
  getRefreshToken,
  setAccessToken,
  setExpiresIn,
} from "../auth/tokenUtils";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

var jwtDecode = require("jwt-decode");

export function getApolloClient(): ApolloClient<any> {
  const cache = new InMemoryCache();
  const httpLink = createHttpLink({
    uri: "/graphql",
  });

  return new ApolloClient({
    cache: cache,
    link: ApolloLink.from([retryLink, authLink, jwtLink, httpLink]),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
  });
}

export const getNewToken = ():Promise<any> => {
  return axios.post("/rest/auth/refresh", {refreshToken: getRefreshToken()})
}

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getAuthHeaders(),
    },
  };
});

const jwtLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () =>  !isTokenExpired() || typeof getAccessToken() !== 'string',
  fetchAccessToken: getNewToken,
  handleFetch: accessToken => {
    console.info("handleFetch", accessToken);
    const accessTokenDecrypted = jwtDecode(accessToken);
    setAccessToken(accessToken);
    setExpiresIn(accessTokenDecrypted.exp);
  },
  handleResponse: (operation, accessTokenField) => (response:any) => {
    console.log("handleResponse response", response);
    return { access_token: response.data.accessToken }
  },
  handleError: err => {
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);
  }
})

const retryLink = new RetryLink({
  delay: {
  initial: 3000,
  max: Infinity,
  jitter: true
},
attempts: {
  max: 5,
  retryIf: (error, _operation) => !!error
}
});

const isTokenExpired = (): boolean => {
  const tokenExpiresIn = getExpiresIn();
  const currentTime = new Date().getTime() / 1000;

  return (tokenExpiresIn - currentTime) <= 5;
};
export function getAuthHeaders() {
  const token = getAccessToken();

  return {
    authorization: "Bearer " + token,
  };
}

export function updateAxiosAuthInterceptor() {
  const refreshAuthLogic = (failedRequest: any) =>
    getNewToken().then((tokenRefreshResponse) => {
      console.log('tokenRefreshResponse', tokenRefreshResponse);
      // setAccessToken(tokenRefreshResponse?.data?.token);
      failedRequest.response.config.headers["Authorization"] = "Bearer " + getAccessToken();
      return Promise.resolve();
    });

  createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    statusCodes: [401],
  });
}
