import {useQuery} from "@apollo/react-hooks";
import {GET_SPECIALISTS} from "../favors/graphqlRequests";
import React, {useEffect} from "react";
import Spin from "antd/es/spin";
import DefaultSelector from "../utils/DefaultSelector";

export function SpecialistSelector(props) {
    const {value, onChange} = props;
    const {loading, data = {specialists: []}, refetch} = useQuery(GET_SPECIALISTS);
    useEffect(() => {
        refetch();
    }, [value]);

    const options = data.specialists.map(spec => ({...spec, fio: `${spec.lastName} ${spec.name} ${spec.surName}`}));
    return (
        <Spin spinning={loading}>
            <DefaultSelector {...props}
                             placeholder="Выберите специалиста"
                             onChange={val => onChange(data.specialists.find(spec => spec.id === val))}
                             value={value ? value.toString() : null}
                             options={options} dataTitle="fio"
            />
        </Spin>
    )
}

export function SpecialistsSelector(props) {
    const {value, onChange} = props;
    const {loading, data = {specialists: []}, refetch} = useQuery(GET_SPECIALISTS);
    useEffect(() => {
        refetch();
    }, [value]);

    const options = data.specialists.map(spec => ({...spec, fio: `${spec.lastName} ${spec.name} ${spec.surName}`}));
    return (
        <Spin spinning={loading}>
            <DefaultSelector {...props} mode={"multiple"}
                             placeholder="Выберите специалиста"
                             onChange={val => onChange(val)}
                             value={value ? value : []}
                             options={options} dataTitle="fio"
            />
        </Spin>
    )
}
