import gql from "graphql-tag";

const GET_CHILD = gql`query getChild($id: ID!){
    child(id: $id) {
        id
        name
        lastName
        surName
        birthday
        description,
        isDeleted
    }
}`;

const UPDATE_CHILD = gql`mutation updateChild(
    $id: ID, $name: String!, $lastName: String!,
    $surName: String, $birthday: String, $description: String, $isDeleted: Boolean) {
    updateChild(
        id: $id,
        name: $name
        lastName: $lastName
        surName: $surName
        birthday: $birthday
        description: $description,
        isDeleted: $isDeleted
    ) {
        id
        lastName
        name
        surName
    }
}`;

export {
    GET_CHILD,
    UPDATE_CHILD
}