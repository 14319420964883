import React, {ChangeEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Button, Checkbox, Col, Form, Input, notification, Row} from "antd";
import {axiosInstance as axios} from "../../utils/axios";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const DEFAULT_VALUES = {
    id: undefined, lastName: "", name: "", surName: "", phoneNumber: "", password: "", isDeleted: false
}

export const GET_USER = gql`query administrator($id: ID!){
    administrator(id: $id) {
        id
        name
        lastName
        surName
        phoneNumber
        password
        isDeleted
        roles { id name }
    }
}`;

export const EditAccountPage = () => {
    const {id} = useParams();
    const history = useHistory();
    const [data,setData] = useState({id, ...DEFAULT_VALUES })

    const {data:administratorInfo = {administrator: DEFAULT_VALUES}, loading} = useQuery(GET_USER, {
        skip: !id, variables: {id}
    })

    const saveAccount = () => {
        axios.post("/rest/auth/register/admin", {...data})
            .then(() => history.push("/accounts"))
            .catch((reason) => notification.error({description: reason, message: "При сохранении возникли ошибки" }))
    }

    useEffect(() => {
        setData((prevState:any) => ({
            ...prevState,
            ...administratorInfo.administrator
        }))
    }, [administratorInfo.administrator])

    const onFieldChange = ({target:{id, value, checked}}:ChangeEvent<any>) => {
        setData((prevState) => ({...prevState, [id]: id === "isDeleted" ? checked : value}))
    }

    return (
        <Form onChange={onFieldChange}>
            <Form.Item id={"id"} label={"ID"}> <Input disabled value={data.id} /></Form.Item>
            <Form.Item id={"lastName"} label={"Фамилия"}>
                <Input id={"lastName"} value={data.lastName}/>
            </Form.Item>
            <Form.Item id={"name"} label={"Имя"}>
                <Input id={"name"} value={data.name} />
            </Form.Item>
            <Form.Item id={"surName"} label={"Отчество"}>
                <Input id={"surName"} value={data.surName} />
            </Form.Item>

            <Form.Item id={"phoneNumber"} label={"Логин"}>
                <Input id={"phoneNumber"} value={data.phoneNumber} />
            </Form.Item>
            <Form.Item id={"password"} label={"Пароль"}>
                <Input.Password visibilityToggle={true} id={"password"} value={data.password}/>
            </Form.Item>
            <Form.Item label={"Профиль заблокирован?"}>
                <Checkbox id={"isDeleted"} defaultChecked={false} value={data.isDeleted}/>
            </Form.Item>

            <Row gutter={16}>
                <Col span={12}><Button style={{width: '100%'}} type={"primary"} onClick={saveAccount}>Сохранить</Button></Col>
                <Col span={12}><Button style={{width: '100%'}} type={"danger"}>Отмена</Button></Col>
            </Row>
        </Form>
    )
}