import Divider from "antd/es/divider";
import Select, {SelectProps} from "antd/es/select";
import React, {ReactNode} from "react";

interface DefaultSelectorProps extends SelectProps {
    options: any[]
    dataIndex?: string
    dataTitle?: string
}

const DefaultSelector = ({
                             placeholder,
                             value = [],
                             options = [],
                             dataIndex = "id",
                             dataTitle = "title",
                             onChange = () => {},
                             disabled, id,
                             mode = "default"
                         }: DefaultSelectorProps): JSX.Element => {

    const selectOptions = options
        .map((option, index) => (
            <Select.Option className={`${id}_${index}`} value={option[dataIndex]} key={`option_${dataIndex}_${index}`}>
                {option[dataTitle]}
            </Select.Option>
        ))

    const dropdownRender = (menuNode: ReactNode): ReactNode => {
        const options = {
            // @ts-ignore
            ...menuNode,
            props: {
                ...menuNode.props,
                menuItems: menuNode.props.menuItems.slice(0, 25)
            }
        }

        return (
            <>
                {options}
                <Divider style={{marginTop: 8, marginBottom: 8}}/>
                <div
                    style={{padding: '4px 8px'}}
                    onMouseDown={e => e.preventDefault()}
                >
                    Показано {options.props.menuItems.length} записей из {selectOptions.length}
                </div>
            </>
        )
    }

    return (
        <Select style={{width: '100%'}} placeholder={placeholder} value={value || []}
                onChange={onChange} id={id || "selector"}
                disabled={disabled}
                showSearch mode={mode}
                optionFilterProp="children"
                notFoundContent={"Не найдено"}
                dropdownRender={dropdownRender}
        >
            {selectOptions}
        </Select>
    )
}

export default DefaultSelector;