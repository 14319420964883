import gql from "graphql-tag";

const getCabinets = gql`query {
    locations {
        id
        address
        cabinet
    }
}`;

const getSpecialist = gql`query getSpecialistById($id: ID!) {
    specialist(id: $id) {
        id
        name
        lastName
        birthday
        surName
        description
        isDeleted
        cabinets {
            id
        }
        services {
            id
        }       
    }
}`;

const SAVE_SPECIALIST = gql`mutation saveSpecialist(
    $id:ID,
    $name: String!,
    $lastName: String!,
    $surName: String,
    $birthday: String,
    $description: String,
    $phoneNumber: String
    $isDeleted: Boolean
    $cabinets: [ID]
) {
    updateSpecialist(
        id: $id
        name: $name,
        lastName: $lastName,
        surName: $surName,
        phoneNumber:$phoneNumber,
        birthday :$birthday,
        description: $description,
        isDeleted: $isDeleted,
        cabinets: $cabinets
    ) {
        id
    }
}`;

const GET_WORK_PERIODS = gql`query workPeriodsBySpecialist($specId: ID!) {
    workPeriodsBySpecialist(specId: $specId) {
        id
        dayOfWeek
        from_time
        to_time
    }
}`;

const SAVE_WORK_PERIOD = gql`mutation ss($id: ID, $specId: ID!, $dayOfWeek: Int!, $from: String!, $to: String!, $isDeleted: Boolean){
    saveWorkPeriod (id: $id, specId: $specId, dayOfWeek: $dayOfWeek,
        from: $from, to:$to, isDeleted: $isDeleted) {
        id
        dayOfWeek
    }
}`;

export {getCabinets, getSpecialist, SAVE_SPECIALIST, GET_WORK_PERIODS, SAVE_WORK_PERIOD}