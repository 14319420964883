import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Row from "antd/es/grid/row";
import {Input, notification, Affix} from "antd";
import Col from "antd/es/grid/col";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spin from "antd/es/spin";
import {DatePicker, message, Table} from "antd/es";
import moment from "moment";
import Icon from "antd/es/icon";
import DefaultSelector from "../utils/DefaultSelector";
import {axiosInstance as axios} from "../../utils/axios"
import Tooltip from "antd/es/tooltip";
import InputNumber from "antd/es/input-number";
import Card from "antd/es/card";
import Checkbox from "antd/es/checkbox";
import Select from "antd/es/select";
import {
    FIND_LESSON,
    GET_ALL_SPECS,
    LESSON_MUTATION
} from "./graphqlRequests";
import Modal from "antd/es/modal";
import NewChildModal from "../children/newChildModal";
import LessonHistory from "./historyPanel";
import DefaultDatePicker, {DEFAULT_DATE_FORMATS} from "../utils/DefaultDatePicker";
import {canSaveLessonsPromise} from "../../utils/lessons";
import {ServiceSelector} from "../services/selector";
import {LocationSelector} from "../locations/selector";
import {ClientSelector} from "../children/selector";
import {LessonStatusSelector} from "../lessonStatus/selector";
import {LessonPrioritySelector} from "../lessonPriority/selector";

const defaultColumns = [
    {
        name: "date",
        span: 4,
        label: "Дата занятия",
        item: (lesson, setLesson) => <DefaultDatePicker placeholder="Дата" value={moment(lesson.date)}
                                                        id={"lesson_date_picker"}
                                                        onChange={(moment) => setLesson({
                                                            ...lesson,
                                                            date: !moment ? moment : moment.format("YYYY-MM-DD")
                                                        })}/>
    },
    {
        name: "time",
        span: 4,
        label: "Время занятия",
        item: (lesson, setLesson) => <Input type="time" placeholder="Время" value={lesson.time}
                                            id={"lesson_time_picker"} name={"Время занятия"}
                                            onChange={({target: {value}}) => setLesson({...lesson, time: value})}/>
    },
    {
        name: "time",
        span: 4,
        label: "Продолжительность",
        item: (lesson, setLesson) => <InputNumber placeholder="Продолжительность" value={lesson.duration}
                                                  min={5} max={1440}
                                                  onChange={(duration) => setLesson({...lesson, duration})}
                                                  style={{width: '100%'}}/>
    },
    {
        name: "status",
        span: 6,
        label: "Статус занятия",
        item: (lesson, setLesson) => <LessonStatusSelector value={lesson.status.name}
                                                      onChange={(status) => setLesson({...lesson, status})}/>
    },
    {
        name: "priority",
        span: 6,
        label: "Приоритет занятия",
        item: (lesson, setLesson) => <LessonPrioritySelector value={lesson.priority.name}
                                                             onChange={(priority) => setLesson({...lesson, priority})}/>
    },
];

export default function Lesson({id, setIdNull = false, newDateTime = null, lessonCallback}) {
    const startMoment = Date.now();
    const lessonInitial = {
        id: null,
        time: null,
        date: null,
        duration: 30,
        status: {name: "Предстоит"},
        priority: {name: "Средний"},
        child: {id: null},
        location: {id: null},
        specialist: {id: null},
        service: {id: null},
        isCalled: false,
        isConfirmed: false,
        cost: 0,
        description: ""
    };

    const history = useHistory();
    const location = useLocation();
    const {state: predefined} = location;
    const columnsToDisplay = defaultColumns;
    const [lesson, setLesson] = useState(lessonInitial);
    const [lessonHistoryPanel, setLessonHistoryPanel] = useState({visible: false, lessonId: null});
    const findLessonQuery = useQuery(FIND_LESSON, {variables: {id: Number.parseInt(id)}, skip: !id});
    const [lessonDates, setLessonDates] = useState([]);
    const [severalErrorsProps, setSeveralErrorsProps] = useState({visible: false, conflictItems: []});
    const [saving, setSaving] = useState(false);
    const [addChildModal, setAddChildModal] = useState({visible: false});

    const {data: specialistsData = {specialists: []}} = useQuery(GET_ALL_SPECS);

    if (predefined) {
        if (predefined.date) {
            lessonInitial.date = moment(predefined.date).format("YYYY-MM-DD");
            lessonInitial.time = moment(predefined.date).format("HH:mm:00");
        }
        if (predefined.resource) {
            lessonInitial[predefined.resource.type] = {id: predefined.resource.id.toString()};
        }
    }

    useEffect(() => {
        const {specialists} = specialistsData;
        if (predefined && predefined.resource && predefined.resource.type === "specialist") {
            const spec = specialists.find(spec => spec.id === predefined.resource.id.toString());
            const cabs = (spec && spec.cabinets.length > 0) ? spec.cabinets : [{id: null}];
            lessonInitial.location = cabs[0];
            if (spec) {
                setLesson({...lesson, location: cabs[0]})
            }
        }
    }, [specialistsData]);


    const [saveLessonMutation] = useMutation(LESSON_MUTATION);
    const {
        loading, data = {
            lesson: lesson
        }, error
    } = findLessonQuery;

    useEffect(() => {
        const date = newDateTime ? moment(newDateTime).format("YYYY-MM-DD") : data.lesson.date;
        const time = newDateTime ? moment(newDateTime).format("HH:mm:00") : data.lesson.time;

        setLesson({
            ...lesson,
            ...data.lesson,
            date, time,
            id: setIdNull ? null : id,
            created: setIdNull ? null : lesson.created
        });
    }, [findLessonQuery, newDateTime, setIdNull]);

    const deleteLesson = () => {
        setLesson({...lesson, isDeleted: true});
        saveLesson({
            isDeleted: true,
            description: `${lesson.description}\nЗанятие удалено`,
            status: "Отменен"
        }, () => {
            history.push("/")
        });
    };

    const saveLesson = (updFields = {}) => {
        const savingMessage = message.loading("Сохранение...", 0);
        const data = {
            id: Number.parseInt(lesson.id),
            specId: Number.parseInt(lesson.specialist.id),
            priority: lesson.priority.name,
            status: lesson.status.name,
            time: lesson.time,
            duration: lesson.duration,
            date: lesson.date,
            childId: Number.parseInt(lesson.child.id),
            serviceId: Number.parseInt(lesson.service.id),
            locationId: Number.parseInt(lesson.location.id),
            isDeleted: lesson.isDeleted || false,
            isCalled: lesson.isCalled,
            description: lesson.description,
            cost: lesson.cost,
            ...updFields
        };
        let promise = axios.post("/rest/lesson/canSaveLesson", {
            ...lesson,
            created: null,
            child: {id: Number.parseInt(lesson.child.id)},
            location: {id: Number.parseInt(lesson.location.id)},
            specialist: {id: Number.parseInt(lesson.specialist.id)},
            duration: lesson.duration,
            status: updFields.status || lesson.status.name,
            priority: lesson.priority.name
        });
        promise.then((value) => {
            const {canSave, error} = value.data;

            if (!canSave) {
                setTimeout(savingMessage, 10);
                notification.error({
                    message: 'Занятия пересекаются',
                    description: (<Row gutter={16}>
                        <Col span={24}>
                            {error}
                        </Col>
                        <Col span={24}>
                            <Button type="danger" style={{width: '100%'}}
                                    onClick={() => saveLesson({status: "Ожидает_решения"})}
                            >
                                Сохранить вопреки всему
                            </Button>
                        </Col>
                    </Row>),
                    duration: 30,
                });
            } else {
                saveLessonMutation({variables: data})
                    .then(resp => {
                        setTimeout(savingMessage, 100);
                        message.success("Сохранено!", 4);
                        lessonCallback ? lessonCallback() : history.goBack()
                    }).catch(error => {
                    console.error(error);
                    console.error(error.message);
                    message.error("При сохранении произошла ошибка. См. в консоль (F12)", 10)
                    message.error(error.message, 10)
                })
            }
        });
        promise.catch(reason => console.error("ERROR: ", reason));
    };

    const selectChild = (id) => setLesson({...lesson, child: {id}});

    const saveSeveralLessons = () => {
        const lessons = lessonDates.map(date => ({
            ...lesson,
            date: moment(date, "DD.MM.YYYY").format("YYYY-MM-DD"),
            status: lesson.status.name,
            priority: lesson.priority.name,
        }));
        setSaving(true);
        let promise = canSaveLessonsPromise(lessons.map(lesson => ({
            ...lesson,
            child: { id: Number.parseInt(lesson.child.id) },
            location: { id: Number.parseInt(lesson.location.id) },
            specialist: { id: Number.parseInt(lesson.specialist.id) },
            duration: lesson.duration,
            status: lesson.status.name,
            priority: lesson.priority.name
        })));

        promise.then(({ data }) => {
            if (data.length) {
                setSeveralErrorsProps({
                    ...severalErrorsProps,
                    visible: true,
                    conflictItems: data,
                    onOk: () => {
                        saveSeveral(lessons);
                        setSeveralErrorsProps({ ...severalErrorsProps, visible: false })
                    },
                    cancelSavingMode: () => setSaving(false)
                });
            } else {
                saveSeveral(lessons)
            }
        })
    };

    const saveSeveral = (lessons) => {
        axios.post("/rest/lesson/several/save", lessons)
            .then(response => {
                message.success("Сохранено!", 4)
            })
            .catch(error => {
                console.error(error);
                message.error("Возникла ошибка при сохранении.", 10)
            })
            .finally(() => {
                setSaving(false);

                location.state.background
                    ? history.push(location.state.background)
                    : history.goBack();
            });
    };

    const isFormValid = (lesson.time && lesson.date &&
        lesson.service.id && lesson.specialist.id && lesson.child.id && lesson.location.id);


    const openAddChildModal = () =>
        setAddChildModal({
            ...addChildModal,
            visible: true,
            selectChild
        });

    const selectSpec = (value) => {
        const spec = specialistsData.specialists.find(item => item.id === value);
        const newVals = {
            service: {id: null},
            specialist: {id: value}
        };

        if (spec.cabinets.length > 0) {
            newVals.location = {id: spec.cabinets[0].id}
        } else {
            newVals.location = {id: null}
        }
        setLesson({
            ...lesson,
            ...newVals
        })
    };

    console.info('lesson', lesson)
    return (
        <Spin spinning={loading || saving}>
            <Row gutter={[8, 8]}>
                {
                    columnsToDisplay.map(({item, label, span}, index) => (
                        <Col span={span ? span : 12} key={`col_${index}`}>
                            <Form.Item required label={label}>
                                {item(lesson, setLesson)}
                            </Form.Item>
                        </Col>
                    ))
                }
                <Col span={12}>
                    <Form.Item required label="Клиент" validateStatus={!lesson.child.id ? "error" : "success"}>
                        <Row gutter={8}>
                            <Col span={2}>
                                <Tooltip title={!lesson.child.id ? "Укажите клиента" : "Просмотр расписания клиента"}>
                                    <Button disabled={!lesson.child.id}
                                            onClick={() => history.push({
                                                pathname: `/schedule/child/${lesson.child.id}`,
                                                state: {
                                                    background: location,
                                                    schedule: {selectable: false}
                                                }
                                            })}
                                    >
                                        <Icon type="calendar"/>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={20}>
                                <ClientSelector value={lesson.child.id} onChange={(client) => selectChild(client.id)}/>
                            </Col>
                            <Col span={2}>
                                <Button onClick={openAddChildModal}><Icon type="plus"/></Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item required label="Кабинет"
                               validateStatus={!lesson.location.id ? "error" : "success"}
                    >
                        <Row gutter={8}>
                            <Col span={2}>
                                <Tooltip
                                    title={!lesson.location.id ? "Укажите кабинет" : "Просмотр расписания кабинета"}>
                                    <Button disabled={!lesson.location.id}
                                            onClick={() => history.push({
                                                pathname: `/schedule/location/${lesson.location.id}`,
                                                state: {
                                                    background: location,
                                                    schedule: {selectable: false}
                                                }
                                            })}
                                    >
                                        <Icon type="calendar"/>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={22}>
                                <LocationSelector value={lesson.location.id} onChange={location =>
                                    setLesson({ ...lesson, location: { id: location.id } })
                                }
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item required label="Специалист" validateStatus={!lesson.specialist.id ? "error" : "success"}>
                        <Row gutter={8}>
                            <Col span={2}>
                                <Tooltip
                                    title={!lesson.specialist.id ? "Укажите специалиста" : "Просмотр расписания специалиста"}>
                                    <Button disabled={!lesson.specialist.id}
                                            onClick={() => history.push({
                                                pathname: `/schedule/specialist/${lesson.specialist.id}`,
                                                state: {
                                                    background: location,
                                                    schedule: {selectable: false}
                                                }
                                            })}
                                    >
                                        <Icon type="calendar"/>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={22}>
                                <DefaultSelector placeholder="Выберите специалиста" value={lesson.specialist.id}
                                                 onChange={selectSpec} id={"specialist_selector"}
                                                 dataTitle="fio" dataIndex="id"
                                                 options={specialistsData.specialists.map(spec => ({
                                                     ...spec,
                                                     fio: `${spec.lastName} ${spec.name} ${spec.surName}`
                                                 }))}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item required label="Услуга"
                               validateStatus={!lesson.service.id ? "error" : "success"} hasFeedback
                               help={!lesson.service.id ? "Требуется заполнить" : null}
                    >
                        <ServiceSelector
                            value={lesson.service.id}
                            forSpec={lesson.specialist.id}
                            onChange={serviceInfo => setLesson({
                                ...lesson,
                                service: {id: serviceInfo.id},
                                duration: serviceInfo.duration || lessonInitial.duration,
                                cost: serviceInfo.costForClient || lessonInitial.cost
                            })}/>

                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label="Итоговая стоимость">
                        <InputNumber id="cost" value={`${lesson.cost} руб`} style={{width: '100%'}}
                                     min={-1} max={10_000}
                                     onChange={(cost) => setLesson({...lesson, cost})}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Col span={24}>
                        <Form.Item label="Комментарий к занятию">
                            <Input.TextArea id="description" value={lesson.description}
                                            onChange={({target: {value}}) => setLesson({...lesson, description: value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Checkbox
                                checked={lesson.isCalled}
                                onChange={() => setLesson({...lesson, isCalled: !lesson.isCalled})}
                            >
                                Был произведен звонок
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Checkbox disabled
                                checked={lesson.isConfirmed}
                            >
                                Клиент подтвердил занятие через Telegram
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Col>
                {lesson.id ? null : (
                    <Col span={24}>
                        <SeveralForm lessonDates={lessonDates} setLessonDates={setLessonDates}/>
                    </Col>
                )}
                <Col span={24}>
                    <Affix offsetBottom={10}>
                        <Button type="primary" onClick={() => {
                            lessonDates.length ? saveSeveralLessons() : saveLesson()
                        }} style={{width: '100%'}}
                                disabled={!isFormValid} id={"save_btn"}
                        >
                            {
                                isFormValid ? lessonDates.length ? "Сохранить несколько занятий" : "Сохранить"
                                    : "Для сохранения заполните обязательные поля"
                            }
                        </Button>
                    </Affix>
                    {
                        !(lesson.id) ? null :
                            (<>
                                <Col span={4}>
                                    <Button type="info"
                                            onClick={() => setLessonHistoryPanel({
                                                ...lessonHistoryPanel,
                                                visible: true,
                                                entityId: lesson.id
                                            })}
                                    >
                                        История
                                    </Button>
                                </Col>
                                <Col span={lesson.id ? 6 : 2}>
                                    <Popconfirm title="Вы уверены?" onConfirm={deleteLesson}
                                                okText="Да" cancelText="Нет" disabled={!lesson.id}>
                                        <Button disabled={!lesson.id} type="danger" style={{width: '100%'}}>
                                            Удалить
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </>)
                    }
                </Col>
            </Row>
            <SeveralSavingErrors props={severalErrorsProps} setProps={setSeveralErrorsProps}/>
            <NewChildModal {...{
                props: addChildModal, setProps: setAddChildModal, setChild: (id) => {
                    selectChild(id);
                }
            }} />
            <LessonHistory props={lessonHistoryPanel} setProps={setLessonHistoryPanel}/>
        </Spin>
    )
}

const getDatesInRange = (range, days) => {
    const startDate = range[0], endDate = range[1];
    const dates = [];
    let dd = moment(startDate);

    while (dd.isBefore(endDate) || dd.isSame(endDate)) {
        if (days.includes(dd.day())) {
            dates.push(dd.format('YYYY-MM-DD'));
        }
        dd.add(1, 'd');
    }
    return dates;
};

function SeveralForm({lessonDates, setLessonDates}) {
    const [dateRange, setDateRange] = useState([moment(), moment().add(1, 'w')]);
    const [selectedWeekDays, setSelectedWeekDays] = useState([0, 1, 2, 3, 4, 5, 6]);

    const selectRange = (range) => setDateRange(range);
    const onChange = (checkedValues) => setSelectedWeekDays(checkedValues);
    const handleChange = (value) => {
        setLessonDates([...value]);
    }

    const options = [
        {label: 'Пн.', value: 1},
        {label: 'Вт.', value: 2},
        {label: 'Ср.', value: 3},
        {label: 'Чт.', value: 4},
        {label: 'Пт.', value: 5},
        {label: 'Сб.', value: 6},
        {label: 'Вск.', value: 0}
    ];

    let datesToSelect = getDatesInRange(dateRange, options.map(day => day.value)).filter(date => !lessonDates.includes(date));

    function addDays() {
        const dates = getDatesInRange(dateRange, selectedWeekDays)
                        .filter(date => !lessonDates.includes(date))
                        .map(date => moment(date).format("DD.MM.YYYY"));

        setLessonDates([...lessonDates, ...dates])
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card title="Даты для дублирования занятия" size="small">
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Form.Item required label="Диапазон дат">
                                <DatePicker.RangePicker defaultValue={dateRange} onChange={selectRange}
                                                        format={DEFAULT_DATE_FORMATS}/>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label="Дни недели">
                                <Checkbox.Group options={options} defaultValue={[]} onChange={onChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Button type="primary" onClick={addDays}>Добавить</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item required label="Даты занятий"
                                       validateStatus={lessonDates.length ? "success" : "error"}>
                                <Select value={lessonDates.map(d => moment(d, "DD.MM.YYYY").format("DD.MM.YYYY"))}
                                        allowClear mode="multiple" defaultValue={[]} style={{width: '100%'}}
                                        onChange={handleChange}
                                >
                                    {
                                        datesToSelect.map((date, index) =>
                                            (
                                                <Select.Option value={moment(date).format("DD.MM.YYYY")}
                                                               key={`option_${index}`}>
                                                    {moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")}
                                                </Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

function SeveralSavingErrors({props, setProps}) {
    const cols = [
        {
            title: "Дата",
            render: (item) => moment(item.date).format("DD.MM.YYYY"),
        },
        {
            title: "Ошибка",
            dataIndex: "error"
        }
    ];
    return (
        <Modal visible={props.visible}
               okText={"Сохранить все вопреки всему"} cancelText={"Отмена"}
               onOk={props.onOk}
               onCancel={() => {
                   props.cancelSavingMode();
                   setProps({...props, visible: false})
               }}
               width={1040} title={"При сохранении возникли конфликты"}
        >
            <Table dataSource={props.conflictItems} columns={cols}/>
        </Modal>
    )
}
