import React, {useEffect, useState} from 'react';
import { Drawer, Result, Spin, Button, message, Menu } from 'antd/es';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Descriptions, Divider, Typography, Icon, Tooltip, Dropdown } from 'antd';
import moment from 'moment';
import LessonModal from '../lessons/modal';
import {useAxios} from '../../hooks/useAxios';
import {canSaveLesson, showMessageWithConflicts} from "../../utils/lessons";
import ReactGA from 'react-ga';
import {sendLessonTiming} from "../../analytics";
import {analyticsCategories} from "../../analytics/categories";

const { Paragraph } = Typography;
const GET_LESSON_INFO = gql`query GET_LESSON_INFO($lessonId: ID!) {
    lesson(id: $lessonId) {
      id
      date
      time
      status {
        name
      }
      priority {
        name
      }
      
      child {
        id
        lastName
        name
        surName
        birthday
        description
        parents {
          id
          lastName
          name
          surName
          phoneNumber
          description
        }
      }
      specialist {
        id
        name
        lastName
        surName
      }
      service {
        id
        name
      }
      location {
        id
        address
        cabinet
      }
      duration
      cost
      isCalled
      description
      
    }
  }`

const INIT_LESSON = {
    date: moment().format("YYYY-MM-DD"),
    time: "00:00",
    duration: 0,
    cost: 0,
    isCalled: false,
    status: {
        name: "Нет информации"
    },
    location: {
        cabinet: "Нет информации",
        address: "Нет информации"
    },
    specialist: {
        lastName: "Нет информации",
        name: "Нет информации",
        surName: "Нет информации",
    },
    service: {
        name: "Нет информации",
    },
    child: {
        lastName: "Нет информации",
        name: "Нет информации",
        surName: "Нет информации",
        birthday: "Нет информации",
        description: "Нет информации",
        parents: []
    }
}

function ScheduleLessonDrawer (): any  {
    const history = useHistory();
    const { id } = useParams();
    const [lessonModal, setLessonModal] = useState({visible: false, setIdNull: false, lesson: {id: null}});
    const componentStartDate = Date.now();
    const { data = { lesson: INIT_LESSON }, loading, error, refetch: refetchLessonInfo } = useQuery<any>(GET_LESSON_INFO, {
        skip: !id,
        displayName: "getLessonInfo",
        variables: {
            lessonId: id
        },
        onCompleted: () => {
            sendLessonTiming('Получение информации о занятии', componentStartDate)
        }
    })

    useEffect(() => {
        sendLessonTiming('Отображение карточки (drawer)', componentStartDate)
        ReactGA.event({
            category: analyticsCategories.LESSONS,
            action: 'Отображение карточки (drawer)',
        });
    });

    if (error) {
        console.error(error);
        return (
            <Result status={"error"} title={"Возникла ошибка сервера. Свяжитесь с администратором"} subTitle={error} />
        )
    }

    return (
        <Spin spinning={loading}>
            <Drawer
                visible={true} width={700}
                onClose={() => history.goBack()}
            >
                <LessonDescription lesson={data.lesson || INIT_LESSON} updateLessonInfo={refetchLessonInfo}
                    title={(
                        <>
                            Занятие
                            <Button type={"link"} onClick={() => setLessonModal({...lessonModal, visible: true, setIdNull: false, lesson: {id: data.lesson.id}})}>Редактировать</Button>
                            <Divider type={"vertical"}/>
                            <Button type={"link"} onClick={() => setLessonModal({...lessonModal, visible: true, setIdNull: true, lesson: {id: data.lesson.id}})}>Копировать</Button>
                        </>
                    )}
                />
                <Divider />
                <ChildDescription child={data.lesson.child} updateLessonInfo={refetchLessonInfo} />
                <Divider />
                <RepresentativesDescription representatives={data.lesson.child.parents} updateLessonInfo={refetchLessonInfo} />
                <Divider />
            </Drawer>
            <LessonModal {...{lessonModal}} lessonCallback={() => {
                setLessonModal({...lessonModal, visible: false, setIdNull: false});
                refetchLessonInfo();
                } }/>
        </Spin>
    )
}

const statuses = [
    "Предстоит",
    "Завершен",
    "Отменен",
    "Ожидает_решения",
    "Резерв",
    "Не_дозвонились",
];

const LessonDescription = ({ lesson, title, updateLessonInfo }: any) => {
    const history = useHistory();
    const [, executeUpdateLesson] = useAxios(
        {
            url: '/rest/lesson/save/quiet',
            method: 'POST'
        },
        { manual: true }
    )

    const saveLesson = (customProps:any) => {
        const saveStart = Date.now();
        executeUpdateLesson({ data: {...lesson, priority: lesson.priority.name, status: lesson.status.name, ...customProps } })
            .then(updateLessonInfo)
            .then(() => {
                sendLessonTiming('Сохранение занятия', saveStart)
                message.success("Сохранено")
            })
            .catch((reason) => {
                sendLessonTiming('Сохранение занятия', saveStart)
                console.error(reason)
                message.error("Возникла ошибка" + reason, 10)
            })
            .finally(() => {
                ReactGA.event({
                    category: analyticsCategories.LESSONS,
                    action: 'Сохранение занятия',
                });
            })
    }
    const updateLesson = async (customProps: any = {}) => {
        const canSave = await canSaveLesson({
            ...lesson,
            priority: lesson.priority.name,
            status: lesson.status.name,
            ...customProps
        }, showMessageWithConflicts);

        if (canSave) {
            saveLesson(customProps)
        }
    }

    const menu = statuses.map((status) => (
        <Menu.Item>
            <a target="_blank" onClick={e => {
                e.preventDefault();
                updateLesson({ status })
            }}>
              { status }
            </a>
        </Menu.Item>
    )) ;
    
    const calledMenu = [
        "Произведен",
        "Не произведен"
    ].map((callStatus) => (
        <Menu.Item>
            <a target="_blank" onClick={e => { 
                e.preventDefault();
                updateLesson({isCalled: callStatus === "Произведен"})
            }}>
              {callStatus}
            </a>
        </Menu.Item>
    ))
    return (
        <Descriptions title={title || "О занятии"} size={"small"} column={3} colon={false}>
            <Descriptions.Item label="Дата">{moment(`${lesson.date} ${lesson.time}`).format("DD.MM.YY HH:mm")}</Descriptions.Item>
            <Descriptions.Item label="Статус">
                <Dropdown overlay={(<Menu>{menu}</Menu>)}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {lesson.status.name} <Icon type="down" />
                    </a>
                </Dropdown>
            </Descriptions.Item>
            <Descriptions.Item label="Место проведения" span={2}>
                {lesson.location.address}, каб. {lesson.location.cabinet}
            </Descriptions.Item>
            <Descriptions.Item label="Специалист">
                <Tooltip title="Нажмите для перехода на карточку специалиста">
                    <span onClick={() => history.push(`/specialist/${lesson.specialist.id}`)}>
                        {lesson.specialist.lastName} {lesson.specialist.name} {lesson.specialist.surName}
                    </span>
                </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="Услуга" span={2}>
                {lesson.service.name} ({lesson.cost} руб. | {lesson.duration} мин.)
            </Descriptions.Item>
            <Descriptions.Item label="Звонок" span={1}>
                <Dropdown overlay={(<Menu>{calledMenu}</Menu>)}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {lesson.isCalled ? "Произведен" : "Не произведен"} <Icon type="down" />
                    </a>
                </Dropdown>
            </Descriptions.Item>
                <Descriptions.Item label="Краткая информация" span={2}>
                <Paragraph editable={{ onChange: (description: string ) => updateLesson({description}) }}>
                        {lesson.description || "Нет информации"}
                </Paragraph>
            </Descriptions.Item>
        </Descriptions>
    )
}

const RepresentativesDescription = ({ representatives, updateLessonInfo }: any) => {
    const history = useHistory();
    const [{ }, executeUpdateRepresentative] = useAxios(
        {
            url: '/rest/parent/quiet',
            method: 'POST'
        },
        { manual: true }
    )

    const updateRepresentative = (data: any):void => {
        executeUpdateRepresentative({ data })
                    .then(updateLessonInfo)
                    .then(() => message.success("Сохранено"))
                    .catch((reason) => {
                        console.error(reason)
                        message.error("Возникла ошибка" + reason, 10)
                    })
    }

    return (
        <Descriptions title="Представители" size={"small"}>
            {
                representatives.map((parent: any) => (
                    <>
                        <Descriptions.Item label="ФИО" span={2}>
                            <Tooltip title="Нажмите для перехода на карточку представителя">
                                <span onClick={() => history.push(`/parent/${parent.id}`)}>
                                    {parent.lastName} {parent.name} {parent.surName}
                                </span>
                            </Tooltip>
                        </Descriptions.Item>
                        <Descriptions.Item label="Номер телефона">{parent.phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="Краткая информация" span={3}>
                            <Paragraph editable={{ onChange: (description: string ) => updateRepresentative({...parent, description}) }}>
                                {parent.description || "Нет информации"}
                            </Paragraph>
                        </Descriptions.Item>
                    </>
                ))
            }
        </Descriptions>
    )
}
const ChildDescription = ({ child, updateLessonInfo }: any) => {
    const history = useHistory();
    const [{ }, executeUpdateDescription] = useAxios(
        {
            url: '/rest/child/updateDescription',
            method: 'POST'
        },
        { manual: true }
    )

    const updateDescription = (description: string):void => {
        executeUpdateDescription({ data: { id: child.id, description } })
            .then(updateLessonInfo)
            .then(() => message.success("Сохранено"))
            .catch((reason) => {
                console.error(reason)
                message.error("Возникла ошибка" + reason, 10)
            })
    }
    const title = (<>Клиент <Button type={"link"} onClick={() => history.push(`/child/${child.id}`)}>Карточка</Button></>)
    return (
        <Descriptions size={"small"} title={title} >
            <Descriptions.Item span={2} label="ФИО">
                <Tooltip title="Нажмите для перехода на карточку клиента">
                    <span onClick={() => history.push(`/child/${child.id}`)}>
                        {child.lastName} {child.name} {child.surName}
                    </span>
                </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="Дата рождения.">
                {child.birthday ? moment(child.birthday).format("DD.MM.YYYY") : "Нет информации"}
            </Descriptions.Item>
            <Descriptions.Item label="Краткая информация" span={3} >
                    <Paragraph editable={{ onChange: updateDescription }}>
                        {child.description || "Нет информации"}
                    </Paragraph>
            </Descriptions.Item>
        </Descriptions>
    )
}

export default ScheduleLessonDrawer;