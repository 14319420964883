import React, {useEffect} from "react";
import DefaultTable from "../utils/DefaultTable";
import Spin from "antd/es/spin";
import {Button} from "antd";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {useHistory} from "react-router-dom";

const GET_LOCATIONS = gql`query {
    locations {
        id
        address
        cabinet
    }
}`;

export default function Locations() {
    const history = useHistory();
    const query = useQuery(GET_LOCATIONS);
    const {data = {locations: []}, loading, error, refetch} = query;

    useEffect(() => {
        refetch();
    }, [history]);

    const cols = [
        {
            title: "Адрес",
            dataIndex: "address"
        },
        {
            title: "Кабинет",
            dataIndex: "cabinet"
        },
        {
            title: "Действия",
            dataIndex: "",
            render: row => (<Button type="link" onClick={() => history.push(`/location/${row.id}`)}>Ред.</Button>)
        },

    ];
    return (
        <Spin spinning={loading}>
            <DefaultTable tableTitle="Кабинеты" columns={cols} dataSources={data.locations}
                          footer={() => {
                              return (<Button type="link" onClick={() => history.push("/location/")}>Добавить</Button>)
                          }}
            />
        </Spin>
    )

}