import React, {useState} from "react";
import Modal from "antd/es/modal";
import ChildForm from "./childForm";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Spin from "antd/es/spin";

export default function NewChildModal({props, setProps,setChild }) {
    const [saving, setSaving] = useState(false);
    const onCancel =() => setProps({...props, visible: false});
    return (
        <Spin spinning={saving}>
            <Modal visible={props.visible} title={"Добавление нового клиента"}
                   onCancel={onCancel} width={1024} footer={null}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ChildForm onCancel={onCancel} props={props} setProps={setProps} setChild={setChild} setSaving={setSaving}/>
                    </Col>
                </Row>
            </Modal>
        </Spin>
    )
}