import React from "react";
import { Drawer, Col, Form, Row, Checkbox, message } from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";
import DefaultSelector from "../utils/DefaultSelector";

interface ScheduleProps {
  cellSizeInMinutes: number
  setCellSizeInMinutes: (_: any) => void

  showStatuses: string[]
  setShowStatuses?: (_: any) => void | any[]
}

interface ScheduleSettingsProps {
  visible?: boolean
  onClose?: () => void
  scheduleProps: ScheduleProps
}

const ScheduleSettings = ({ visible, onClose, scheduleProps: { cellSizeInMinutes = 30, setCellSizeInMinutes ,
    showStatuses = [], setShowStatuses = () => {} }}
  : ScheduleSettingsProps): any => {

  return (
    <Drawer
      title="Настройки календаря"
      placement="right"
      width={400}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <Row>
        <Col span={24}>
          <Form.Item label="Размер ячеек">
            <DefaultSelector placeholder={"10"} value={cellSizeInMinutes}
              options={[10, 30, 60].map(value => ({ id: value, title: `${value} мин.` }))}
              onChange={value => {
                setCellSizeInMinutes(value);
                message.success("Сохранено");
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Показывать занятия в статусе">
            <CheckboxGroup
              options={["Предстоит", "Ожидает_решения", "Не_дозвонились", "Резерв", "Отменен", "Завершен"]}
              value={showStatuses}
              onChange={(vals) => {
                setShowStatuses(vals)
                message.success("Сохранено");
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Drawer>
  )
}

export default ScheduleSettings;