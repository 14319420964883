import {useMutation, useQuery} from "@apollo/react-hooks";
import React, {useEffect, useState} from "react";
import Result from "antd/es/result";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import Card from "antd/es/card";
import Col from "antd/es/grid/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import 'rc-color-picker/assets/index.css';
import {message, Popconfirm} from "antd";
import {GET_CHILD, UPDATE_CHILD} from "./graphqlRequests";
import ChildHistory from "./historyPanel";
import moment from "moment";
import Text from "antd/es/typography/Text";
import DefaultDatePicker from "../utils/DefaultDatePicker";

export default function ChildCard({childId, navigation}) {
    const requestFromServer = useQuery(GET_CHILD, {
        variables: {
            id: Number.parseInt(childId),
        },
        skip: !childId
    });
    const {
        loading, data = {
            child: {
                name: "", lastName: "", surName: "", birthday: null, id: null
            }
        }
    } = requestFromServer;
    const [updateChild] = useMutation(UPDATE_CHILD);
    const [child, setChild] = useState({...data.child});
    const [saving, setSaving] = useState(false);
    const [historyPanel, setHistoryPanel] = useState({visible: false, entityId: null});

    useEffect(() => {
        setChild(data.child)
    }, [requestFromServer]);

    const saveChild = (customProps) => {
        let messageSaving = message.loading("Сохранение", 0);
        setSaving(true);
        console.info("customProps", customProps)
        updateChild({variables: customProps !== undefined ? {...child, ...customProps}: child})
            .then(r => {
                message.success("Клиент сохранен");
                navigation.push(`/child/${r.data.updateChild.id}`)
            })
            .catch(reason => {
                console.error('err', reason.message, reason);
                message.error('Клиент с такими данными уже существует', 20);
            })
            .finally(() => {
                setTimeout(messageSaving, 100);
                setSaving(false);
            })
    };

    const changeBirthday = (asMoment, str) => {

        let birthday = null
        if (asMoment != null)
            birthday = asMoment.format("YYYY-MM-DD");

        setChild({...child, birthday})
    };

    const changeField = ({target: {id, value}}) => {
        if (value) {
            value = value[0].toUpperCase() + value.slice(1);
        }

        setChild({...child, [id]: value})
    };

    if (!child) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Клиент не найден"
                extra={<Button type="primary" onClick={() => navigation.push("/children/")}>К списку клиентов</Button>}
            />
        )
    }

    const deleteClient = () => {
        console.info("DELETE CLIENT ", childId)
        saveChild({isDeleted: true})
    }

    return (
        <Spin spinning={loading || saving}>
            <Card size="small" title="Клиент">
                <Col>
                    <Col span={12}>
                        <Form.Item label="Фамилия" required
                                   validateStatus={!child.lastName ? "error" : "success"} hasFeedback
                                   help={!child.lastName ? "Необходимо заполнить" : null}
                        >
                            <Input placeholder="Фамилия" required value={child.lastName} id="lastName"
                                   onChange={changeField}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Имя" required
                                   validateStatus={!child.name ? "error" : "success"} hasFeedback
                                   help={!child.name ? "Необходимо заполнить" : null}
                        >
                            <Input placeholder="Имя" autoFocus required value={child.name} id="name"
                                   onChange={changeField}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Col span={12}>
                            <Form.Item label="Отчество"
                                       validateStatus={!child.surName ? "warning" : "success"} hasFeedback
                                       help={!child.surName ? "Рекомендуется заполнить" : null}>
                                <Input placeholder="Отчество" value={child.surName} id="surName"
                                       onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Дата рождения"
                                       validateStatus={!child.birthday ? "warning" : "success"} hasFeedback
                                       help={!child.birthday ? "Рекомендуется заполнить" : null}
                            >
                                <DefaultDatePicker placeholder="Дата рождения"
                                            value={child.birthday ? moment(child.birthday) : null}
                                            onChange={changeBirthday}/>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Описание">
                            <Input.TextArea id="description" value={child.description} onChange={changeField}/>
                        </Form.Item>
                    </Col>
                    {
                        !child.isDeleted ? null : (
                            <Col span={24}>
                                <Text type={"danger"}>Клиент удален</Text>
                            </Col>
                        )
                    }
                    <Col span={24}>
                        <Button type="primary" disabled={!(child.name && child.lastName)} id={"save_btn"}
                                style={{width: '100%'}} onClick={() => saveChild()}>
                            {(child.name && child.lastName) ? "Сохранить" : "Для сохранения заполните обязательные поля"}
                        </Button>
                    </Col>
                    {
                        !child.id ? null : [
                            (
                                <Col span={12}>
                                    <Button type="info"
                                            style={{width: '100%'}} onClick={() => setHistoryPanel({
                                        ...historyPanel,
                                        visible: true,
                                        entityId: child.id
                                    })}>
                                        Показать историю
                                    </Button>
                                </Col>
                            ),
                            (
                                <Col span={12}>
                                    <Popconfirm title={"Вы уверены?"} okText={"Да"} cancelText={"Нет"}
                                                style={{width: "100%"}}
                                                onConfirm={deleteClient} disabled={child.isDeleted}>
                                        <Button type={"danger"} style={{width: '100%'}}
                                                disabled={child.isDeleted}>Удалить</Button>
                                    </Popconfirm>
                                </Col>
                            )
                        ]
                    }
                </Col>
            </Card>
            <ChildHistory props={historyPanel} setProps={setHistoryPanel}/>
        </Spin>
    )
}