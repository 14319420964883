import React from "react";
import {Tag} from "antd";

export interface Role {
    id: any
    name: string
}

export enum RolesRusNames {
    ROLE_ADMIN="Администратор",
    ROLE_USER="Пользователь",
    ROLE_SUPER_ADMIN="Владелец системы"
}


export const convertToTags = (roles: Array<Role>) => roles.map(({name}) => (
    <Tag color={name === "ROLE_SUPER_ADMIN" ? "magenta" : "red"}>{RolesRusNames[name as keyof typeof RolesRusNames]}</Tag>
));
