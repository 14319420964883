import React from "react";
import {Button, Icon} from "antd";
import {ColumnProps} from "antd/es/table";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import DefaultTable from "../../components/utils/DefaultTable";
import {convertToTags} from "./roles.customization";
import {useHistory} from "react-router-dom";

const COLS:ColumnProps<any>[] = [
    { title: "ID", dataIndex: "id" },
    { title: "Статус", dataIndex: "isDeleted", render: (disabled: boolean) =>  disabled ? <Icon type="close" /> : <Icon type="check" />},
    { title: "ФИО", dataIndex: "fio" },
    { title: "Логин", dataIndex: "phoneNumber" },
    { title: "Роли", dataIndex: "roles", render: (roles) => convertToTags(roles) },
]

export const GET_USERS = gql`query administrators{
    administrators {
        id
        name
        lastName
        surName
        fio
        phoneNumber
        isDeleted
        roles { id name }
    }
}`;

const AccountsPage = () => {
    const history = useHistory();
    const {data = {administrators: []}, loading} = useQuery(GET_USERS)

    const actionsColumn = {
        title: "", dataIndex: "",
        render: (item:any) => <Button type={"link"} onClick={() => history.push(`/account/${item.id}`)}>Редактировать</Button>
    }

    const footer = () => (<Button type={"link"} onClick={() => history.push("/account")}>Добавить</Button>)

    return (
        <DefaultTable footer={footer} loading={loading} dataSources={data.administrators} columns={[...COLS, actionsColumn]} />
    );
}

export default AccountsPage