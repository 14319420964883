import React  from "react"
import { ChildDescriptionProps } from "../models"
import { Descriptions, Button, Divider, Icon, Tooltip } from "antd";
import { MarkedText } from "../markedText";
import { useHistory, useLocation } from "react-router-dom";

export const ChildDescription: React.FC<ChildDescriptionProps> = ({ child, mappedBy = "" }: ChildDescriptionProps) => {
    const markText = (text: string) => <MarkedText text={text} mappedBy={mappedBy} />
    const history = useHistory();
    const location = useLocation();
    const title = `Клиент. ${child.lastName} ${child.name} ${child.surName || ""}`

    const getParentDescriptionTitle = (parent:any) => {
        console.log('getParentDescriptionTitle', parent);
        
        return (
            <Tooltip title="Для перехода на карточку представителя, нажмите">
                <span onClick={() => history.push(`/parent/${parent.id}`)}>
                    Представитель
                </span>
            </Tooltip>
        )
    }

    const parents = child.parents.map(parent => (
        <Descriptions.Item span={2} label={getParentDescriptionTitle(parent)}>
            {markText(`${parent.lastName} ${parent.name} ${parent.surName || ""} ${parent.phoneNumber}`)}
        </Descriptions.Item>
    ))
    const goToChild = () => history.push(`/child/${child.id}`);
    const fullTitle = (
        <>
            {markText(title)}
            <Divider type={"vertical"} />
            <Button type={"link"} onClick={goToChild} >Карточка</Button>
            <Divider type={"vertical"} />
            <Button type="link" key={"action_show_timetable"}
                onClick={() => history.push({
                    pathname: `/schedule/child/${child.id}`,
                    state: {
                        background: location,
                    }
                })}>
                Расписание
            </Button>
        </>
    );

    return (
        <Descriptions title={fullTitle}>
            <Descriptions.Item label="Дата рождения">{markText(child.birthday || "Нет даных")}</Descriptions.Item>
            <Descriptions.Item span={2} label="Описание">{markText(child.description || "Нет даных")}</Descriptions.Item>
            {parents}
        </Descriptions>
    )
}