import ReactGA from "react-ga";
import {analyticsCategories} from "./categories";

export const sendLessonTiming = (variable, startMoment) => {
    ReactGA.timing({
        category: analyticsCategories.LESSONS,
        variable,
        value: Date.now() - startMoment,
    });
}

export const sendPageLoadTiming = (variable, startMoment) => {
    ReactGA.timing({
        category: analyticsCategories.PAGE_LOADING_INITIAL,
        variable,
        value: Date.now() - startMoment,
    });
}

export const sendScheduleTiming = (variable, startMoment) => {
    ReactGA.timing({
        category: analyticsCategories.SCHEDULE,
        variable,
        value: Date.now() - startMoment,
    });
}

