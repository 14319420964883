import React, { useState, useEffect } from 'react'
import { Drawer, Input, Divider, Spin, Icon, Tooltip } from 'antd';
import {useAxios} from '../../hooks/useAxios';
import { ItemsDescriptionParams, SearchResult } from "./models";
import { ChildDescription } from './descriptions/childDescription';
import { ParentdDescription } from './descriptions/parentDescription';
import { SpecialistDescription } from './descriptions/specialistDescription';
import { useHistory, useLocation } from 'react-router-dom';
import { Result } from 'antd/es';

export const SearchPanel: React.FC = () => {
    const history = useHistory();
    const { state = { search: undefined } } = useLocation();
    const initSearch: SearchResult = { children: [], parents: [], errors: [], specialists: [] }
    const [visible, setVisible] = useState<boolean>(true)
    const [searchText, setSearchText] = useState<string>(state.search || '')
    const [{ data = initSearch, loading }, executeSearch] = useAxios<SearchResult>({
        url: '/rest/search',
        params: { searchText }
    }, { manual: state.search === undefined })

    const searchTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value);

    const onPressEnterHandler = () => {
        if (!searchText.trim().length) {
            return;
        }
        executeSearch({ params: { searchText: searchText.toString() } })
            .catch(console.error);
    }

    useEffect(() => {
        if (state.search !== undefined) state.search = undefined;
    }, [searchText, state.search])

    const questionIcon = () => (
        <Tooltip title={<>
            Форма ищет среди клиентов, специалистов и представителей по полям: <br />
                * ФИО <br />
                * Услуги (по наименованию <br />
                * Номер телефона (без +7) <br />
                * Описание <br />

        </>}>
            <Icon type="question-circle" />
        </Tooltip>
    );

    const showEmptyResult = (data.children.length + data.specialists.length + data.parents.length) === 0

    return (
        <Drawer
            title={<>{questionIcon()} Почти Yandex! Найдется почти все!</>}
            placement="right"
            closable={false}
            width={800}
            onClose={() => {
                setVisible(false);
                setSearchText("");
                history.goBack();

            }}
            visible={visible}
        >
            <Input disabled={loading} placeholder="Что ищем?" value={searchText}
                onChange={searchTextHandler}
                onPressEnter={onPressEnterHandler}
            />
            <Divider />
            <Spin spinning={loading}>
                {
                    showEmptyResult ? <EmptyResult /> : <ItemsDescription search={searchText} foundItems={data} />
                }
            </Spin>
        </Drawer>
    )
}

const EmptyResult = () => (
    <Result status="404" title={"Ничего не найдено!"} subTitle={"Попробуйте изменить запрос"} />
)

const ItemsDescription = ({ search, foundItems }: ItemsDescriptionParams) => {
    const childrenDescriptions = foundItems.children.map(child => (<ChildDescription child={child} mappedBy={search} />))
    const parentsDescriptions = foundItems.parents.map(parent => (<ParentdDescription parent={parent} mappedBy={search} />))
    const specialistsDescriptions = foundItems.specialists.map(specialist => (<SpecialistDescription spec={specialist} mappedBy={search} />))
    return (
        <>
            {childrenDescriptions}
            {parentsDescriptions}
            {specialistsDescriptions}
        </>
    )
}