import React from "react";

interface StateWithLocalStorageProps {
    key: string
    initialValue: any | any[]
}

export const useStateWithLocalStorage = ({key = "", initialValue= ""}:StateWithLocalStorageProps) => {
    const [specsInLocalStore, setSpecsInLocalStore] = React.useState(
        JSON.parse(localStorage.getItem(key) || "null") || initialValue
    );

    React.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(specsInLocalStore));
    }, [key, specsInLocalStore]);
    return [specsInLocalStore, setSpecsInLocalStore];
};