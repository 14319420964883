import React, {useState} from "react";
import Row from "antd/es/grid/row";
import {Button, Card, Form, Input, message} from "antd";
import Col from "antd/es/grid/col";
import { DatePicker } from 'antd';
import { SpecialistsSelector } from "../../components/specialists/selector";
import { ClientsSelector } from "../../components/children/selector";
import { ServicesSelector } from "../../components/services/selector";
import { LessonStatusesSelector } from "../../components/lessonStatus/selector";
import { LocationSelector } from "../../components/locations/selector";
import { axiosInstance } from "../../utils/axios";

const { RangePicker } = DatePicker;

export default function ReportPage () {
    const [filters, setFilters] = useState({
        periodStart: null,
        periodEnd: null,
        specialists: [],
        lessonStatuses: [],
        clients: []
    })

    const generateReport = () => {
        const loading = message.loading("Генерация отчета...", 0);
        axiosInstance.post(
            "rest/reports", filters, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Report.xlsx');
                document.body.appendChild(link);
                link.click();
                setTimeout(loading, 1000)
            }).finally(() => {
                message.success("Готово")
            })
    }

    return (
        <Card title={"Генерация отчета"}>
            <Row gutter={[16,16]} >
                <Col span={24-6}>
                    <Form.Item label={"Период"} required 
                        validateStatus={!(filters.periodStart && filters.periodEnd) ? "error" : "success"}
                        help={!(filters.periodStart && filters.periodEnd) ? "Заполните период выборки" : null}
                        >
                        <RangePicker
                            format="DD.MM.YYYY"
                            placeholder={['Начало периода', 'Конец периода']}
                            onChange={(_, strs) => {
                                setFilters({...filters,
                                    periodStart: strs[0],
                                    periodEnd: strs[1]
                                })
                            }} />
                    </Form.Item>
                    <Form.Item label={"Специалисты"}
                        help={"Если не заполнено, в выборку попадут все специалисты"}
                    >
                        <SpecialistsSelector value={filters.specialists} onChange={(value)=> setFilters({...filters, specialists: value})} />
                    </Form.Item>
                    <Form.Item label={"Клиенты"}
                        help={"Если не заполнено, в выборку попадут все клиенты"}
                    >
                        <ClientsSelector value={filters.clients} 
                            onChange={(values, _) => setFilters({
                                ...filters,
                                clients: values
                            })}
                        />
                    </Form.Item>
                    <Form.Item label={"Услуги"} help={"Если не заполнено, в выборку попадут все услуги"}>
                        <ServicesSelector value={filters.services}
                            onChange={value => {
                                setFilters({
                                    ...filters,
                                    services: value
                                })
                            }}
                         />
                    </Form.Item>
                    <Form.Item label={"Точки и кабинеты"} help={"Если не заполнено, в выборку попадут все кабинеты"}>
                        <LocationSelector mode={"multiple"} value={filters.locations}
                            onChange={(values, ) => setFilters({
                                ...filters, 
                                locations: values
                            })}
                        />
                    </Form.Item>
                    <Form.Item label={"Занятия в статусах"}
                        help={"Если не заполнено, в выборку попадут занятия во всех статусах"}
                    >
                        <LessonStatusesSelector 
                            value={filters.lessonStatuses}
                            onChange={value => {
                                console.warn("VALUE", value)
                                setFilters({
                                    ...filters,
                                    lessonStatuses: value
                                })
                            }}/>
                    </Form.Item>
                    <Button type={"primary"} style={{width: '100%'}} 
                        onClick={generateReport} disabled={!(filters.periodStart && filters.periodEnd)}
                        >
                        Сформировать отчет
                    </Button>
                </Col>
                <Col span={6}>
                    <Input.TextArea disabled rows={20} value={JSON.stringify(filters)}/>
                </Col>
            </Row>
        </Card>
    )
}