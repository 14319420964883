import React, {useEffect, useState} from "react";
import {Typography} from "antd";
import {useQuery} from "@apollo/react-hooks";
import {GET_USER_BY_PHONE_NUMBER} from "./graphqlRequests";

function getNotificationStatusRusName(notificationsStatus) {
    switch (notificationsStatus){
        case "DENIED": return { rusName: "Отключены", type:"danger" }; break;
        case "GRANTED": return { rusName: "Разрешены", type:"secondary" }; break;
        case "RESTRICTED": return { rusName: "Ограничено", type:"secondary" }; break;
        case "LIMITED": return { rusName: "Ограничено", type:"secondary" }; break;
        case "PERMANENTLYDENIED": return { rusName: "Отключены", type:"danger" }; break;

        default: return { rusName: "Нет данных", type:"secondary" }
    }
}

export const NotificationsStatus = ({phoneNumber}) => {
    const [notificationRusName, setNotificationRusName] = useState("Нет данных")
    const [notificationType, setNotificationType] = useState("secondary" )
    const {data = {findByPhoneNumber: {deviceInfo: {notificationsStatus: null}}}} = useQuery(GET_USER_BY_PHONE_NUMBER, {
        variables: { phoneNumber }, skip: phoneNumber == null || phoneNumber === "", pollInterval: 10000
    })

    useEffect(() => {
        if (data.findByPhoneNumber != null) {
            const {rusName, type} = getNotificationStatusRusName(data.findByPhoneNumber.deviceInfo.notificationsStatus)

            setNotificationRusName(rusName)
            setNotificationType(type)
        }
    }, [data, notificationType, notificationType])

    return (
        <Typography.Text type={notificationType}>
            {notificationRusName}
        </Typography.Text>
    )
}