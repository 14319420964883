import {message} from "antd/es";
import {axiosInstance as axios} from "../utils/axios";
import {sendLessonTiming} from "../analytics";

export const canSaveLesson = (lesson, showMessage) => {
    return canSaveLessons([lesson], showMessage)
}

export const canSaveLessons = async (lessons, onConflict) => {
    const saving = await canSaveLessonsPromise(lessons)
    if (saving.data.length) {
        if (onConflict) onConflict(saving.data)
        return false;
    }
    return true;
}

export const showMessageWithConflicts = (conflicts, duration) => {
    conflicts.forEach((value) => {
        message.error(`Не сохранено: ${value.error}`, duration)
    })
}

export const canSaveLessonsPromise = (lessons) => {
    const startMoment = Date.now();
    const promise = axios.post("/rest/lesson/findConflicts", [...lessons])
    promise.then(() => {
        sendLessonTiming(
            'Проверка возможности сохранения занятий',
            startMoment
        )
    })
    promise.catch(reason => {
        sendLessonTiming('Проверка возможности сохранения занятий', startMoment)
        console.error(reason)
        message.error("Возникла ошибка при проверке возможности сохранения занятия: " + reason, 10)
    })

    return promise;
}