import React, {useState} from "react";
import {Button, Col, message, Row, Tooltip} from "antd";
import {EditorStepsHeader, Step0, Step1} from "./steps/index";
import {canSaveLessonsPromise} from "../../../utils/lessons";
import {axiosInstance as axios} from "../../../utils/axios"
import {useHistory} from "react-router-dom";
import {ModalWithErrors} from "./modal";

export interface NewEditFormProps {
    lessons: Array<number | any | undefined | null>,
    callbackPath?: string
}

interface EditorProps {
    currentStep: number,
    storage: any,
    init: boolean,
    modalProps: any,
    targetData: Array<any>
}

export function NewEditLessonsForm(props: NewEditFormProps): JSX.Element {
    const {lessons = [], callbackPath} = props
    const history = useHistory();
    const [editorFormProps, setEditorFormProps] = useState<EditorProps>({
        currentStep: 0,
        targetData: [],
        modalProps: {
            visible: false,
            loading: false,
            onOk: (lessons: Array<any>) => {
                saveLessonPromise(getLessonsForSaving(lessons), goBack)
                    .finally(() => updateModalProps({visible: false}))
            },
            onCancel: () => {
                updateModalProps({visible: false});
                goBack();
            }
        },
        init: true,
        storage: {
            service: {checked: false, value: {}},
            client: {checked: false, value: {}},
            specialist: {checked: false, value: {}},
            location: {checked: false, value: {}},
            status: {checked: false, value: {}},
            priority: {checked: false, value: {}},
            isCalled: {checked: false, value: {}},
        }
    })

    const goBack = (): void => {
        if (callbackPath) {
            history.push(callbackPath)
        } else history.goBack()
    }

    const updateStorage = (storage: any) => {
        setEditorFormProps({
            ...editorFormProps,
            storage
        })
    }

    const updateModalProps = (modalProps: any) => {
        setEditorFormProps({
            ...editorFormProps,
            modalProps: {
                ...editorFormProps.modalProps,
                ...modalProps
            }
        })
    }

    const updateTargetData = (targetData: Array<any>) => {
        setEditorFormProps({
            ...editorFormProps,
            targetData
        })
    }

    const getFilteredStorage: any = () => {
        const keys = Object.keys(editorFormProps.storage)
        const res: any = {}

        keys.forEach(key => {
            if (editorFormProps.storage[key].checked) {
                res[key] = editorFormProps.storage[key]
            }
        })
        return res;
    }

    const onClickNextButton = (): void => {
        if (editorFormProps.currentStep < 1) {
            setEditorFormProps({
                ...editorFormProps,
                currentStep: editorFormProps.currentStep === 2 ? 0 : editorFormProps.currentStep + 1,
            })
        } else {
            const onError = (lessonsWithErrors: Array<any>) => {
                updateModalProps({
                    dataSource: lessonsWithErrors,
                    allLessons: editorFormProps.targetData,
                    visible: true
                })
            }
            saveLessons(editorFormProps.targetData, onError, goBack)
        }
    }

    const steps: Array<{ component: any, title: string }> = [
        {
            component: (<Step0 storage={editorFormProps.storage} updateStorage={updateStorage}/>),
            title: "Выберите поля для редактирования"
        },
        {
            component: (<Step1 storage={{...getFilteredStorage()}} targetData={[...editorFormProps.targetData]}
                               updateTargetData={updateTargetData} lessons={[...lessons]}/>),
            title: "Проверка значений"
        },
    ]

    const isStorageItemCorrect = () => {
        const keys = Object.keys(editorFormProps.storage)
        const storage = editorFormProps.storage;
        if (keys.every(key => !storage[key].checked)) {
            return false;
        }

        return keys.every(key => {
            return !storage[key].checked ? true : !isEmpty(storage[key].value)
        });
    }

    const isNextButtonDisabled = editorFormProps.currentStep === 0 ? !isStorageItemCorrect() : false;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <EditorStepsHeader current={editorFormProps.currentStep}/>
            </Col>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h1>
                            {steps[editorFormProps.currentStep].title}
                        </h1>
                    </Col>
                    <Col span={24}>
                        {steps[editorFormProps.currentStep].component}
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <Col span={6}>
                    <Button
                        style={{width: '100%'}}
                        disabled={editorFormProps.currentStep === 0}
                        onClick={() => setEditorFormProps({
                            ...editorFormProps, currentStep: editorFormProps.currentStep - 1,
                        })}>
                        Назад
                    </Button>
                </Col>
                <Tooltip defaultVisible={true}
                    title={editorFormProps.currentStep === 0 ? "Для продолжения выберите поля и значения для них": null}
                >
                    <Col span={6}>
                        <Button onClick={onClickNextButton} type={"primary"}
                                disabled={isNextButtonDisabled}
                                style={{width: '100%'}}
                        >
                            {editorFormProps.currentStep === 1 ? "Сохранить" : "Далее"}
                        </Button>
                    </Col>
                </Tooltip>
            </Col>
            <ModalWithErrors {...editorFormProps.modalProps} />
        </Row>
    )
}

const getLessonsForSaving = (lessons: Array<any>): Array<any> => {
    return lessons.map(lesson => ({
        ...lesson,
        child: {id: Number.parseInt(lesson.client.id)},
        location: {id: Number.parseInt(lesson.location.id)},
        specialist: {id: Number.parseInt(lesson.specialist.id)},
        duration: lesson.duration,
        status: lesson.status.name,
        priority: lesson.priority.name,
        isCalled: lesson.isCalled.name === "Да"
    }))
}

function saveLessons(lessons: Array<any>, onError: (_: Array<any>) => any, callback: () => void) {
    const msg = message.loading("Созранение занятий...", 0);
    const lessonsForSaving = getLessonsForSaving(lessons)
    const promise = canSaveLessonsPromise(lessonsForSaving);
    promise.finally(() => {
        setTimeout(msg, 10);
    })

    promise.then(({data}) => {
        if (data.length) {
            data.forEach(({id, error}: any) => {
                const lessonWithError = lessons.find(lesson => Number.parseInt(lesson.id) === id)
                lessonWithError.errors = [...lessonWithError.errors || [], error]
            })
            onError(lessons.filter(lesson => lesson.errors))
        } else {
            saveLessonPromise(lessonsForSaving, callback)
        }
    })
}

const saveLessonPromise = (lessons: any, callback: () => void) => {
    return axios.post("/rest/lesson/several/save", lessons)
        .then(response => {
            message.success("Сохранено!", 4);
            callback()
        })
        .catch(error => {
            console.error(error);
            message.error("Возникла ошибка при сохранении.", 10)
        })
}

function isEmpty(obj: any) {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
}
