import gql from "graphql-tag";

const GET_FAVOR = gql`query GET_FAVOR($id: ID){
    favor (id: $id) {
        id
        name
        isDeleted
        costForClient
        specialistCost
        duration
        type
        specialist {
            id
        }
    }
}`;

const FAVOR_MUTATION = gql`mutation updateFavor($id: ID,
    $name: String!,
    $costForClient: Int!,
    $specialistCost: Int,
    $specialistId: ID!,
    $duration: Int!,
    $type: String
    $isDeleted: Boolean
) {
    updateFavor(id: $id, name:$name, costForClient: $costForClient,
        specialistCost: $specialistCost,
        specialistId: $specialistId, duration: $duration, type: $type, isDeleted: $isDeleted) {
        ... on Favor {
            id
        }
    }
}`;

const GET_SPECIALISTS = gql`{
    specialists {
        id
        name
        lastName
        surName
    }
}`;

export {GET_FAVOR, FAVOR_MUTATION, GET_SPECIALISTS}