import DefaultDatePicker from "../../../utils/DefaultDatePicker";
import {Checkbox, Col, Input, Row} from "antd";
import {ClientSelector} from "../../../children/selector";
import {SpecialistSelector} from "../../../specialists/selector";
import {ServiceSelector} from "../../../services/selector";
import {LocationSelector} from "../../../locations/selector";
import {LessonStatusSelector} from "../../../lessonStatus/selector";
import {LessonPrioritySelector} from "../../../lessonPriority/selector";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import React from "react";
import DefaultSelector from "../../../utils/DefaultSelector";
import {Moment} from "moment";

export default function Step0({storage, updateStorage}: any) {
    const updateField = (field: string, value: any) => {
        console.info('updateField', field, value)
        updateStorage({
            ...storage,
            [field]: {
                ...storage[field],
                value
            }
        })
    }

    const fields: Array<FieldProp> = [
        {
            name: "date",
            title: "Дата занятия",
            render: (disabled: boolean) => <DefaultDatePicker disabled={disabled} onChange={(moment:Moment) => {
                updateField("date", !moment ? moment : moment.format("YYYY-MM-DD"))
            }} />
        },
        {
            name: "time",
            title: "Время занятия",
            render: (disabled: boolean) => <Input type="time" disabled={disabled} placeholder="Время"
                                                  id={"lesson_time_picker"}
                                                  onChange={({target: {value}}) => updateField("time", value) }
            />
        },
        {
            name: "client",
            title: "Клиент",
            render: (disabled: boolean) => <ClientSelector disabled={disabled} value={storage.client.value.id}
                                                           onChange={(client: any) => updateField("client", client)}/>
        },
        {
            name: "specialist",
            title: "Специалист",
            render: (disabled: boolean) => <SpecialistSelector disabled={disabled}
                                                               value={storage.specialist.value.id}
                                                               onChange={(selectedSpec: any) => updateField("specialist", selectedSpec)}/>
        },
        {
            name: "service",
            title: "Услуга",
            render: (disabled: boolean) => <ServiceSelector disabled={disabled} value={storage.service.value.id}
                                                            onChange={(service: any) => updateField("service", service)}/>
        },
        {
            name: "location",
            title: "Кабинет",
            render: (disabled: boolean) => <LocationSelector disabled={disabled} value={storage.location.value.id}
                                                             onChange={(location: any) => updateField("location", location)}
            />
        },
        {
            name: "status",
            title: "Статус занятия",
            render: (disabled: boolean) => <LessonStatusSelector disabled={disabled} value={storage.status.value.name}
                                                                 onChange={(status: any) => updateField("status", status)}
            />
        },
        {
            name: "priority",
            title: "Приоритет занятия",
            render: (disabled: boolean) => <LessonPrioritySelector disabled={disabled}
                                                                   value={storage.priority.value.name}
                                                                   onChange={(priority: any) => updateField("priority", priority)}/>
        },
        {
            name: "isCalled",
            title: "Был ли звонок клиенту о занятии",
            render: (disabled: boolean) => <IsCalledSelector disabled={disabled} value={storage.isCalled.value.name}
                                                             onChange={(isCalled: any) => updateField("isCalled", isCalled)}/>
        },
    ]
    const isSelected = (name: string) => {
        const field = storage[name]

        return field ? field.checked : false
    }

    function selectField({target: {id = "", checked}}: CheckboxChangeEvent) {
        updateStorage({
            ...storage,
            [id]: {
                ...storage[id],
                checked,
                title: (fields.find(field => field.name === id) || {title: ""}).title
            }
        })
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                {fields.map((field, index) => {
                    const isSelected1: boolean = isSelected(field.name)

                    return (
                        <Row gutter={[16, 16]} key={`row_${index}`}>
                            <Col span={6} xl={6} md={12}>
                                <Checkbox checked={isSelected1} id={field.name}
                                          onChange={selectField}
                                >
                                    {field.title}
                                </Checkbox>
                            </Col>
                            <Col span={10} md={12}>
                                {field.render ? field.render(!isSelected1) : (
                                    <Input disabled={true} placeholder={"new value"}/>)}
                            </Col>
                        </Row>
                    )
                })}
            </Col>

        </Row>
    )
}


interface FieldProp {
    name: string,
    title?: string,
    checked?: boolean,
    render?: (disabled: boolean) => any
}

const isCalledOptions = [
    {name: "Да"},
    {name: "Нет"},
]

function IsCalledSelector(props: any) {
    return (
        <DefaultSelector {...props}
                         placeholder="Был ли звонок клиенту"
                         options={isCalledOptions} value={props.value} dataTitle={"name"} dataIndex={"name"}
                         onChange={(selected) =>
                             props.onChange(isCalledOptions.find(item => item.name === selected))
                         }
        />

    )
}
