import React, { useState, useEffect } from 'react';
import { Spin, Modal, message } from "antd";
import { ServiceForm } from "./serviceForm";
import {useMutation, useQuery} from "@apollo/react-hooks";
import { SAVE_SERVICE_MUTATION, GET_SERVICE } from './graphql';

const SERVICE_INITIAL_STATE = {
    businessId: "",
    costForClient: 0,
    duration: 30,
    name: "",
    specialistCost: 0,
    specialists: [],
    type: "Индивидуальное",
    id: undefined,
    isDeleted: false
}

const Service = ({ id = undefined, visible = false, onClose = () => { } }) => {
    const {data = {findService: SERVICE_INITIAL_STATE}, loading} = useQuery(GET_SERVICE, {skip: !id, variables: {id: Number.parseInt(id)}});
    const [saveServiceMutation] = useMutation(SAVE_SERVICE_MUTATION);
    
    const [saving, setSving] = useState(false);
    const [service, setService] = useState(data.findService)

    useEffect(() =>  {
        if (!!id) {
            setService({ 
                ...data.findService,
                specialists: data.findService.specialists.map(_ => _.id)
            })
        }
    }, [data, id])

    const onFieldChange = ({ target: { id, value } }) => setService({ ...service, [id]: value });

    const onSave = () => {
        setSving(() => true);
        saveServiceMutation({variables: service})
            .then(onClose)
            .then(() => {
                message.success("Сохранено!", 4)
            })
    }

    return (
            <Modal title={`${!id ? "Создание" : "Редактирование"} услуги`}
                visible={visible} width={700} onClose={onClose}
                cancelText={"Отменить"} onCancel={onClose} cancelButtonProps={{disabled: loading || saving}}
                okText={"Сохранить"} onOk={onSave} okButtonProps={{disabled: loading || saving}}
            >
                <Spin spinning={loading || saving}>
                    <ServiceForm service={service} onChange={onFieldChange} />
                </Spin>
            </Modal>

    )
}

Service.whyDidYouRender = false;
export {Service};